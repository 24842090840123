(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsFirmwareSliderController', PartialsFirmwareSliderController);

    PartialsFirmwareSliderController.$inject = ['$scope', '$rootScope', 'Wmoapi', 'ngDialog', '$q', 'deviceIdentifier', 'DeviceService'];

    function PartialsFirmwareSliderController($scope, $rootScope, Wmoapi, ngDialog, $q, deviceIdentifier, DeviceService) {

        var vm = this;
        var mainDevice = DeviceService.getFlashableDevice();
        var popupAlreadyShown = deviceIdentifier.doesDetectedDeviceUseAccRfid(mainDevice.productPartOptions);

        var sliderOpen = false;

        // define what we are adding to the scope (vars / functions)
        vm.openFirmwareSlider = openFirmwareSlider;
        vm.alternativeFirmwares = [];

        function getFirmwares(requestId) {
            var deferred = $q.defer();

            // if has firmwares
            if (vm.responseData.vehicleFirmwares.length !== 0) {
                var response = {
                    data: {
                        firmwares: vm.responseData.vehicleFirmwares
                    }
                };
                deferred.resolve(response);
            } else {
                Wmoapi.getFirmwares(requestId)
                    .then(function(success) {
                        vm.responseData.vehicleFirmwares = success.data.firmwares;
                        deferred.resolve(success);
                    }, function(error) {
                        deferred.reject(error);
                    });
            }

            return deferred.promise;
        }

        function openFirmwareSlider() {
            if (sliderOpen) {
                return;
            }
            sliderOpen = true;
            $rootScope.isGlobalLoading = true;

            getFirmwares(vm.request.id)
                .then(function(success) {
                    $rootScope.isGlobalLoading = false;
                    vm.alternativeFirmwares = success.data.firmwares;

                    ngDialog.openConfirm({
                        template: 'modules/core/views/partials/firmware/directives/firmwareslider.modal.client.directive.view.html',
                        className: 'ngdialog-theme-plain firmware-selection',
                        controller: 'PartialsFirmwareSliderModalController as ctrl',
                        resolve: {
                            alternativeFirmwares: function() {
                                return vm.alternativeFirmwares;
                            },
                            currentFirmware: function() {
                                return vm.responseData.currentFirmware;
                            }
                        },
                        closeByDocument: true
                    }).then(function(result) {
                        sliderOpen = false;
                        vm.userSelection.isFwChanged = vm.userSelection.selectedFirmware.id !== result.id;
                        vm.userSelection.selectedFirmware = result;
                        const firmwareUsesAccRfid = vm.userSelection.selectedFirmware.isRfidRequired && vm.responseData.firmwareFamily.isKlonHubRequired;
                        if (firmwareUsesAccRfid && !popupAlreadyShown) {
                            ngDialog.openConfirm({
                                template: 'modules/core/views/partials/firmware/directives/rfidrequired.modal.client.directive.html',
                                className:'ngdialog-theme-plain',
                                closeByDocument: false,
                                showClose: false
                            });
                        }
                        getAccessories(vm.userSelection.isFwChanged, vm.request.id, vm.userSelection.selectedFirmware.id);
                    }, function() {
                        // user closed dialog - do nothing
                        sliderOpen = false;
                    });
                },
                function(error) {
                    $rootScope.isGlobalLoading = false;
                });

        }


        function getAccessories(isFwChanged, requestId, selectedFirmwareId) {
            var deferred = $q.defer();

            // if has firmwares
            if (isFwChanged) {
                $rootScope.isGlobalLoading = true;
                vm.responseData.deviceOemRemoteConfig = {};
                vm.userSelection.oemRemote = '';
                Wmoapi.getAccessories(requestId, selectedFirmwareId)
                    .then(function(success) {
                        vm.userSelection.selectedAccessories = success.data.accessories;
                        vm.responseData.currentAccessories = success.data.accessories;
                        $rootScope.isGlobalLoading = false;
                        deferred.resolve(success);
                    }, function(error) {
                        $rootScope.isGlobalLoading = false;

                        deferred.reject(error);
                    });
            }

            return deferred.promise;
        }
    }

})();
