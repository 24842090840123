'use strict';


angular.module('core').controller('HomeController', ['$scope', 'Wmoapi',
    'configuration','messageBox', 'alertBar', 'deviceIdentifier', '$rootScope', 'klonIdentifier',
    '$timeout', '$interval', '$window', 'translateService','Wiringapi', 'AppService',
    'Request', 'deviceDetect', 'detectedDevice', '$q', 'DeviceService', '$location', 'sidebarManager','dataService','ngDialog', 'RSUData','guidesService','_', 'showAgreementsModal', 'constants', 'wu',
    function($scope, Wmoapi, configurationFactory, messageBox,
        alertBar, deviceIdentifier, $rootScope, klonIdentifier,
        $timeout, $interval, $window, translateService, Wiringapi, AppService,
             Request, deviceDetect, detectedDevice, $q, DeviceService, $location, sidebarManager, dataService, ngDialog, RSUData, guidesService, _, showAgreementsModal, constants, wu) {

        var defaultPartial = 'modules/core/views/partials/device/device.client.view.html';
        var idatalinkUrl = 'http://www.idatalink.com/weblink/';
        var readRemotePagesResult = null;
        $scope.submenu = { items:[], selectedItem: '', show: false };
        $scope.menuArrow = { showInVehicle : false , showInfeatures:false };

        $scope.sidebar = sidebarManager.getSidebar();

        $scope.$watch(function () {
           return sidebarManager.getSidebar();
         }, function(newValue, oldVal) {
              if (typeof newValue !== 'undefined') {
                  $scope.sidebar = newValue;
              }
        }, true);

        $scope.app = { demo_mode: false, flex_upgrade_active: false, is_in_dev: false };
        $scope.loading = true;

        $scope.redirectIdatalink = false;
        $scope.redirectTimeInSeconds = '-';
        $scope.redirectTitle = 'DETECTION_ERROR_PAGE_NOT_LOGGEDIN';
        $scope.showCancelRedetectBtn = false;
        $scope.configurationParameters = {
            showAllMakes : false,
            flashByFirmware: false,
            flashFromFavorite: false,
            flashFromFavoriteConfig: false
        };

        $scope.detectedDevice = DeviceService.getFlashableDevice();
        $scope.rsuDevice = DeviceService.getRsuDevice();
        $scope.embeddedDevices = DeviceService.getEmbeddedDevices();
        $scope.isRusWithNoBlade = $scope.rsuDevice && $scope.detectedDevice.bootloader.moduleType === $scope.rsuDevice.bootloader.moduleType;

        $scope.request = {
            id:'',
            configModuleRequestId:'',
            configRemotesRequestId:'',
            klonRequestId:'',
            configModuleSoloRequestId:'',
            configAntennaRequestId:'',
            configBootloaderRequestId: '',
            configTrialActivationRequestId:'',
            configBADeviceRequestId: '',
            parentRequestId: '',
        };
        $scope.request_device = {
            id:'',
            bootloader:{version:0},
            serial:''
        };
        $scope.selectedVehicle = {
            makeId:'',
            makeName:'',
            year:'',
            yearName:'',
            modelId:'',
            modelName:'',
            modelImage:'',
            vehicleId:'',
            vehicleName:'',
            firmwareFamilyId:'',
            firmwareFamilyName:'',
            firmwareId:'',
            firmwareName:'',
            firmwareVersion:''
        };
        $scope.prevSelectedVehicle = {
            makeId:'',
            year:'',
            modelId:'',
            vehicleId:'',
            firmwareFamilyId:'',
            firmwareId:''
        };

        $scope.userSelection = {
            oemRemote: { optionId:'', firmwareId:'' },
            selectedFirmware: {},
            selectedAccessories: {},
            isFwChanged:false,
            telematicId:'', selectedRemote:{},
            selectedProtocol:{id:'', name:'', isStandalone:false, standaloneId:'', isStandaloneRf:false, isStandaloneTelematic:false, usedAsAddOn: false , option:{name: '' , id: ''}},
            telematicName:'',
            packages:{ packageId:'', packageChangeId:'' },

        };

        $scope.responseData = {
            deviceControls:[],
            deviceControlPages:[],
            deviceGuides:[],
            deviceOemRemoteConfig:{},
            quickConfigs:[],
            vehicleMakes:[],
            vehicleYears:[],
            vehicleModels:[],
            vehicleTrims:[],
            protocols:[],
            vehicleFirmwareFamilies:[],
            vehicleFirmwares:[],
            setFirmware: {hasLcdRemoteFirmwareUpdate: false, hasConfig:false},
            currentFirmware: {},
            currentAccessories: {},
            firmwareDownload:[],
            configModuleDownload:[],
            configRemotesDownload:[],
            klonHlsLogContent: null,
            klonSuspendedRequest:[],
            klonRequiredPages:[],
            klonRequiredPagesReadings:[],
            klonDevicePrepared:[],
            klonLatestRequest:[],
            klonSendReadings:[],
            klonStatusUpdate:[],
            klonDownload:[],
            configModuleRequiredPages:[],
            configModulePagesReadings:[],
            telematics:[],
            firmwarePosts:[],
            rfDecoder:{},
            wiringApiStatus:{},
            wiringApi:{ wires:[], vehicleId:'', wireImages:[], wireCount:'', imageCount:'', images:'', vehicleIdForGallery:'', imagesTags:''},
            videosGuides:{videos:[], vehicleId:'', productId:''},
            packages:{packagesList:[],packageClassGrid:[]},
            configAntennaDownload:[],
            configBootloaderDownload:[],
            configBADownload:[],
            configTrialActivationDownload:[],currentPackage:{},
            packageChangeData:{},latestFirmwareInfo:{},
            classicInstallOptions:{},
            remotePages:[],
            remotePagesReadings:[],
            firmwareFamily: {}
        };

        $rootScope.globalResponseData = { deviceFeatures:[] };
        $rootScope.isGlobalLoading = false;
        $rootScope.keyreadingFlash = false;
        $rootScope.globalUser = {};

        $scope.partial = defaultPartial;
        $scope.detectionError = false;
        $scope.flashForKlonOnly = false;
        $scope.isAlreadyFlashed = false;
        $scope.modifyFeaturesOnly = false;
        $scope.modifyControlsOnly = false ;
        $scope.skipControls = false;
        $scope.flashForFirmwareUpdateOnly = false;
        $scope.flashForFlexUpgradeOnly = false;
        $scope.resumeKeyreading = false;
        $scope.serialToUpdate = '';
        $scope.hasSuspendedRequest = false;
        $scope.hasKlonFw = false;
        $scope.hasFirmwareUpdates = false;
        $scope.hasRsuFirmwareUpdate = false;
        $scope.deviceHasBlankFirmware = false;
        $scope.isTelematicsOn = false;
        $scope.showDeviceNotificationBar = false;
        $scope.showHasFlexPackageNoBillNotificationBar = false;
        $scope.showHasFlexPackageNoBillNotificationBarAfterFlash = false;
        $scope.showWiringSection = false;
        $scope.isAntenna = false;
        $scope.isDeviceAccessory = false;
        $scope.isDeviceRfid = false;
        $scope.canFlashByVehicle = false;
        $scope.canModifyFeatures = false;
        $scope.isIdatastartRemotePairingCompatible = false;
        $scope.hasFlexUpgrade = false;
        $scope.hasFlexPackageNoBill = false;
        $rootScope.flashInProgress = false;
        $scope.isInWeblinkDesktop = AppService.isPramanthaObjectDetected();
        $scope.isFavoriteActive = AppService.isFavoriteActive();

        var sliderOpen = false;
        $scope.usedAsAddOn = false;
        $scope.standaloneFlags = AppService.getStandaloneFlags();
        var mainDevice = DeviceService.getFlashableDevice();
        var isDetectedDeviceIsClassic = !$rootScope.hasInvalidDevice ?  DeviceService.isClassicDevice() : false;
        var classicDevIndex = isDetectedDeviceIsClassic ? 1 : 0;
        $scope.skipToWiring = false;
        $scope.skipToGuides = false;
        $scope.isConfigRsuFeatures = false;
        $scope.firmwareAlreadySet = false;
        $scope.externalMemoryRequest = {};

        $scope.initHome = function() {
            AppService.setAddOn(false);
            if (AppService.isDemoMode()) {
                weblinkupdater.weblinkupdater.mockEnabled = true;
                $scope.app.demo_mode = true;
            }
            if (AppService.isInDev()){
                $scope.app.is_in_dev = true;
            }

            checkLogin(mainDevice);
        };

        $scope.getWiringSection =  function() {
            $scope.skipToWiring = true;
            $scope.skipstep('guides');
        };

        $scope.getGuidesSection =  function() {
            $scope.skipToGuides = true;
            $scope.skipstep('guides');
        };

        function checkLogin (device){
            if (device.productID !== 'generic' || AppService.getRequiresReboot()) {
                Wmoapi
                    .getUserProfile()
                    .success(function (userProfile) {
                        $rootScope.globalUser.profile = userProfile;
                        $scope.changeLanguage(userProfile.language.toLowerCase());
                        $scope.$broadcast('loggedIn');

                        if(!userProfile.agreementAccepted || userProfile.agreementUpdated) {
                            showAgreementsModal(true);
                        }
                    })
                    .catch(function () {
                        $location.path('login');
                    });
            } else {
                if(detectedDevice.errorStatus === 401)   $location.path('login');
                else detectFailedTimeout(detectedDevice.error ? detectedDevice.error : 'DETECT_NO_PLUGIN_DETECTED', null, detectedDevice.errorStatus);
            }
        }

        $scope.$on('loggedIn', function () {

            if (AppService.getRequiresReboot()){
                //TODO Determine behavior between a Regular user and ADS Staff
                if ($rootScope.globalUser.profile.userRoleId === constants.INTERNAL_USER_ROLE_ID && !$rootScope.hasNoFirmware) {
                    $scope.detect();
                } else if ($rootScope.globalUser.profile.userRoleId === constants.BETA_USER_ROLE_ID && !$rootScope.hasNoFirmware && !$rootScope.forcedUpdate) {
                    $scope.detect();
                } else {
                    $scope.rebootHub();
                }
            } else {
                if ($scope.detectedDevice.productID !== 'generic') {

                    if (deviceIdentifier.isDeviceHub($scope.detectedDevice.partId)) {

                        var isStaffUser = $rootScope.globalUser.profile.userRoleId === constants.INTERNAL_USER_ROLE_ID;
                        var isBetaUser = $rootScope.globalUser.profile.userRoleId === constants.BETA_USER_ROLE_ID;
                        var deviceFirmwareUpdate = deviceIdentifier.hasFirmwareUpdate($scope.detectedDevice);

                        if(deviceFirmwareUpdate.hasUpdate || isStaffUser || isBetaUser) {
                            console.log('hub needs update');
                            if ((!isStaffUser || deviceFirmwareUpdate.hasBlankFirmware) && (!isBetaUser || $scope.detectedDevice.isFirmwareUpdateForced)) {
                                $scope.doFirmwareUpdate(true);
                            }
                            detectHCX($scope.detectedDevice);
                        } else {
                            var restartHub = wu.restartHub($scope.detectedDevice, () => $window.location.reload(true), onRestartHubFailed);
                            restartHub.start();
                        }

                    } else {
                        if($scope.isRusWithNoBlade && $scope.isInWeblinkDesktop) {
                            $scope.$broadcast('deviceDetected');

                        } else {
                            detectHCX($scope.detectedDevice);
                        }
                    }


                } else {
                    detectFailedTimeout(detectedDevice.error ? detectedDevice.error : 'DETECT_NO_PLUGIN_DETECTED', null, detectedDevice.errorStatus);
                }
            }


        });

        function onRestartHubFailed () {
            ngDialog.openConfirm({
                template: 'modules/core/views/modals/hubreset.modal.client.view.html',
                className:'ngdialog-theme-plain'
            }).then(function(result){
                $window.location.reload(true);
            },function(error){
                $window.location.reload(true);
            });
        }

        $scope.rebootHub = function () {
            $rootScope.isGlobalLoading = true;
            Wmoapi.getWeblinkHubRebootFrame().success(function (response){

                var reboot = wu.getRebootFunction(response.frame, function () {
                    $timeout($scope.refreshPage, 2000);
                });
                reboot.start();

            }).error(function (error) {
                AppService.setWeblinkDesktopStatus(null);
                console.error('getWeblinkHubRebootFrame error:', error);
            });
        };


        $scope.refreshPage = function () {
            $window.location.reload(true);
        };

        $scope.detect = function() {
            // use service to detect.
            $scope.stopRedirectCountdown();
            $scope.loading = true;
            $timeout(function() {
                $scope.detectionError = false;
                $scope.redirectIdatalink = false;
            }, 1000);

            deviceDetect.detect()
                .then(function(devices) {
                    $rootScope.isGlobalLoading = false;
                    DeviceService.setDevices(devices);
                    $scope.detectedDevice = DeviceService.getFlashableDevice();
                    $scope.embeddedDevices = DeviceService.getEmbeddedDevices();
                    $scope.rsuDevice  = DeviceService.getRsuDevice();
                    $scope.isRusWithNoBlade = $scope.rsuDevice && $scope.detectedDevice.bootloader.moduleType === $scope.rsuDevice.bootloader.moduleType;
                    clearPrevVehicleSelections();

                    if(deviceIdentifier.isDeviceHub($scope.detectedDevice.partId) && !deviceIdentifier.hasFirmwareUpdate($scope.detectedDevice).hasUpdate) {
                        var restartHub = wu.restartHub($scope.detectedDevice, $window.location.reload(true), onRestartHubFailed);
                        restartHub.start();
                    } else {
                        deviceDetect.manageUserPlugin()
                            .then(function() {
                                if($scope.isRusWithNoBlade && $scope.isInWeblinkDesktop) {
                                    $scope.$broadcast('deviceDetected');
                                } else {
                                    detectHCX($scope.detectedDevice);
                                }
                            }, function(error) {
                                detectFailedTimeout(error);
                            });
                    }


                }, function(error) {
                    console.log(error);
                    $timeout(function() {
                        $scope.showDetectFail(error.errorMsg ? error.errorMsg : 'DETECT_TIMEOUT', null, error.errorCode);
                    }, 1000);
                }
            );
        };

        function clearPrevVehicleSelections() {
            var v = $scope.selectedVehicle;
            v.makeId = '';
            v.makeName = '';
            v.year = '';
            v.yearName = '';
            v.modelId = '';
            v.modelName = '';
            v.modelImage = '';
            v.vehicleId = '';
            v.vehicleName = '';
            v.firmwareFamilyId = '';
            v.firmwareFamilyName = '';
            v.firmwareId = '';
            v.firmwareName = '';
            v.firmwareVersion ='';

            var p = $scope.prevSelectedVehicle;
            p.makeId='';
            p.year = '';
            p.modelId = '';
            p.vehicleId = '';
            p.firmwareFamilyId = '';
            p.firmwareId = '';

            $scope.responseData.deviceOemRemoteConfig = {};
            $scope.responseData.firmwarePosts = {};
            $scope.userSelection.oemRemote = {};
            $scope.userSelection.telematicId = '';
            $scope.userSelection.telematicName = '';
            $scope.userSelection.telematicImagePhone = '';
            $scope.responseData.packages.packagesList = [];
            $scope.userSelection.packages.packageId = '';
            $scope.userSelection.packages.packageChangeId = '';
            $scope.userSelection.selectedFirmware = {};
            $scope.hasFirmwareUpdates = false;
            $scope.hasRsuFirmwareUpdate = false;
            $scope.deviceHasBlankFirmware = false;
            $scope.isAntenna = false;
            $scope.isDeviceAccessory = false;
            $scope.isDeviceRfid = false;
            $scope.canFlashByVehicle = false;
            $scope.canModifyFeatures = false;
            $scope.hasFlexUpgrade = false;
            $scope.hasFlexPackageNoBill = false;
            $scope.menuArrow.showInGuides = false;
            $rootScope.flashInProgress = false;

        }

        function detectHCX(device) {
            var isDetectedDeviceAllowed = deviceIdentifier.isDetectedDeviceAllowed(device.productPartOptions);
            $scope.isAntenna = deviceIdentifier.isDeviceAntenna(device.deviceFunctionId);
            $scope.isDeviceAccessory = deviceIdentifier.isDeviceAccessory(device.deviceFunctionId);
            $scope.isDeviceRfid = deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId);
            $scope.canFlashByVehicle = deviceIdentifier.isFlashByVehicleAllowed(device.allowedServices);
            $scope.canModifyFeatures = deviceIdentifier.isConfigureModuleAllowed(device.allowedServices);
            var hasFlexPackageNoBill = deviceIdentifier.isDetectedDeviceHasFlexProgramNoBill(device.productPartOptions);

            if (isDetectedDeviceAllowed) {
                if ((device.hasProgram || hasFlexPackageNoBill) && $scope.isAntenna) {
                    checkActivatedAntenna(device.serial);
                } else {
                    $scope.$broadcast('deviceDetected');
                }
            } else {
                if ($scope.isInWeblinkDesktop) {
                    var isDetectedDeviceAllowedInWD = deviceIdentifier.isDetectedDeviceAllowedInWD(device.productPartOptions);
                    if (isDetectedDeviceAllowedInWD) {
                        $scope.$broadcast('deviceDetected');
                    } else {
                        detectedDeviceNotAllowedInWd();
                    }
                } else {
                    detectedDeviceNotAllowed();
                }
            }
        }

        function checkActivatedAntenna (serial) {
            var device = $scope.detectedDevice;
            var firmwareDetail = deviceIdentifier.hasFirmwareUpdate(device);
            $scope.hasFirmwareUpdates = firmwareDetail.hasUpdate;
            $scope.deviceHasBlankFirmware = firmwareDetail.hasBlankFirmware;
            var hasFlexPackageNoBill = deviceIdentifier.isDetectedDeviceHasFlexProgramNoBill(device.productPartOptions);

            if(device.hasProgram){
                if($scope.deviceHasBlankFirmware) {
                    detectFailedTimeout('DETECTION_ERROR_PAGE_NEW_FLEX_ANTENNA_MSG','DETECTION_ERROR_PAGE_NEW_FLEX_ANTENNA_HEADER');
                } else {

                    Wmoapi
                        .getPackage(serial)
                        .success(function(response){

                            $scope.responseData.currentPackage = response;

                            if(deviceIdentifier.isFlexActivated(response)) {
                                checkIfHasFlexUpgrade();
                                $scope.$broadcast('deviceDetected');
                            }
                            else {
                                detectFailedTimeout('DETECTION_ERROR_PAGE_NEW_FLEX_ANTENNA_MSG','DETECTION_ERROR_PAGE_NEW_FLEX_ANTENNA_HEADER');
                            }

                        })
                        .error(function(error){
                            $scope.responseData.currentPackage = { package: null };
                        });

                }
            } else if(hasFlexPackageNoBill) {

                Wmoapi
                    .getPackage(serial)
                    .success(function(response){

                        $scope.responseData.currentPackage = response;

                        manageFlexPackageNoBillPackages(device);
                        $scope.$broadcast('deviceDetected');

                    })
                    .error(function(error){
                        $scope.responseData.currentPackage = { package: null };
                    });
            }

        }

        function manageFlexPackageNoBillPackages (device){

            Wmoapi
                .getPackagesNoBill(device.serial)
                .success(function(response){
                    $scope.responseData.packageChangeData = response;

                    if (response.package_changes) {
                        if (response.package_changes.length > 0 ){
                            $scope.hasFlexPackageNoBill = true;
                            var fwId = parseInt(device.firmwareID,10);
                            var hasBlankFw = (isNaN(fwId) || fwId === 0);
                            $scope.deviceHasBlankFirmware = hasBlankFw;
                            if(hasBlankFw) {
                                $scope.hasFirmwareUpdates = false;
                            }
                        }
                    }
                })
                .error(function(error){
                    $scope.responseData.packagesNoBill = { 'packages': [] };
                });

        }

        function checkIfHasFlexUpgrade (){
            var device = $scope.detectedDevice;
            var isInternal = device.isInternal;
            var isFlexUpgradeActive = $scope.app.flex_upgrade_active;

            if(!isInternal && isFlexUpgradeActive) {
                getPackageChange(device.serial);
            }

        }

        function getPackageChange (serial) {
            Wmoapi
                .getPackageChange(serial)
                .success(function(response){
                    $scope.responseData.packageChangeData = response;
                    checkIfPackageUpgradeAvailable(response);
                })
                .error(function(error){
                    $scope.responseData.packageChangeData = { 'package_changes': [] };
                });
        }

        function checkIfPackageUpgradeAvailable (response) {
            if (response.package_changes) {
                if (response.package_changes.length > 0 ){
                    $scope.hasFlexUpgrade = true;
                }
            }
        }

        function detectedDeviceNotAllowedInWd() {
            $scope.redirectIdatalink = true;
            $scope.redirectTitle = 'DETECTION_ERROR_PAGE_NOT_SUPPORTED_TITLE';
            $scope.redirectText =  'DETECTION_ERROR_PAGE_NOT_SUPPORTED_TEXT';
            $scope.redirectButtonTitle =  'FLASH_REDETECT_BUTTON';
            $scope.showCancelRedetectBtn = true;
        }

        function detectedDeviceNotAllowed() {
            $scope.redirectIdatalink = true;
            $scope.redirectTitle = 'DETECTION_ERROR_PAGE_NONHCX_TITLE';
            $scope.redirectText = 'DETECTION_ERROR_REDIRECTING_MSG';
            $scope.redirectButtonTitle = 'DETECTION_ERROR_PAGE_BUTTON_CANCELREDETECT';
            $scope.showCancelRedetectBtn = true;

            $timeout(function () {
                $scope.loading = false;
            }, 1000);
           $scope.startRedirectCountdown(10);
        }

        function detectFailedTimeout(errorMessage, errorMessageHeader, errorCode){

            $timeout(function() {
                $scope.showDetectFail(errorMessage, errorMessageHeader, errorCode);
            }, 1000);

        }

        var stopRedirectCount;
        $scope.startRedirectCountdown = function(waitTimeInSeconds){

            if (angular.isDefined(stopRedirectCount)) return;

            stopRedirectCount = $interval(function() {
                if (waitTimeInSeconds>0) {
                    $scope.redirectTimeInSeconds = waitTimeInSeconds;
                    waitTimeInSeconds--;
                } else {
                    $scope.stopRedirectCountdown();
                    $window.location.href = idatalinkUrl;
                }
            }, 1000);

        };

        $scope.stopRedirectCountdown = function(){
            if (angular.isDefined(stopRedirectCount)) {
                $interval.cancel(stopRedirectCount);
                stopRedirectCount = undefined;
                console.log('stopRedirectCountdown');
            }
        };

        $scope.logout = function(){

            Wmoapi
                .logout()
                .success(function(response){
                    //redirect which page?
                    //$location.path('/');
                })
                .error(function(error){
                    //TODO: handle error
                });

        };

        $scope.$on('deviceDetected', function () {
            RSUData.clearRSUBrands();
            RSUData.setSelectedRsuBrand(null);
            $scope.getDetectedDeviceInfo();
            $scope.loadDevicePartial();
            $scope.checkFlashedDevice();
            if (!$scope.isAntenna && !$scope.rsuDevice) $scope.checkDeviceRfDecoderForUpdate(); //TODO: check later
            $scope.checkWiringApiStatus();
            $scope.checkAnnouncements();

            if ($rootScope.hasInvalidDevice) {
                alertBar.show('warning', 'DETECT_INVALID_EMBEDDED_DEVICE');
            }
        });


        $scope.getDetectedDeviceInfo = function () {
            var productPartOptions = $scope.detectedDevice.productPartOptions ;
            var deviceIden = deviceIdentifier.isSkipRemoteConfiguration(productPartOptions);
            $scope.skipControls = deviceIden;
            $scope.supportsInternalRemotes = deviceIdentifier.supportsInternalRemotes(productPartOptions);
        };

        $scope.checkFlashedDevice = function(){

            var device = $scope.detectedDevice;
            var embeddedDevices = $scope.embeddedDevices;
            var firmwareId = parseInt(device.firmwareID);
            $scope.isIdatastartRemotePairingCompatible = deviceIdentifier.isIdatastartRemotePairingCompatible(device.isIdatastartRemotePairingCompatible);
            var hasFlexPackageNoBill = deviceIdentifier.isDetectedDeviceHasFlexProgramNoBill(device.productPartOptions);

            $scope.isAlreadyFlashed = (firmwareId !== 0);
            var firmwareDetail = deviceIdentifier.hasFirmwareUpdate(device);
            var embeddedDevicesFirmwareDet = embeddedDevices.map(function(device) {
                return deviceIdentifier.hasFirmwareUpdate(device);
            });
            var oneOfEmbeddedDevicesHasFwUpdate = function() { 
                var array = embeddedDevicesFirmwareDet.filter(function(item){
                    return item.hasUpdate === true;
                })[0];
                return array ? true : false;
            };
            var rsuDeviceHasFwUpdate = $scope.rsuDevice && $scope.rsuDevice.Latest && (($scope.rsuDevice.Firmware ? $scope.rsuDevice.Firmware.id : 0) < $scope.rsuDevice.Latest.id);
            var staffUserForcingFirmwareUpdate = (deviceIdentifier.isDeviceHub(device.partId) && $rootScope.globalUser.profile.userRoleId === constants.INTERNAL_USER_ROLE_ID);
            var betaUserForcingFirmwareUpdate = (deviceIdentifier.isDeviceHub(device.partId) && $rootScope.globalUser.profile.userRoleId === constants.BETA_USER_ROLE_ID && !$rootScope.forcedUpdate);

            $scope.hasFirmwareUpdates = firmwareDetail.hasUpdate || staffUserForcingFirmwareUpdate || betaUserForcingFirmwareUpdate || oneOfEmbeddedDevicesHasFwUpdate() || rsuDeviceHasFwUpdate;
            $scope.hasRsuFirmwareUpdate = rsuDeviceHasFwUpdate;
            dataService.mainDeviceHasFwUpdate = firmwareDetail.hasUpdate || staffUserForcingFirmwareUpdate  || betaUserForcingFirmwareUpdate;
            dataService.embeddedDeviceHasFwUpdate = oneOfEmbeddedDevicesHasFwUpdate();
            dataService.rsuDeviceHasFwUpdate = rsuDeviceHasFwUpdate;
            $scope.deviceHasBlankFirmware = firmwareDetail.hasBlankFirmware;
            var allowsFlashByVehicle = deviceIdentifier.isFlashByVehicleAllowed(device.allowedServices);

            if(device.needBootloaderUpdate) {
                if(parseInt(device.expectedBootloaderVersion) === parseInt(device.bootloader.version)) {
                    setBootloaderVersion();
                } else {
                    showModalBoxBootloaderUpgrade();
                }
            }
        };

        function showModalBoxBootloaderUpgrade() {
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/messageBox/bootloaderUpgradeMessageBox.client.view.html',
                className: 'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false,
                controller: ['$scope', function ($scope) {
                    $scope.title = 'BOOTLOADER_UPGRADE_MODAL_TITLE';
                    $scope.message = 'BOOTLOADER_UPGRADE_MODAL_TEXT';
                    $scope.secondMessage = 'BOOTLOADER_UPGRADE_MODAL_SECOND_TEXT';
                    $scope.buttonTitle = 'BOOTLOADER_UPGRADE_MODAL_BUTTON_TITLE';
                    $scope.event = function () {
                        upgradeBootloader();
                    };
                }]
            }).then(function (result) {
                // user did something else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }

        function setBootloaderVersion() {
            var device = $scope.detectedDevice;
            Wmoapi
                .setBootloaderVersion(device.serial, device.bootloader.version)
                .success(function (response) {
                    console.log('setBootloaderVersion - success', response);
                })
                .error(function (error) {
                    console.log('error in setBootloaderVersion', error);
                });
        }

        function upgradeBootloader() {
            //here we need to upgrade bootloader version
            ngDialog.closeAll();
            $scope.flashForKlonOnly = false;
            $scope.modifyFeaturesOnly = false;
            $scope.modifyControlsOnly = false;
            $rootScope.flashInProgress = true;
            dataService.upgradeBootloader = true;
            $scope.loadFlashPartial(true, true);
        }

        $scope.checkDeviceRfDecoderForUpdate = function(){
            $scope.showDeviceNotificationBar = false;
            $scope.showHasFlexPackageNoBillNotificationBar = false;
            $scope.showHasFlexPackageNoBillNotificationBarAfterFlash = false;

            var device = $scope.detectedDevice;
            
            Wmoapi
                .getRfDecoder(device.serial)
                .success(function(response){
                    var rfDecoderDevice = response.rfDecoder.device;
                    $scope.responseData.rfDecoder = rfDecoderDevice;
                    var rfDecoderId = parseInt(rfDecoderDevice.id);
                    var hasFlexPackageNoBill = deviceIdentifier.isDetectedDeviceHasFlexProgramNoBill(rfDecoderDevice.productPartOptions);

                    //show notice if non-embedded rfDecoder has an update
                    if(rfDecoderDevice && rfDecoderId > 0 && !device.hasProgram && !rfDecoderDevice.isEmbedded) {
                        var firmwareDetail = deviceIdentifier.hasFirmwareUpdate(rfDecoderDevice);
                        $scope.showDeviceNotificationBar = firmwareDetail.hasUpdate;
                    }

                    if(hasFlexPackageNoBill && (!rfDecoderDevice.firmwareID || rfDecoderDevice.firmwareID === '0')){
                        $scope.showHasFlexPackageNoBillNotificationBar = true;
                        $scope.showHasFlexPackageNoBillNotificationBarAfterFlash = true;
                    }
                })
                .error(function(error){
                    console.log('error in getRfDecoder',error);
                });

        };

        $scope.checkWiringApiStatus = function (){

            Wiringapi
                .getStatus()
                .success(function(response){
                    $scope.responseData.wiringApiStatus = response;
                    $scope.showWiringSection = response.active;
                })
                .error(function(error){
                });

        };

        $scope.checkAnnouncements = function (){

            var isAnnouncementKlonOutageActive = AppService.isAnnouncementKlonOutageActive();
            var isAnnouncement3LockActive = AppService.isAnnouncement3LockActive();
            var isAnnouncementIEMigrationActive = AppService.isAnnouncementIEMigrationActive();
            var isInWeblinkDesktop = AppService.isPramanthaObjectDetected();

            if (isAnnouncementKlonOutageActive) {
                alertBar.show('message', 'ANNOUNCEMENT_KLON_OUTAGE');
            } else if(!isInWeblinkDesktop && isAnnouncementIEMigrationActive) {
                alertBar.show('message', 'ANNOUNCEMENT_IE_MIGRATION_BODY', {}, 'ANNOUNCEMENT_IE_MIGRATION_TITLE');
            } else if (isAnnouncement3LockActive) {
                alertBar.show('success', 'ANNOUNCEMENT_3_LOCK');
            }

        };

        $scope.getSuspendedRequest = function(){
            var deferred = $q.defer();

            var device = $scope.detectedDevice;
            $rootScope.isGlobalLoading = true;

            $scope.SuspendedRequestResult = klonIdentifier.getSuspendedRequest(device.serial,
                function() {
                    //onKlonGetSuspendedRequestComplete
                    var result = $scope.SuspendedRequestResult;
                    $scope.hasSuspendedRequest = (result.hasSuspendedRequest);
                    $scope.responseData.klonSuspendedRequest = result.suspendedRequest;
                    $scope.request.klonRequestId =result.suspendedRequest.request_id;
                    $rootScope.isGlobalLoading = false;
                    deferred.resolve(result);

                    if(!$scope.hasSuspendedRequest) {
                        $scope.hasKlonFw = deviceIdentifier.hasKlonStep2Service(device.allowedServices);
                    }

                }, function(){
                    //onKlonGetSuspendedRequestError
                    $scope.hasSuspendedRequest = false;
                    $rootScope.isGlobalLoading = false;
                    deferred.reject();
                });

            return deferred.promise;
        };

        $scope.loadKlonProgressPartial = function(){
            var device = $scope.detectedDevice;
            if (device.isKlonHubRequired){
                if (AppService.getIsHub()){
                    $rootScope.isGlobalLoading = true;
                    $scope.resumeKeyreading = true;
                    $scope.loadFlashPartial(false,false);
                } else {
                    alertBar.show('error', 'HUB_REQUIRED_MESSAGE_FW_FAMILY');
                }

            } else {
                $scope.flashForKlonOnly = true;
                $scope.loadFlashPartial(true,false);
            }
        };

        $scope.configRsuFeatures = function() {
            clearPrevVehicleSelections();
            $scope.isConfigRsuFeatures = true;
            $scope.loadFeaturePartial(true);
        };

        $scope.ConfigModuleOnly = function(serial = ''){
            var device = $scope.detectedDevice;
            if (!serial) {
                serial = device.serial;
            }
            DeviceService.setDeviceToFlash(DeviceService.getDeviceBySerial(serial));
            clearPrevVehicleSelections();
            $scope.isConfigRsuFeatures = false;
            $scope.loadFeaturePartial(true);
        };


        $scope.ConfigureNewModule = function(){
            Request.clearRequestDevice();
            $scope.flashForKlonOnly = false;
            $scope.modifyFeaturesOnly = false;
            $scope.modifyControlsOnly = false ;
            $scope.flashForFirmwareUpdateOnly = false;
            $scope.flashForFlexUpgradeOnly = false;
            $scope.showPackageMode = $scope.detectedDevice.hasProgram;
            $scope.showConfigSubMenuPackage =  $scope.showPackageMode ? '' : 'CONFIGURATION_SUBMENU_PACKAGE';
            var configPartialParam =  $scope.detectedDevice.hasProgram ? 'packages' : 'Makes';
            $scope.loadConfigurationPartial(configPartialParam,true);
        };

        $scope.flashFromFavorites = function(){
            Request.clearRequestDevice();
            $scope.flashForKlonOnly = false;
            $scope.modifyFeaturesOnly = false;
            $scope.modifyControlsOnly = false;
            $scope.flashForFirmwareUpdateOnly = false;
            $scope.flashForFlexUpgradeOnly = false;
            $scope.loadFavoritesPartial(false);
        };

        $scope.ConfigRemoteOnly = function (){
            clearPrevVehicleSelections();

            Wmoapi.getRemoteEepromAddress().success(function (response) {
                $scope.responseData.remotePages = response;
                $scope.loadControlsPartial(true);
            }).error(function (error) {
                console.log('--- getRemoteEepromAddress');
            });
        };



        $scope.loadDevicePartial = function (hideAlertBar) {

            if (hideAlertBar === undefined || hideAlertBar) {
                alertBar.hide();
            }

            DeviceService.setDevices(DeviceService.getDevices());
            $scope.detectedDevice = DeviceService.getFlashableDevice();

            //Cancel request when returning to main menu
            if ($scope.request.configModuleRequestId !== '') {
                Wmoapi.cancel($scope.request.configModuleRequestId).success(function (result){
                    $scope.request.configModuleRequestId = '';
                    $scope.modifyFeaturesOnly = false;

                });
            }
            if ($scope.request.id !== '') {
                Wmoapi.cancel($scope.request.id).success(function (result){
                    $scope.request = {
                        id:'',
                        configModuleRequestId:'',
                        configRemotesRequestId:'',
                        klonRequestId:'',
                        configModuleSoloRequestId:'',
                        configAntennaRequestId:'',
                        configBootloaderRequestId: '',
                        configTrialActivationRequestId:'',
                        configBADeviceRequestId: ''
                    };

                });
            }

            var sidebar = $scope.sidebar;

            $scope.skipToWiring = false;
            $scope.skipToGuides = false;

            if (sidebar.mode === 'flash-klon' || $scope.configurationParameters.flashByFirmware) {
                sidebarManager.setSidebarMode('');
                $scope.configurationParameters.flashByFirmware = false;
                $scope.detect();
                return;
            } else if (sidebar.mode === 'configure-module' || sidebar.mode === 'configure-remotes')  {
                $rootScope.isGlobalLoading = false;
                var device = $scope.detectedDevice;
                if(device) {
                    $scope.isAntenna = deviceIdentifier.isDeviceAntenna(device.deviceFunctionId);
                    $scope.isDeviceAccessory = deviceIdentifier.isDeviceAccessory(device.deviceFunctionId);
                    $scope.isDeviceRfid = deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId);
                    $scope.canFlashByVehicle = deviceIdentifier.isFlashByVehicleAllowed(device.allowedServices);
                    $scope.canModifyFeatures = deviceIdentifier.isConfigureModuleAllowed(device.allowedServices);
                    $scope.isIdatastartRemotePairingCompatible = deviceIdentifier.isIdatastartRemotePairingCompatible(device.isIdatastartRemotePairingCompatible);
                    var firmwareDetail = deviceIdentifier.hasFirmwareUpdate(device);
                    $scope.hasFirmwareUpdates = firmwareDetail.hasUpdate;

                }
            }

            sidebarManager.setGuidesWiresSidebarStatus(false);
            $scope.submenu.items = [];
            $scope.submenu.show = false;
            $scope.submenu.partial = 'device';
            $scope.menuArrow.showInVehicle = $scope.submenu.show;
            $scope.menuArrow.showInfeatures = $scope.submenu.show;
            $scope.partial = 'modules/core/views/partials/device/device.client.view.html';
            sidebarManager.setSidebarIndex(0);

            $scope.hasSuspendedRequest = false;
            $scope.hasKlonFw = false;

            var detectedDevice = $scope.detectedDevice;
            var isDeviceKlonnable = deviceIdentifier.isDeviceKlonnable(detectedDevice.deviceFunctionId);

            if(!$scope.isRusWithNoBlade) {

                if (isDeviceKlonnable) {
                    $scope.getSuspendedRequest().then(function () {
                            getLatestRequest(detectedDevice)
                                .then(function () {
                                    $scope.loading = false;

                                    // get remotes from eeprom reading
                                    if (detectedDevice.firmwareID > 0 && detectedDevice.isIdatastartRemotePairingCompatible) {
                                        getRemotesAfterDeviceDetect();
                                    }

                                }, function (error) {
                                    $scope.loading = false;
                                });
                        }, function (error) {
                            $scope.loading = false;
                        }
                    );
                } else {
                    getLatestRequest(detectedDevice)
                        .then(function () {
                            $scope.loading = false;
                        }, function (error) {
                            $scope.loading = false;
                        }
                    );
                }
            } else {
                $scope.loading = false;
                $rootScope.isGlobalLoading = false;
                if(!$rootScope.alertBar.showBar) {
                    alertBar.show('message', 'HOME.ALERT_BAR_WEBLINK_RSU_NO_MODULE_DETECTED');
                }
            }

            $scope.resetBodyCss();
        };

        $scope.loadConfigurationPartial = function (title, showSub) {
            var index = 1;
            alertBar.hide();
            var partials_dir = 'modules/core/views/partials/configuration/{page}.client.view.html';
            var page = title.trim().toLowerCase();
            var partial = partials_dir.replace('{page}', page);
            if (page === 'makes' && partial !== $scope.partial) {
                $scope.submenu.items = [];
            }
            $scope.usedAsAddOn = AppService.usedAsAddOn();
            $scope.standaloneFlags = AppService.getStandaloneFlags();

            $scope.partial = partial;
            $scope.submenu.show = showSub;
            $scope.menuArrow.showInVehicle = showSub;
            $scope.menuArrow.showInfeatures = !showSub;
            if (showSub) {
                $scope.part = partials_dir.replace('{page}', 'submenu');
            }
            sidebarManager.setSidebarMode('flash');
            sidebarManager.setSidebarIndex(index);
            $scope.resetBodyCss();
        };

        $scope.loadInstallOptionsPartial = function () {

            var index = 2;//2
            alertBar.hide();
            $scope.submenu.show = false;
            $scope.menuArrow.showInVehicle = $scope.submenu.show;
            $scope.menuArrow.showInfeatures = $scope.submenu.show;
            $scope.usedAsAddOn = AppService.usedAsAddOn();
            $scope.standaloneFlags = AppService.getStandaloneFlags();

            var partial = 'modules/core/views/partials/installOptions/installOptions.client.view.html';
            $scope.partial = partial;
            sidebarManager.setSidebarIndex(index);
            $scope.resetBodyCss();

        };

        $scope.loadControlsPartial = function () {
            $scope.standaloneFlags = AppService.getStandaloneFlags();

            var index = classicDevIndex + 2;// 2 or 3
            var sidebar = $scope.sidebar;
            if (sidebar.currentIndex === index) return;

            alertBar.hide();

            $scope.submenu.show = false;
            $scope.menuArrow.showInfeatures = $scope.submenu.show;
            $scope.modifyControlsOnly = true;

            var partial = 'modules/core/views/partials/controls/controls.client.view.html';

            $scope.partial = partial;

            var mode = 'configure-remotes';
            sidebarManager.setSidebarMode(mode);
            sidebarManager.setSidebarIndex(index);

            $scope.resetBodyCss();
        };

        $scope.loadInternalRemotesPartial = function (isFlash) {
            var index = classicDevIndex + 2;// 2 or 3
            if (!isFlash) {
                $scope.loadControlsPartial();
                return false;
            }
            $scope.usedAsAddOn = AppService.usedAsAddOn();
            $scope.standaloneFlags = AppService.getStandaloneFlags();

            $scope.submenu.show = false;
            var partial = 'modules/core/views/partials/remotes/remotes.client.view.html';
            $scope.partial = partial;
            sidebarManager.setSidebarMode('flash');
            sidebarManager.setSidebarIndex(index);
            $scope.resetBodyCss();
        };

        $scope.loadTelematicsPartial = function () {
            $scope.standaloneFlags = AppService.getStandaloneFlags();

            var index = classicDevIndex + 3; // 3  or 4
            alertBar.hide();
            $scope.submenu.show = false;
            $scope.menuArrow.showInVehicle = $scope.submenu.show;
            $scope.menuArrow.showInfeatures = $scope.submenu.show;
            var partial = 'modules/core/views/partials/telematics/telematics.client.view.html';
            $scope.partial = partial;
            sidebarManager.setSidebarIndex(index);
            $scope.resetBodyCss();
        };

        $scope.loadFirmwarePartial =  function (skipToFeature) {
            var index = classicDevIndex + 4; // 4 or 5
            $scope.loading = false;
            var sidebar = $scope.sidebar;
            $scope.usedAsAddOn = AppService.usedAsAddOn();
            $scope.standaloneFlags = AppService.getStandaloneFlags();
            $scope.submenu.items = [];
            $scope.submenu.show = false;
            $scope.submenu.partial = 'firmware';

            sidebar.mode = skipToFeature ? 'configure-module' : 'flash';
            sidebar.currentIndex = index;
            $scope.partial = 'modules/core/views/partials/firmware/firmware.client.view.html';
            $scope.resetBodyCss();

        };

        $scope.loadFavoritesPartial =  function (configOnly) {
            var index = 0;
            $scope.configurationParameters.flashFromFavoriteConfig = configOnly;
            if (configOnly) {
                index = classicDevIndex + 5;
            } else {
                index = classicDevIndex + 1; // 4 or 5
            }

            $scope.loading = false;
            var sidebar = $scope.sidebar;
            $scope.usedAsAddOn = AppService.usedAsAddOn();
            $scope.standaloneFlags = AppService.getStandaloneFlags();
            $scope.submenu.show = false;
            sidebar.mode = 'flash';
            sidebar.currentIndex = index;
            $scope.partial = 'modules/core/views/partials/favorites/favorites.client.view.html';
            $scope.resetBodyCss();
        };

        $scope.loadOemRemoteStartPartial = function () {
            var index = classicDevIndex + 5; //5 or 6;
            alertBar.hide();
            $scope.submenu.show = false;
            $scope.menuArrow.showInVehicle = $scope.submenu.show;
            $scope.menuArrow.showInfeatures = $scope.submenu.show;
            var partial = 'modules/core/views/partials/oemRemoteStart/oemRemoteStart.client.view.html';
            $scope.partial = partial;
            sidebarManager.setSidebarIndex(index);
            $scope.resetBodyCss();
        };
        /**
         * @param skipToFeature boolean Set to configure-module mode only
         */
        $scope.loadFeaturePartial = function(skipToFeature){

            var index = classicDevIndex + 6; //6 or 7
            var sidebar = $scope.sidebar;

            if(sidebar.currentIndex === index) return;

            alertBar.hide();

            $scope.submenu.show = true;
            $scope.menuArrow.showInGuides = false;
            $scope.menuArrow.showInfeatures = $scope.submenu.show;
            $scope.submenu.partial = 'feature';
            $scope.modifyFeaturesOnly = skipToFeature;

            var partial = 'modules/core/views/partials/features/features.client.view.html';

            if(partial !== $scope.partial) {
                $scope.submenu.items = [];
                $scope.partial = partial;
            }

            var mode = skipToFeature ? 'configure-module' : 'flash';
            sidebarManager.setSidebarMode(mode);
            sidebarManager.setSidebarIndex(index);

            $scope.part = 'modules/core/views/partials/features/submenu.client.view.html';
            $scope.resetBodyCss();
        };

        $scope.loadGuidesPartial = function() {
            var index = classicDevIndex + 7; //7 or 8
            alertBar.hide();

            $scope.submenu.show = $scope.showWiringSection;
            $scope.submenu.selectedItem = 'All';
            $scope.submenu.partial = 'guides';
            $scope.menuArrow.showInGuides = $scope.submenu.show;
            $scope.menuArrow.showInfeatures = false;
            $scope.part = 'modules/core/views/partials/guides/submenu.client.view.html';
            var guidesWiresPartial =  'modules/core/views/partials/guides/guidesWires.client.view.html';
            var guidesPartial = 'modules/core/views/partials/guides/guides.client.view.html';

            var partial;
            partial = $scope.showWiringSection ? guidesWiresPartial : guidesPartial;

            if(partial !== $scope.partial) {
                $scope.submenu.items = [];
                $scope.partial = partial;
            }

            sidebarManager.setSidebarIndex(index);
            $scope.resetBodyCss();
        };

        $scope.loadFlashPartial = function(skipToKlon, flashForFlexUpgradeOnly){
            var sidebar = $scope.sidebar;
            var index = classicDevIndex + 8; //8 or 9


            if ($scope.configurationParameters.flashByFirmware){
                var device = DeviceService.getDeviceBySerial(Request.getRequestDevice().serial);
                if (deviceIdentifier.isConfigureModuleAllowed(device.allowedServices)){
                    $scope.$broadcast('PartialsFeatures_OnSubmitFeatureUpdates');
                }
            }

            if ($scope.configurationParameters.flashFromFavorite) {
               sidebarManager.setPreventConfiguration(true);
            } else {
                sidebarManager.setPreventConfiguration(false);
            }

            $scope.flashForFlexUpgradeOnly = flashForFlexUpgradeOnly;
            $scope.menuArrow.showInGuides = false;
            if(sidebar.currentIndex === index) return;
            alertBar.hide();
            $scope.submenu.show = false;
            var partial = 'modules/core/views/partials/flash/flash.client.view.html';
            if (partial !== $scope.partial) {
                $scope.submenu.items = [];
                $scope.partial = partial;
            }
            var mode = skipToKlon ? 'flash-klon' : 'flash';
            sidebarManager.setSidebarMode(mode);
            sidebarManager.setSidebarIndex(index);
            $scope.resetBodyCss();
        };

        $scope.showDetectFail = function (errorStringKey, errorStringHeaderKey, errorCode) {
            var errorMessage = _.isEmpty(errorStringKey) ? '' : (_.isString(errorStringKey.errorMsg) ? errorStringKey.errorMsg : (_.isString(errorStringKey.error) ? errorStringKey.error : errorStringKey));
            $scope.detectionErrorMsgHeader = !_.isEmpty(errorStringHeaderKey) ? errorStringHeaderKey : 'DETECTION_ERROR_PAGE_TITLE';

            if (errorMessage === 'DETECT_INVALID_DEVICE' || errorMessage === 'DETECT_NO_DEVICE'){
                if (errorCode === constants.DETECT_USB_ERROR) {
                    errorMessage += '_USB_NOT_DETECTED';
                }
                if (errorCode === constants.CONNECT_ERROR) {
                    errorMessage += '_USB_DETECTED';
                }
            }
            $scope.detectionErrorMsg = errorMessage;
            $scope.detectionErrorUsbDetected = errorCode === constants.CONNECT_ERROR;

            switch (AppService.getFlashConnectionType()) {
                case 'hub':
                    $scope.detectionErrorConnectionType = 'HUB';
                    break;
                case 'weblink-updater':
                    $scope.detectionErrorConnectionType = 'Weblink Cable';
                    break;
                default:
                    $scope.detectionErrorConnectionType = AppService.getFlashConnectionType();
            }
            $scope.detectionError = true;
        };

        $scope.resetBodyCss = function(){
            toggleClass(false,'#body','body-trim');
            toggleClass(false,'#body','body-model');
            toggleClass(true,'#body','standard');
        };

        /**
         * Used for page bottom buttons ie: save or go to functions
         * Used for page navigation
         * @param skipTo string page to skip to
         */
        $scope.skipstep = function(skipTo){

            switch (skipTo.toLowerCase()){
                case 'controls':
                case 'remotes':
                    $scope.loadControlsPartial(false);
                    break;
                case 'internal-remotes':
                  $scope.loadInternalRemotesPartial(true);
                  break;
                case 'device':
                    $scope.loadDevicePartial();
                    break;
                case 'guides':
                    $scope.$broadcast('PartialsFeatures_OnSubmitFeatureUpdates');
                    $scope.loadGuidesPartial();
                    break;
                case 'features':
                    $scope.loadFeaturePartial(false);
                    break;
                case 'save-firmware-remotes':
                    $scope.$broadcast('PartialsFirmware_OnSaveFirmwareFlashMode');
                    break;
                case 'features-save-remotes':
                    $scope.$broadcast('PartialsControls_OnSaveRemotesFlashMode');
                    break;
                case 'features-save-telematics':
                case 'remotes-save-telematics':
                    $scope.$broadcast('PartialsTelematics_OnSaveTelematicsFlashMode');
                    break;
                case 'firmware':
                    $scope.$broadcast('PartialsTelematics_OnSaveTelematicsFlashMode');
                    $scope.loadFirmwarePartial(false);
                    break;
                case 'flash':
                    $scope.loadFlashPartial(false,false);
                    break;
                case 'flash-from-guides':
                    $scope.$broadcast('PartialsGuides_OnClickNextToFlash');
                    break;
                case 'oem-remote-start':
                  $scope.loadOemRemoteStartPartial();
                  break;
                case 'oem-start-save-config':
                  $scope.$broadcast('oem-start-save-config');
                  break;
                case 'telematics':
                    $scope.loadTelematicsPartial(false);
                    break;
                case 'protocols':
                    $scope.loadInstallOptionsPartial();
                    break;
                case 'vehicle.makes':
                    $scope.loadConfigurationPartial('Makes', true);
                    break;
                case 'vehicle.models':
                    $scope.loadConfigurationPartial('Models', true);
                    break;
                case 'vehicle.trims':
                    $scope.loadConfigurationPartial('Trims', true);
                    break;
                case 'vehicle.years':
                    $scope.loadConfigurationPartial('Years', true);
                    break;
                case 'vehicle.packages':
                    $scope.loadConfigurationPartial('packages', true);
                    break;
                default:
                    console.log('skip step: '+skipTo+' not supported.');
            }

        };
        $rootScope.globalSkipStep = function(skipTo){
            $scope.skipstep(skipTo);
        };
        $scope.changeLanguage = function(lang) {
            translateService.setCurrentLanguage(lang);
            $rootScope.globalLangCurrent = translateService.getCurrentLanguage();
        };
        function toggleClass(isAdd, qrySelector, className){

            var angular_element = angular.element(document.querySelector(qrySelector));
            if(isAdd) angular_element.addClass(className);
            else angular_element.removeClass(className);

        }

        $scope.doFirmwareUpdate = function(skipSlider){
            $rootScope.isGlobalLoading = true;
            var devices = DeviceService.getDevices();
            var serials = devices.filter(function(device){
                return deviceIdentifier.hasFirmwareUpdate(device).hasUpdate;
            }).map(function(device) {
                return device.serial;
            });

            var isStaffUserHubUpdate = $rootScope.globalUser.profile.userRoleId === constants.INTERNAL_USER_ROLE_ID && deviceIdentifier.isDeviceHub($scope.detectedDevice.partId);
            var isBetaUserHubUpdate = $rootScope.globalUser.profile.userRoleId === constants.BETA_USER_ROLE_ID && deviceIdentifier.isDeviceHub($scope.detectedDevice.partId);

            if (isStaffUserHubUpdate && $scope.detectedDevice.firmwareID && $scope.detectedDevice.firmwareID !== '0') {
                createRequestFirmwareUpdateStaff($scope.detectedDevice, serials);
            } else if (isBetaUserHubUpdate && $scope.detectedDevice.firmwareID && $scope.detectedDevice.firmwareID !== '0' && !$scope.detectedDevice.isFirmwareUpdateForced) {
                createRequestFirmwareUpdateStaff($scope.detectedDevice, serials);
            } else {
                Wmoapi.getLatestFirmwareInfo(serials)
                    .then(function(success) {
                        $scope.responseData.latestFirmwareInfo = success.data;
                        if(skipSlider) {
                            console.log('handling update hub');
                            ngDialog.openConfirm({
                                template: 'modules/core/views/modals/hubfirmware.modal.client.view.html',
                                className:'ngdialog-theme-plain'
                            }).then(function(result){
                                    handleUpdate(serials);
                                },
                                function(error){
                                    handleUpdate(serials);
                                });

                        } else{
                            openFirmwareUpdateSlider(success.data);
                        }
                    }, function(error) {
                        $scope.showErrorMessageFromServer('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUEST', error.data);
                    });
            }
        };

        $scope.resetFirmwareFlag = function () {
            $scope.firmwareAlreadySet = false;
        };

        $scope.reFlashDeviceBySerial = function (serial) {
            var device = DeviceService.getDeviceBySerial(serial);
            flashDevice(device);
        };

        $scope.reFlashDevice = function () {
            var device = $scope.detectedDevice;
            flashDevice(device);
        };

        function flashDevice(device) {
            $scope.request.id = '';
            $scope.responseData.firmwareDownload = {};
            $scope.request.configModuleRequestId = '';
            $scope.responseData.configModuleDownload = {};
            $rootScope.isGlobalLoading = true;
            var serial = device.serial;
            var bootloader = device.bootloader.version;

            Wmoapi
                .createRequestFlashByFwNoParent(bootloader, serial)
                .then(function(response){
                    var requestId = response.data.requestId;
                    $scope.request.id = requestId;
                    Request.setRequestDevice(requestId, bootloader, serial);
                    return requestId;
                })
                .then(Wmoapi.getProtocols)
                .then(function(response){
                    //Get protocol ID for FLRSBA device
                    if(deviceIdentifier.isDetectedDeviceFwDependant(device.productPartOptions)){
                        return Wmoapi.getLatestRequest(bootloader, serial).then(function(latestRequest){
                            return Wmoapi.setProtocol($scope.request.id, latestRequest.data.action.protocol.id);
                        });
                    } else {
                        var protocols = response.data.filter(function(item){ return item.isDefault === 1; });
                        if (protocols.length === 0){
                            return Promise.reject({data: 'No protocol found.'});
                        }
                        var protocolId = protocols[0].id;
                        return Wmoapi.setProtocol($scope.request.id, protocolId);
                    }
                })
                .then(function(response){
                    if (response.data.result) {
                        return Wmoapi.getFirmwareFamilies($scope.request.id);
                    } else {
                        return Promise.reject({data: 'Cannot set protocol'});
                    }
                })
                .then(function(response){
                    if(response.data.length === 1){
                        return Wmoapi.setFirmwareFamily($scope.request.id, response.data[0].id);
                    } else {
                        return Promise.reject({data: 'Cannot infer firmware family'});
                    }
                })
                .then(function (response){
                    if (response.data.result) {
                        return Wmoapi.getFirmwares($scope.request.id);
                    } else {
                        return Promise.reject({data: 'Cannot set firmware family'});
                    }
                })
                .then(function(response){
                    $rootScope.isGlobalLoading = false;
                    return ngDialog.openConfirm({
                        template: 'modules/core/views/partials/firmware/directives/hubfirmwareslider.modal.client.directive.view.html',
                        className: 'ngdialog-theme-plain firmware-selection',
                        controller: 'PartialsFirmwareSliderModalController as ctrl',
                        resolve: {
                            alternativeFirmwares: function() {
                                return response.data.firmwares;
                            },
                            currentFirmware: function() {
                                return $scope.responseData.currentFirmware;
                            }
                        },
                        scope: $scope,
                        closeByDocument: true
                    });
                }).then(function(result) {
                sliderOpen = false;
                $rootScope.isGlobalLoading = true;
                $scope.firmwareAlreadySet = true;
                return Wmoapi.setFirmware($scope.request.id, result.id);
            }, function(error) {
                sliderOpen = false;
                $rootScope.isGlobalLoading = false;
                $scope.showErrorMessageFromServer('FLASH_FIRMWAREUPDATE_ERROR_SETFIRMWARE', error.data);
                return Wmoapi.cancel($scope.request.id);
            })
                .then(function (response) {
                    $scope.responseData.setFirmware = response.data;
                    dataService.mainDeviceHasFwUpdate = true;
                    if ( response.data.result && $scope.responseData.setFirmware.hasLcdRemoteFirmwareUpdate && $scope.firmwareAlreadySet ) {
                        return Wmoapi.createRequestFlashExternalMemory(serial, $scope.request.id).then(function(response){
                            $scope.externalMemoryRequest.id = response.data.requestId;
                            handleUpdate([serial]);
                        });
                    } else if ( response.data.result && $scope.firmwareAlreadySet ) {
                        handleUpdate([serial]);
                    }
                })
                .catch(function (error) {
                    $scope.showErrorMessageFromServer('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUEST', error.data);
                });
        }

        function createRequestFirmwareUpdateStaff(device, serials) {

            $scope.request.id = '';
            $scope.responseData.firmwareDownload = {};
            $scope.request.configModuleRequestId = '';
            $scope.responseData.configModuleDownload = {};
            var serial = device.serial;
            var bootloader = device.bootloader.version;
            $rootScope.isGlobalLoading = true;
            $rootScope.flashInProgress = true;
            $scope.flashing = true;
            alertBar.hide();

            Wmoapi
                .createRequestFirmwareUpdate(bootloader, serial)
                .success(function (response) {
                    $rootScope.isGlobalLoading = true;
                    var requestId = response.requestId;
                    $scope.request.id = requestId;
                    Request.setRequestDevice(requestId, bootloader, serial);
                    Wmoapi.getFirmwares(requestId).then(function(response){
                        $rootScope.isGlobalLoading = false;
                        ngDialog.openConfirm({
                            template: 'modules/core/views/partials/firmware/directives/hubfirmwareslider.modal.client.directive.view.html',
                            className: 'ngdialog-theme-plain firmware-selection',
                            controller: 'PartialsFirmwareSliderModalController as ctrl',
                            resolve: {
                                alternativeFirmwares: function() {
                                    return response.data.firmwares;
                                },
                                currentFirmware: function() {
                                    return $scope.responseData.currentFirmware;
                                }
                            },
                            scope: $scope,
                            closeByDocument: false
                        }).then(function(result) {
                            sliderOpen = false;
                            $rootScope.isGlobalLoading = false;
                            $scope.firmwareAlreadySet = true;
                            return Wmoapi.setFirmware(requestId, result.id);
                        }, function(error) {
                            sliderOpen = false;
                            $rootScope.isGlobalLoading = false;
                            return Wmoapi.cancel(requestId);
                        }).then(function(){
                            if($scope.firmwareAlreadySet){
                                var uniqueSerials = new Set([serial, ...serials]);
                                handleUpdate([...uniqueSerials]);
                            } else {
                                var restartHub = wu.restartHub($scope.detectedDevice, $window.location.reload(true), onRestartHubFailed);
                                restartHub.start();
                            }
                        });
                    });

                })
                .error(function (error) {
                    $scope.showErrorMessageFromServer('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUEST', error);
                });

        }
        function interceptFirmwareUpdate(){

            ngDialog.openConfirm({
                template: 'modules/core/views/modals/firmware.modal.client.view.html',
                className:'ngdialog-theme-plain intercept-firmware'
            }).then(function(result){
                $scope.ConfigureNewModule();
            });
        }

        function openFirmwareUpdateSlider(response) {

            $rootScope.isGlobalLoading = false;
            
            if (dataService.mainDeviceHasFwUpdate) {
                var allowsFlashByVehicle = deviceIdentifier.isFlashByVehicleAllowed($scope.detectedDevice.allowedServices);
                var isRequestInfoAvailable = response.devices[0].oldRequestInfoAvailable;

                if (!isRequestInfoAvailable && allowsFlashByVehicle) {
                    interceptFirmwareUpdate();
                    return;
                }
            }

            if (sliderOpen) {
                return;
            }
            sliderOpen = true;

            ngDialog.openConfirm({
                template: 'modules/core/views/directives-partial/firmwareSlider.client.directive.view.html',
                className: 'ngdialog-theme-plain firmware-selection',
                controller: 'PartialsFirmwareUpdateSliderModalController as ctrl',
                resolve: {
                    mainDeviceFirmwareInfo: function() {
                        return dataService.mainDeviceHasFwUpdate ? response.devices[0] : null;
                    },
                    /**
                     * @return {string}
                     */
                    embeddedDevicesFirmwareInfo: function() {
                        return dataService.mainDeviceHasFwUpdate ? response.devices.slice(1) : response.devices;
                    }
                },
                scope: $scope,
                closeByDocument: true
            }).then(function(result) {
                sliderOpen = false;
                var serials = Object.keys(result).filter(function(item){ return result[item] === true; });
                if(serials.length) {
                    handleUpdate(serials);
                }

            }, function(error) {
                // user closed dialog - do nothing
                sliderOpen = false;
            });

        }

        $scope.flashByFirmwareEmbeddedDevice = function(serial) {
            $scope.serialToUpdate = serial;
            ngDialog.openConfirm({
                template: 'modules/core/views/modals/flashByFirmware.modal.client.view.html',
                className: 'ngdialog-theme-plain',
                scope: $scope
            }).then(function () {
                $scope.firmwareAlreadySet = true;
                $scope.configurationParameters.flashByFirmware = true;
                var requestDevice = Request.getRequestDevice();
                $scope.request.id = requestDevice.id;
                dataService.mainDeviceHasFwUpdate = false;
                dataService.embeddedDevicesHasFwUpdate = true;
                dataService.embeddedDevicesSerialsToUpdate = [serial];
                dataService.mainDeviceSerialToUpdate = null;

                if ( $scope.responseData.setFirmware.hasLcdRemoteFirmwareUpdate ) {
                    return Wmoapi.createRequestFlashExternalMemory(serial, $scope.request.id).then(function(response){
                        $scope.externalMemoryRequest.id = response.data.requestId;
                        $scope.loadFlashPartial(true, false);
                    });
                } else  {
                    $scope.loadFlashPartial(true, false);
                }
            });
        };

        $scope.flashByFirmware = function () {
            $scope.serialToUpdate = DeviceService.getFlashableDevice().serial;
            ngDialog.openConfirm({
                template: 'modules/core/views/modals/flashByFirmware.modal.client.view.html',
                className: 'ngdialog-theme-plain',
                scope: $scope
            }).then(function (result) {
                    $scope.firmwareAlreadySet = true;
                    var requestDevice = Request.getRequestDevice();
                    $scope.request.id = requestDevice.id;
                    $scope.configurationParameters.flashByFirmware = true;
                    dataService.mainDeviceSerialToUpdate = requestDevice.serial;
                    dataService.mainDeviceHasFwUpdate = true;
                    dataService.embeddedDevicesHasFwUpdate = false;
                    dataService.embeddedDevicesSerialsToUpdate = null;

                    if($scope.responseData.setFirmware.hasLcdRemoteFirmwareUpdate){
                        Wmoapi.createRequestFlashExternalMemory(requestDevice.serial, $scope.request.id).then(function(response){
                            $scope.externalMemoryRequest.id = response.data.requestId;
                            if(!deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId)){
                                $scope.skipstep('oem-remote-start');
                            } else {
                                $scope.skipstep('flash');
                            }
                        });
                    } else {
                        if(!deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId)){
                            $scope.skipstep('oem-remote-start');
                        } else {
                            $scope.skipstep('flash');
                        }
                    }



                },
                function (error) {
                    var requestDevice = Request.getRequestDevice();
                    Wmoapi.cancel(requestDevice.id);
                }
                );
        };

        $scope.retryUpdateBySerial = function(serial) {
            handleUpdate([serial]);
        };
        function handleUpdate(serials) {

            $scope.flashForKlonOnly = false;
            $scope.modifyFeaturesOnly = false;
            $scope.modifyControlsOnly = false;
            $scope.flashForFirmwareUpdateOnly = true;

            var mainDevice = serials.indexOf($scope.detectedDevice.serial) > -1 ? $scope.detectedDevice.serial : false;
            var embeddedDevices = $scope.embeddedDevices.length ? 
                serials.filter(function(item) { return item !== $scope.detectedDevice.serial; }) 
                : false;
            var rsuDevice = $scope.rsuDevice && serials.indexOf($scope.rsuDevice.serial) > -1 ? $scope.rsuDevice.serial : false;

            dataService.mainDeviceSerialToUpdate = !$scope.isRusWithNoBlade ? mainDevice : false;
            dataService.embeddedDevicesSerialsToUpdate = embeddedDevices;
            dataService.rsuDeviceSerialToUpdate = rsuDevice;

            if (mainDevice) {
                if(!embeddedDevices) dataService.embeddedDevicesHasFwUpdate = false;
                if(!rsuDevice) dataService.rsuDeviceHasFwUpdate = false;
                $scope.loadFlashPartial(true, false);
            } else {
                if(rsuDevice) {
                    dataService.mainDeviceHasFwUpdate = false;
                    dataService.embeddedDevicesHasFwUpdate = false;
                    $scope.loadFlashPartial(true, false);
                }
                if(embeddedDevices) {
                    dataService.mainDeviceHasFwUpdate = false;
                    $scope.loadFlashPartial(true, false);
                }
            }

        }
        $scope.RedirectToIdatalink=function(){
            location.href = idatalinkUrl;
        };
        $scope.closeDeviceNotificationBar=function(){
            $scope.showDeviceNotificationBar = false;
            $scope.showHasFlexPackageNoBillNotificationBar = false;
        };
        $scope.closeDeviceNotificationBarAfterFlash=function(){
            $scope.showHasFlexPackageNoBillNotificationBarAfterFlash = false;
        };
        $scope.showErrorMessageFromServer=function(translateKey, errorMsgFromServer){
            var error_msg = _.isEmpty(errorMsgFromServer) ? '' : ' [ ' + (errorMsgFromServer.error || errorMsgFromServer) + ' ]';
            alertBar.show('error', translateKey, {ERROR_MSG: error_msg});
        };
        function getLatestRequest(device) {
            var deferred = $q.defer();
            $scope.latestRequest  = {  action:null, package: null, image:'' };
            $scope.lastVehicleFlashed = undefined;
            $scope.rsuRemoteControl = undefined;
            var ownerGuide = getOwnerGuide (device);

            Wmoapi.getLatestRequest(device.bootloader.version, device.serial)
                .then(function(success) {

                    $scope.latestRequest.package = success.data.package ? success.data.package : null;
                    $scope.latestRequest.image = success && success.data && success.data.vehicleImage ? success.data.vehicleImage : 'img/generic/no-car-img.png';

                    //requires success.data.action
                    $scope.latestRequest.action = null;
                    $scope.lastVehicleFlashed = null;
                    $scope.rsuRemoteControl = '';
                    $scope.telematic = null;
                    if(success.data.action) {
                        $scope.latestRequest.action = success.data.action;
                        if (success.data.action.vehicle) {
                            var v = success.data.action.vehicle;
                            $scope.lastVehicleFlashed = v;
                            if (v.vehicleMakeId === constants.OTHER_VEHICLE_MAKE_ID.toString()) {
                                v.vehicleShortName = v.vehicleMake;
                            }
                        }
                        $scope.rsuRemoteControl = success.data.rsuRemoteControl ? success.data.rsuRemoteControl : '';
                        $scope.telematic = success.data.telematic ? success.data.telematic : null;
                    }

                    $scope.latestRequest.ownerGuide = ownerGuide ? ownerGuide : '';
                    $scope.controlsObject = getControlsItems(success.data, device.firmwareID);
                    $scope.hideControlsCard = success.data.hideControlsCard || (!$scope.canModifyFeatures && ($scope.isDeviceAccessory || $scope.isDeviceRfid));

                    deferred.resolve(success);
                }, function(error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function getRemotesAfterDeviceDetect() {
            Wmoapi.getRemoteEepromAddress().success(function (response) {
                $rootScope.isGlobalLoading = true;
                $scope.responseData.remotePages = response;
                readRemotePages(response);
            }).error(function (error) {
                $rootScope.isGlobalLoading = false;
                alertBar.show('error', 'DETECTION_ERROR_REMOTE_GET_EEPROM');
            });
        }
        function readRemotePages(remotePages) {
            if (remotePages.length > 0) {
                var device = $scope.detectedDevice;
                $scope.readRemotePagesResult = wu.getReadModuleResult(device, remotePages, $scope.onReadRemotePagesComplete, onReadRemotePagesError);
                $scope.readRemotePagesResult.start();
            } else {
                $rootScope.isGlobalLoading = false;
                alertBar.show('error', 'DETECTION_ERROR_REMOTE_READ_EEPROM');
            }
        }
        $scope.onReadRemotePagesComplete = function () {
            var readings = wu.transformPageReadingsToArray($scope.readRemotePagesResult.configuration);
            $scope.responseData.remotePagesReadings = readings;
            getRemotesOnDetect(readings);
        };
        function onReadRemotePagesError (error) {
            $rootScope.isGlobalLoading = false;
            var error_msg = _.isEmpty(error) ? '' : ' [ ' + (error.error || error) + ' ]';
            alertBar.show('errorFull', 'DETECTION_ERROR_REMOTE_READ_EEPROM', {'ERROR_MSG' : error_msg});
        }
        function getRemotesOnDetect(pageReadings) {
            Wmoapi
                .getDeviceRemotes($scope.detectedDevice.serial, pageReadings)
                .success(function (response) {
                    var remotes = response.remotes;
                    var countRemoteIncompatible = response.countRemoteIncompatible;

                    if (remotes && (remotes.length > 0 || countRemoteIncompatible > 0)) {
                        var rfControl = {
                            image: 'img/remotes/generic_summary_grey.png',
                            name: 'iDatastart',
                            hasUnrecognizedRemote: (countRemoteIncompatible > 0),
                            unrecognizedRemoteCount: countRemoteIncompatible,
                            remotes: remotes
                        };
                        $scope.controlsObject.slides.unshift($scope.controlsObject.slides.length + 1);
                        $scope.controlsObject.array.unshift(rfControl);
                    }

                    $rootScope.isGlobalLoading = false;
                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show('error', 'DETECTION_ERROR_REMOTE_GET_INFO');
                });
        }

        function getControlsItems (data, fwId) {
            var i = 0;
            var controlsObject = {};
            var slides= [];
            var array = [];
            var telematicObj = {name: 'No Telematics', image: 'img/telematics/flash-summary/phone/no-phone.png', isTelematic: false};
            var oemObj = {name: 'No Oem Remote', image:'img/flash/no-remote-oem.png', isOem: false};
            var hasProtocol = $scope.latestRequest && $scope.latestRequest.action && $scope.latestRequest.action.protocol;
            var protocol = hasProtocol ? $scope.latestRequest.action.protocol : null;

            if(fwId > 0) {

                // Do not load remotes from data.remoteControls
                // Do getRemotesAfterDeviceDetect()

                if (data && data.rsuRemoteControl) {
                    slides.push(++i);
                    array.push(data.rsuRemoteControl);
                } else if (isDetectedDeviceIsClassic && hasProtocol) {
                    if (protocol.isRf) {
                        var rfProtocol = {
                            name: protocol.name,
                            image: 'img/remotes/generic_summary_grey.png',
                            description: '',
                            brand: ''
                        };
                        slides.push(++i);
                        array.push(rfProtocol);
                    }
                }

                if (data && data.telematic) {
                    telematicObj = data.telematic;
                    telematicObj.image = data.telematic.images.phone;
                    telematicObj.isTelematic = true;
                } else if (isDetectedDeviceIsClassic && hasProtocol) {
                    if (protocol.isTelematic) {
                        telematicObj.name = protocol.name;
                        telematicObj.image = 'img/telematics/flash-summary/phone/generic.png';
                        telematicObj.isTelematic = !protocol.isRf;
                    }
                }
            }

            slides.push(++i);

            if(data && data.oemRemoteControlConfig && fwId > 0){
                oemObj = data.oemRemoteControlConfig;
                oemObj.name = data.oemRemoteControlConfig.feature_configuration_option_name;
                oemObj.isOem =  true;
                oemObj.image = 'img/flash/remote-oem.png';
            }
            slides.push(++i);

            if (telematicObj.isTelematic){
                array.push(telematicObj);
                array.push(oemObj);
            } else {
                array.push(oemObj);
                array.push(telematicObj);
            }

            controlsObject.array = array;
            controlsObject.slides = slides;

            return controlsObject;
        }

        function getOwnerGuide (device) {

            var productId = device.productID;
            var ownerLink = '';
            var ownerGuide = null;
            if(guidesService.ownerGuidesHash[productId]){
                ownerLink = guidesService.ownerGuidesHash[productId];
            }
            if(ownerLink){
               ownerGuide = guidesService.extractOwnerGuideFromUrl(ownerLink);
            }

            return ownerGuide;

        }

        $scope.goToWeblinkDesktop =  function(){
            window.WD_download_latest_version();
        };

    }
]);
