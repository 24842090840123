angular.module('core').run(['$templateCache', function($templateCache) {
  'use strict';

  $templateCache.put('modules/core/views/consume-token/home.client.view.html',
    "<section id=\"ConsumeTokenHomeController\" data-ng-controller=\"ConsumeTokenHomeController\" ng-init=\"initConsumeTokenHome()\">\n" +
    "<div class=\"token-view\">\n" +
    "    <div class=\"container\">\n" +
    "        <div class=\"content\">\n" +
    "            <div class=\"text-center logo\">\n" +
    "                <img src=\"img/splash/logo_weblink.png\" />\n" +
    "            </div>\n" +
    "            <div class=\"text-center\">\n" +
    "                <div class=\"spinner\"><i class=\"fa fa-spinner fa-pulse fa-4x\"></i></div>\n" +
    "            </div>\n" +
    "            <div class=\"text-center msg\">\n" +
    "                {{ theMessage | translate: ({ 'WAIT_TIME':redirectTimeInSeconds }) }}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/agreementsModal.html',
    "<section class=\"legal-content\">\n" +
    "    <div class=\"legal-title\" ng-show=\"mustAgree\">\n" +
    "        <p>{{ 'TERMS_OF_USE_TITLE' | translate }}</p>\n" +
    "    </div>\n" +
    "    <div class=\"legal-text\">\n" +
    "        <terms ng-bind-html=\"message\"></terms>\n" +
    "        <div ng-hide=\"message\">\n" +
    "            <div class=\"spinner\"><i class=\"fa fa-spinner fa-pulse fa-4x\"></i></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"legal-text-controls\" ng-show=\"message\">\n" +
    "        <div ng-show=\"mustAgree\" class=\"button-container legal-button-container\">\n" +
    "            <button type=\"button\" class=\"large-btn\" ng-bind=\"'TERMS_OF_USE_BACK_BUTTON' | translate\" ng-click=\"back()\"></button>\n" +
    "            <button type=\"button\" class=\"large-btn\" ng-bind=\"'TERMS_OF_USE_AGREE_BUTTON' | translate\" ng-click=\"agree()\"></button>\n" +
    "        </div>\n" +
    "        <div ng-hide=\"mustAgree\" class=\"button-container legal-button-container\">\n" +
    "            <button type=\"button\" class=\"large-btn\" ng-bind=\"'TERMS_OF_USE_CLOSE_BUTTON' | translate\" ng-click=\"close()\"></button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/component.container.html',
    "<div class=\"component-details-container\">\n" +
    "    <div>{{ 'IMAGE_INFO_COMPONENT_NAME'  | translate}}</div>\n" +
    "    <div class=\"component-name\">{{tags[0].component | sanitizename }}</div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/connector.container.html',
    "<div class=\"connector-details-container\">\n" +
    "    <div>{{ 'IMAGE_INFO_CONNECTOR_NAME' | translate }}</div>\n" +
    "    <div class=\"connector-name\">{{ tags[0] | connectorlocation }}</div>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/directives-partial/controlPreview.device.html',
    "\n" +
    "    <div class=\"image-container-block\">\n" +
    "        <img ng-src={{control.image}} alt={{control.name}}/>\n" +
    "        <p class=\"description\" ng-if=\"control.isOem\">OEM</p>\n" +
    "        <p class=\"description\">{{control.brand}}</p>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"device-container\">\n" +
    "        <div class=\"device-name\">{{control.name}} <i ng-if=\"control.hasUnrecognizedRemote\" class=\"remote-warning fa fa-warning fa-1x\" uib-tooltip=\"{{ control.unrecognizedRemoteCount }} {{'REMOTE_UNRECOGNIZED_WARNING' | translate }}\" tooltip-placement=\"top\"></i>\n" +
    "        </div>\n" +
    "        <div ng-if=\"control.remotes.length > 0\" ng-repeat=\"c in control.remotes\" class=\"device-details\">\n" +
    "            <span class=\"config-title\">{{ 'REMOTE_MODAL_SN' | translate }}</span>\n" +
    "            <span>{{ c.t_code ? c.t_code : c.esn }}</span> <img ng-if=\"c.isProgrammedTo2ndCar\" class=\"second-car-warning\" ng-src=\"img/remotes/two_car.png\" uib-tooltip=\"{{'REMOTE_PROGRAMMED_2ND_CAR' | translate }}\" tooltip-placement=\"top\" />\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"showVariable show0 show1 button-container\" ng-hide=\"!control.skipStep || flashFromFavorite\">\n" +
    "        <button class=\"small-btn\" ng-disabled=\"isDeviceUsedAsAddOn\" type=\"button\"\n" +
    "            ng-click=\"skipstep(control.skipStep)\">{{ 'FLASH_MODIFY_BUTTON' | translate }} </button>\n" +
    "    </div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/crop.menu.label.html',
    "<a uib-tooltip=\"{{ labelCropped }}\" tooltip-placement=\"right\">\n" +
    "    {{ labelCropped | limitTo: 15 }}{{labelCropped.length > 15 ? '...' : ''}}\n" +
    "</a>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/detected.device.html',
    "\n" +
    "<div ng-show=\"flexSliderOpen\" class=\"flex-slider\" ng-class=\"flexSliderOpen ? 'open' : ''\">\n" +
    "  <h2>{{ 'DEVICE_FLEX_TITLE' | translate }}</h2>\n" +
    "  <div class=\"row text-center\">\n" +
    "    <div class=\"col-sm-4 text-center flex-upgrade-package\" ng-class=\"getFlexPackageClass($index, $parent.responseData.packageChangeData.package_changes.length)\" ng-repeat=\"item in $parent.responseData.packageChangeData.package_changes track by $index\">\n" +
    "      <input class=\"radio-custom\" type=\"radio\" id=\"{{ item.toPackage.packageId }}\" name=\"selectRadioButton\" ng-model=\"packageSelection.item.packageChangeId\" value=\"{{ item.packageChangeId }}\" required />\n" +
    "      <label class=\"radio-custom-label\" for=\"{{item.toPackage.packageId}}\">\n" +
    "        <img src=\"img/programs/configuration/s-r-flex-logo.png\" alt=\"flex logo\" />\n" +
    "        <img ng-src=\"img/packages/configuration/{{item.toPackage.packageId}}.png\" alt=\"{{item.toPackage.description}}\" /><br/>\n" +
    "        <p ng-bind=\"item.toPackage.description\"></p>\n" +
    "      </label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"text-center\" ng-show = \"showPackageError\">\n" +
    "    <p>{{ 'DEVICE_FLEX_WARNING_ERROR_MESSAGE' | translate }}</p>\n" +
    "  </div>\n" +
    "  <div class=\"warning-box text-center\" ng-hide=\"true\">\n" +
    "    <h3>{{ 'DEVICE_FLEX_WARNING_TITLE' | translate }}</h3>\n" +
    "    <p>{{ 'DEVICE_FLEX_WARNING_TEXT' | translate }}</p>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"bottom\">\n" +
    "    <button class=\"big-btn btn-secondary\" type=\"button\" ng-click=\"toggleFlexSlider()\">{{ 'DEVICE_FLEX_CANCEL_BUTTON' | translate }} </button>\n" +
    "    <button class=\"big-btn btn-black\" ng-click=\"applyFlexUpgrade(packageSelection)\">{{ 'DEVICE_FLEX_SUBMIT_BUTTON' | translate }} </button>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"generic-device-box\" ng-class=\"flexSliderOpen ? 'dark-overlay' : ''\">\n" +
    "\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div ng-show=\"(rsuDevice && detectedDevices.length > 1) || !rsuDevice\" class=\"col-sm-4 col-xs-12 device-section border-right\">\n" +
    "      <div class=\"heading-container\">\n" +
    "        <h5>{{'DETECTION_MAIN_DEVICE_TITLE' | translate}}</h5>\n" +
    "      </div>\n" +
    "      <div class=\"image-container\">\n" +
    "        <img ng-src=\"{{device.mediumImage}}\" err-src=\"img/products/generic.png\" />\n" +
    "        <div class=\"product-name\">{{device.deviceFunction}}</div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"device-container\">\n" +
    "        <div class=\"device-name\">{{device.partName}}</div>\n" +
    "\n" +
    "        <div class=\"device-details\" ng-hide=\"device.partName === device.productName\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_PRODUCT' | translate }}</span>\n" +
    "          <span>{{ device.productName }}</span>\n" +
    "        </div>\n" +
    "        <div class=\"device-details\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_SERIAL' | translate }}</span>\n" +
    "          <span>{{ device.trackingM && device.trackingM + ' - '}}{{device.serial}}</span>\n" +
    "        </div>\n" +
    "        <div class=\"device-details\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_FIRMWARE' | translate }}</span>\n" +
    "          <span ng-bind=\"device.firmwareName\"></span>\n" +
    "          <span ng-show=\"!device.firmwareName\">{{ 'DETECTION_DEVICE_NO_FIRMWARE' | translate }}</span> \n" +
    "          <span ng-show=\"device.firmwareFullVersion\">v{{device.firmwareFullVersion}}</span>\n" +
    "        </div>\n" +
    "        <div class=\"device-details\" ng-show=\"(isClassicDevice && device.firmwareID > 0)\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_INSTALL_OPTION' | translate }}</span>\n" +
    "          <br />\n" +
    "          <span>{{ classicDeviceInstallOption | translate }}</span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"!hasFirmwareUpdates && !deviceHasBlankFirmware\">\n" +
    "          <div class=\"firmware-info device-details\" ng-show=\"isAntenna && !hasFlexPackageNoBill\">\n" +
    "            <span class=\"icon-check-circle\"></span>\n" +
    "            <span class=\"config-title\"> {{ 'DETECTION_FIRMWARE_UP_TO_DATE' | translate }}</span>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div>\n" +
    "          <span ng-show=\"hasFlexPackageNoBill\"><button class=\"full-width-btn bottom-margin\" type=\"button\" ng-click=\"toggleFlexSlider()\" >{{ deviceHasBlankFirmware?'DETECTION_FLEX_UPGRADE_BTN_NOBILL_NOFW':'DETECTION_FLEX_UPGRADE_BTN_NOBILL' | translate }}</button><br/></span>\n" +
    "        </div>\n" +
    "        <br/>\n" +
    "        <div class=\"flex-logo\" ng-if=\"latestRequest.package\">\n" +
    "          <img src=\"img/programs/flash-summary/f-flex-logo.png\" alt=\"flex logo\">\n" +
    "          <img class=\"package-name\" ng-src=\"img/packages/flash-summary/{{latestRequest.package.packageId}}.png\" alt=\"{{latestRequest.package.description}}\">\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "        <div class=\"button-container\">\n" +
    "          <div ng-show=\"hasKlonFw || hasSuspendedRequest\">\n" +
    "            <div class=\"logo-container\">\n" +
    "              <img src=\"img/klon/logo/klon-logo-black-70x16.png\" />\n" +
    "              <div class=\"patent-no\">{{ 'FLASH_PATENT_NO' | translate }}</div>\n" +
    "            </div>\n" +
    "            <button class=\"black-btn full-width-btn bottom-margin\" type=\"button\" ng-click=\"loadKlonProgressPartial()\" ng-hide=\"!hasKlonFw\">{{'DETECTION_START_KEYCLONING' | translate }}</button>\n" +
    "            <button class=\"black-btn full-width-btn bottom-margin\" type=\"button\" ng-click=\"loadKlonProgressPartial()\" ng-hide=\"!hasSuspendedRequest\">{{'DETECTION_GO_TO_KEYCLONING' | translate }}</button>\n" +
    "          </div>\n" +
    "          <button type=\"button\" class=\"black-btn full-width-btn bottom-margin\" ng-click=\"doFirmwareUpdate()\" ng-show=\"hasFirmwareUpdates && device.firmwareID !== '0'\">{{'DETECTION_FIRMWARE_UPDATE_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"black-btn full-width-btn bottom-margin\" ng-if=\"filterInternalOn\" ng-click=\"flashByFirmware()\">{{'FLASH_BY_FIRMWARE_BUTTON' | translate }}</button>\n" +
    "\n" +
    "          <button type=\"button\" class=\"black-btn full-width-btn bottom-margin\" ng-click=\"reFlashDevice()\" ng-if=\"(isAntenna || isDeviceAccessory) && !hasFlexPackageNoBill && device.firmwareID === '0' && device.latestId !== '0'\">{{'DETECTION_FLASH_DEVICE_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"black-btn full-width-btn bottom-margin\" ng-click=\"reFlashDevice()\" ng-if=\"(isAntenna || isDeviceAccessory) && !hasFlexPackageNoBill && device.firmwareID !== '0' && device.latestId !== '0' && !hasFirmwareUpdates\">{{'DETECTION_FLASH_AGAIN_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"configModuleOnly()\" ng-hide=\"!isAlreadyFlashed || !canModifyFeatures\">{{'DETECTION_CONFIGURE_FEATURES' | translate }}</button>\n" +
    "\n" +
    "          <div ng-show=\"canFlashByVehicle\">\n" +
    "            <button type=\"button\" class=\"full-width-btn\" ng-click=\"configureNewModule()\">{{'DETECTION_USER_START_CONFIG' | translate }}</button>\n" +
    "              <button type=\"button\" class=\"full-width-btn favorites-button\" ng-click=\"flashFromFavorites()\" ng-if=\"isFavoriteActive\">{{'FAVORITE_FLASH' | translate }}</button>\n" +
    "              <button type=\"button\" class=\"full-width-btn\" ng-click=\"configRemoteOnly()\" ng-hide=\"skipControls || !isIdatastartRemotePairingCompatible || rsuRemoteControl !== '' || !isAlreadyFlashed\">{{'DETECTION_CONFIGURE_REMOTES' | translate }}</button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"rsuDevice && detectedDevices.length === 1\" class=\"col-sm-4 col-xs-12 device-section border-right\">\n" +
    "      <div class=\"heading-container\">\n" +
    "        <h5>{{'DETECTION_MAIN_DEVICE_TITLE' | translate}}</h5>\n" +
    "      </div>\n" +
    "      <div class=\"image-container\">\n" +
    "        <img ng-src=\"{{ rsuDevice.Product.imageUrl }}\" err-src=\"img/products/generic.png\" />\n" +
    "        <div class=\"product-name\">{{ rsuDevice.Product.deviceFunction }}</div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"device-container\">\n" +
    "        <div class=\"device-name\">{{ rsuDevice.Product.rsuProductName }}</div>\n" +
    "\n" +
    "        <div class=\"device-details\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_FIRMWARE' | translate }}</span>\n" +
    "          <span ng-bind=\"rsuDevice.Firmware.rsuFirmwareName\"></span>\n" +
    "          <span ng-show=\"!rsuDevice.Firmware.rsuFirmwareName\">{{ 'DETECTION_DEVICE_NO_FIRMWARE' | translate }}</span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"button-container\">\n" +
    "          <button type=\"button\" class=\"black-btn full-width-btn\" ng-click=\"doFirmwareUpdate()\" ng-if=\"hasRsuFirmwareUpdate\">{{'DETECTION_FIRMWARE_UPDATE_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"retryUpdateBySerial(rsuDevice.serial)\" ng-if=\"!hasRsuFirmwareUpdate\">{{'DETECTION_FLASH_AGAIN_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"configRsuFeatures()\">{{'DETECTION_CONFIGURE_FEATURES' | translate }}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-8 col-xs-12\">\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-sm-6 col-xs-12 device-section border-right\">\n" +
    "          <div class=\"heading-container\">\n" +
    "            <h5 ng-class=\"!(lastVehicleFlashed && device.firmwareID > 0) ? 'empty-section' : ''\">{{'DETECTION_VEHICLE_TITLE' | translate}}</h5>\n" +
    "          </div>\n" +
    "          <div ng-show=\"!(lastVehicleFlashed && device.firmwareID > 0)\" class=\"not-available-text\">\n" +
    "            {{ 'DETECTION_DEVICE_NOT_AVAILABLE' | translate }}\n" +
    "          </div>\n" +
    "          <div ng-show=\"(lastVehicleFlashed && device.firmwareID > 0)\">\n" +
    "            <div class=\"image-container\">\n" +
    "              <img ng-src={{latestRequest.image}} err-src=\"img/generic/no-car-img.png\" alt=\"car image\" />\n" +
    "            </div>\n" +
    "            <div class=\"device-container\">\n" +
    "              <!-- <p class=\"config-title\">{{ 'DETECTION_DEVICE_CURRENT_CONFIGURATION' | translate }}</p> -->\n" +
    "              <div class=\"device-name\"> {{ lastVehicleFlashed.vehicleShortName }} </div>\n" +
    "              <div class=\"vehicle-details\"> {{ lastVehicleFlashed.vehicleProperties}} </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-sm-6 col-xs-12 device-section\">\n" +
    "          <div class=\"heading-container\">\n" +
    "            <h5 ng-class=\" controlsObject.array.length === 0 ? 'empty-section' : ''\">{{'DETECTION_CONTROLS_TITLE' | translate}}</h5>\n" +
    "            <div class=\"sub-title\" ng-class=\" controlsObject.array.length === 0 ? 'empty-section' : ''\">{{'DETECTION_REMOTES_TELEMATICS_TITLE' | translate}}</div>\n" +
    "          </div>\n" +
    "          <div class=\"not-available-text\" ng-show=\"hideControlsCard\">\n" +
    "            {{ 'DETECTION_DEVICE_NOT_AVAILABLE' | translate }}\n" +
    "          </div>\n" +
    "          <div class=\"device-container carousel\" ng-if=\"controlsObject.array.length > 1 && !hideControlsCard\">\n" +
    "            <ul rn-carousel rn-carousel-indicator=\"true\" rn-carousel-index=\"carouselIndex\" class=\"large-height\" >\n" +
    "            <li ng-repeat=\"controlItem in controlsObject.array\">\n" +
    "              <control-preview  control=\"controlItem\"/>\n" +
    "            </li>\n" +
    "            </ul>\n" +
    "            <div class=\"small-margin\" rn-carousel-indicators ng-if=\"controlsObject.array.length > 1\" slides=\"controlsObject.slides\" rn-carousel-index=\"carouselIndex\"></div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      \n" +
    "      <div>\n" +
    "        <div class=\"divider-min\"></div>\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-xs-12 col-sm-4 guides-section\">\n" +
    "            <h4 class=\"title-regular capitalize\" ng-class=\"!(device.guideDownloadUrl || device.productGuideDownloadUrl) ? 'empty-section' : ''\">Guides</h4>\n" +
    "            <div ng-if=\"device.guideDownloadUrl || device.productGuideDownloadUrl || device.productGuideDownloadUrl || rsuDevice.InstallGuide\">\n" +
    "              <a href=\"#\" ng-click=\"getGuidesSection()\">{{ 'GUIDES_ALL_GUIDES_SEE_ALL' | translate }}</a>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-xs-12 col-sm-4 guides-section\">\n" +
    "            <h4 class=\"title-regular capitalize\" ng-class=\"lastVehicleFlashed.vehicleId && device.firmwareID > 0 ? '' : 'empty-section'\">iDatawire</h4>\n" +
    "            <div ng-if=\"(lastVehicleFlashed.vehicleId && device.firmwareID > 0)\">\n" +
    "              <a href=\"#\" ng-click=\"getWiringSection()\">{{ 'DETECTION_WIRES_PICTURES_TITLE' | translate }}</a>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <!--<div class=\"col-xs-12 col-sm-4 guides-section\">\n" +
    "            <h4 class=\"empty-section title-regular capitalize\">Video Help</h4>\n" +
    "          </div>-->\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"divider-min\"></div>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div ng-show=\"!hasImbedded\" class=\"col-sm-4 col-xs-12 device-section border-right\">\n" +
    "      <div class=\"heading-container\">\n" +
    "        <h5 class=\"empty-section\">{{ 'DETECTION_DEVICE_EMBEDDED_DEVICE' | translate }}</h5>\n" +
    "      </div>\n" +
    "      <div class=\"not-available-text\">\n" +
    "        {{ 'DETECTION_DEVICE_NOT_AVAILABLE' | translate }}\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div ng-show=\"rsuDevice && detectedDevices.length > 1\" class=\"col-sm-4 col-xs-12 device-section\">\n" +
    "      <div class=\"heading-container\">\n" +
    "        <h5>{{ 'DETECTION_DEVICE_EMBEDDED_DEVICE' | translate }}</h5>\n" +
    "      </div>\n" +
    "      <div class=\"image-container\">\n" +
    "        <img ng-src=\"{{ rsuDevice.Product.imageUrl }}\"\n" +
    "          err-src=\"img/productparts/generic.png\" />\n" +
    "      </div>\n" +
    "      <div class=\"device-container\">\n" +
    "        <div class=\"product-name\">{{ rsuDevice.Product.deviceFunction }}</div>\n" +
    "        <div class=\"device-name\">{{ rsuDevice.Product.rsuProductName }}</div>\n" +
    "        \n" +
    "        <div class=\"device-details\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_FIRMWARE' | translate }}</span>\n" +
    "          <span ng-bind=\"rsuDevice.Firmware.rsuFirmwareName\"></span>\n" +
    "          <span ng-show=\"!rsuDevice.Firmware.rsuFirmwareName\">{{ 'DETECTION_DEVICE_NO_FIRMWARE' | translate }}</span>\n" +
    "        </div>\n" +
    "        <div class=\"button-container\">\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"doFirmwareUpdate()\" ng-if=\"hasRsuFirmwareUpdate\">{{'DETECTION_FIRMWARE_UPDATE_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"retryUpdateBySerial(rsuDevice.serial)\" ng-if=\"!hasRsuFirmwareUpdate\">{{'DETECTION_FLASH_AGAIN_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"configRsuFeatures()\">{{'DETECTION_CONFIGURE_FEATURES' | translate }}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div ng-show=\"!rsuDevice && detectedDevices.length\" ng-repeat=\"item in detectedDevices.slice(1, detectedDevices.length)\"  class=\"col-sm-4 col-xs-12 device-section\" ng-class=\"!$last ? 'border-right' : ''\">\n" +
    "      <div class=\"heading-container\">\n" +
    "        <h5>{{ 'DETECTION_DEVICE_EMBEDDED_DEVICE' | translate }}</h5>\n" +
    "      </div>\n" +
    "      <div class=\"image-container\">\n" +
    "        <img ng-src=\"{{item.partMediumImage}}\"\n" +
    "          err-src=\"img/productparts/generic.png\" />\n" +
    "      </div>\n" +
    "      <div class=\"device-container\">\n" +
    "        <div class=\"product-name\">{{item.deviceFunction}}</div>\n" +
    "        <div class=\"device-name\">{{item.partName}}</div>\n" +
    "        \n" +
    "        <div class=\"device-details\" ng-hide=\"item.partName === item.productName\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_PRODUCT' | translate }}</span>\n" +
    "          <span>{{ item.productName }}</span>\n" +
    "        </div>\n" +
    "        <div class=\"device-details\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_SERIAL' | translate }} </span>\n" +
    "          <span>{{ item.trackingM && item.trackingM + ' - ' }}{{ item.serial }}</span>\n" +
    "        </div>\n" +
    "        <div class=\"device-details\">\n" +
    "          <span class=\"config-title\">{{ 'DETECTION_DEVICE_FIRMWARE' | translate }}</span>\n" +
    "          <span ng-bind=\"item.firmwareName\"></span>\n" +
    "          <span ng-show=\"!item.firmwareName\">{{ 'DETECTION_DEVICE_NO_FIRMWARE' | translate }}</span>\n" +
    "          <span ng-show=\"item.firmwareVersion\">v{{item.firmwareVersion}}</span>\n" +
    "        </div>\n" +
    "        <div class=\"button-container\">\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"doFirmwareUpdate()\" ng-if=\"device.firmwareHasUpdate\">{{'DETECTION_FIRMWARE_UPDATE_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"reFlashDeviceBySerial(item.serial)\" ng-if=\"device.firmwareID !== '0' && item.firmwareID === item.latestId\">{{'DETECTION_FLASH_AGAIN_BTN' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"full-width-btn\" ng-click=\"configModuleOnly(item.serial)\" ng-hide=\"!isAlreadyFlashed || !canModifyFeaturesBySerial(item.serial)\">{{'DETECTION_CONFIGURE_FEATURES' | translate }}</button>\n" +
    "          <button type=\"button\" class=\"black-btn full-width-btn bottom-margin\" ng-if=\"filterInternalOn\" ng-click=\"flashByFirmwareEmbeddedDevice(item.serial)\">{{'FLASH_BY_FIRMWARE_BUTTON' | translate }}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/error.notification.bar.html',
    "<div class=\"error-notification-header row\">\n" +
    "    <div class=\"content-box row\">\n" +
    "        <div class=\"col-xs-12 col-sm-9\">\n" +
    "            <h5 class=\"error-header\">{{ title }}</h5>\n" +
    "            <p>{{ message }}</p>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-12 col-sm-4\">\n" +
    "            <button class=\"error-btn\" type=\"button\" ng-click=\"event()\">{{ button }}</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/externalRemotes.client.directive.view.html',
    "<section class=\"controls-partial remotes-container\">\n" +
    "    <div class=\"row text-center\">\n" +
    "      <div ng-repeat=\"dc in remotes\" class=\"col-xs-12 col-sm-6 col-xl-4\">\n" +
    "          <div class=\"remotes-box\">\n" +
    "            <div class=\"remote-img-box variable\">\n" +
    "                <img ng-src=\"{{dc.image}}\" err-src=\"img/remotes/generic.png\" />\n" +
    "            </div>\n" +
    "            <div class=\"description-container\">\n" +
    "                <div class=\"small-text black\">{{ brand.name }}</div>\n" +
    "                <div class=\"remotes-name\">{{dc.name}}</div>\n" +
    "                <div class=\"remotes-info\">{{dc.description}}</div>\n" +
    "            </div>\n" +
    "            <button class=\"round-btn\" ng-click=\"ctrl.selectExternalRemote()(dc, brand.id)\" ng-disabled=\"ctrl.isGlobalLoading\">\n" +
    "                <i class=\"fa fa-plus fa-2x\"></i>\n" +
    "            </button>\n" +
    "          </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/feature.options.radio.html',
    "<div class=\"input-radios\" id=\"option{{featureId}}\">\n" +
    "    <label class=\"features-label\" ng-repeat=\"option in options\" for=\"_radio{{featureId}}{{option.name}}\" ng-click=\"editoption()\"  >\n" +
    "        <input type=\"radio\" id=\"_radio{{featureId}}{{option.name}}\" name=\"{{ featureName }}\"\n" +
    "        value=\"{{option.name}}\" ng-if=\"option.isSelected == '1'\" checked  ng-disabled=\"{{ isLocked }}\" />\n" +
    "        <input type=\"radio\" id=\"_radio{{featureId}}{{option.name}}\" name=\"{{ featureName }}\"\n" +
    "         value=\"{{option.name}}\" ng-if=\"option.isSelected == '0'\"   ng-disabled=\"{{ isLocked }}\" />\n" +
    "        {{ option.name }}\n" +
    "    </label>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/directives-partial/feature.options.select.html',
    "<div>\n" +
    "    <ui-select ng-model=\"selectedOption.selectedValue\" ng-change=\"editOption()\" theme=\"select2\" search-enabled=\"false\" style=\"width:400px\" ng-disabled={{isLocked}}>\n" +
    "        <ui-select-match  style=\"background: white;color: black !important;\">{{$select.selected.value.name}}<span ng-if=\"selectedOption.selectedValue.isDefault\"> * </span></ui-select-match>\n" +
    "        <ui-select-choices repeat=\"selectedOption.value as (key, selectedOption) in options | filter: { value: { name: $select.search }}\">\n" +
    "            <div>\n" +
    "                <span ng-bind-html=\"selectedOption.value.name | highlight: $select.search\" ></span>\n" +
    "                <span ng-if=\"selectedOption.value.isDefault\"> * </span>\n" +
    "            </div>\n" +
    "        </ui-select-choices>\n" +
    "    </ui-select>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/feature.row.html',
    "<div class=\"container row-feature {{ rowStatus }}\" ng-if=\"feature.description.length > 0 \">\n" +
    "    <div class=\"col-xs-6 name-description-box\">\n" +
    "        <div class=\"features-name\">{{ feature.name }} </div>\n" +
    "        <more-less hmtext=\"{{ feature.description  }}\" hmlimit=\"80\" hm-more-text=\"read more\" hm-less-text=\"read less\">\n" +
    "        </more-less>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-5 button-option uiTest-featureid\" ng-when=\"feature.option\">\n" +
    "        <div ng-if=\"feature.selectionType == 'radio'\" class=\"option-item\" feature-options-radio=\"feature.option\"\n" +
    "            reverse=\"editfeature(feature.id)\" feature-id=\"{{ feature.id }}\" feature-name=\"{{ feature.name }}\"\n" +
    "            feature-locked=\"{{ feature.isLocked }}\"></div>\n" +
    "        <div ng-if=\"feature.selectionType == 'select'\" class=\"option-item\" feature-options-select=\"feature.option\"\n" +
    "            reverse=\"editfeature(feature.id)\" feature-id=\"{{ feature.id }}\"\n" +
    "            feature-description=\"{{ feature.description }}\" feature-locked=\"{{ feature.isLocked }}\"\n" +
    "            selected-option=\"selectedOption\"></div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 option-reset-locked col-reset\">\n" +
    "        <button ng-if=\"!isDefaultFeature\" class=\"reset-btn uiTest-ResetSmallbtn\" ng-click=\"resetfeature()\"><i\n" +
    "                class=\"fa fa-undo\"></i></button>\n" +
    "        <i class=\"fa fa-lock\" ng-if=\"feature.isLocked == '1'\"></i>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container row-feature no-description {{ rowStatus }}\" ng-if=\"feature.description.length == 0\">\n" +
    "    <div class=\"col-xs-6 name-description-box\">\n" +
    "        <div class=\"features-name\">{{ feature.name }} </div>\n" +
    "        <more-less ng-if=\"feature.description.length > 0 \" hmtext=\"{{ feature.description  }}\" hmlimit=\"80\"\n" +
    "            hm-more-text=\"read more\" hm-less-text=\"read less\"></more-less>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-5 button-option uiTest-featureid\" ng-when=\"feature.option\">\n" +
    "        <div ng-if=\"feature.selectionType == 'radio'\" class=\"option-item\" feature-options-radio=\"feature.option\"\n" +
    "            reverse=\"editfeature(feature.id)\" feature-id=\"{{ feature.id }}\" feature-name=\"{{ feature.name }}\"\n" +
    "            feature-locked=\"{{ feature.isLocked }}\"></div>\n" +
    "        <div ng-if=\"feature.selectionType == 'select'\" class=\"option-item\" feature-options-select=\"feature.option\"\n" +
    "            reverse=\"editfeature(feature.id)\" feature-id=\"{{ feature.id }}\"\n" +
    "            feature-description=\"{{ feature.description }}\" feature-locked=\"{{ feature.isLocked }}\"\n" +
    "            selected-option=\"selectedOption\" ]></div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 option-reset-locked col-reset\">\n" +
    "        <button ng-if=\"!isDefaultFeature\" class=\"reset-btn uiTest-ResetSmallbtn\" ng-click=\"resetfeature()\"><i\n" +
    "                class=\"fa fa-undo\"></i></button>\n" +
    "        <i class=\"fa fa-lock\" ng-if=\"feature.isLocked == '1'\"></i>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/firmware.posts.html',
    "<div class=\"firmware-posts\">\n" +
    "    <div class=\"media {{link_cssColor}}\" ng-repeat=\"p in posts\">\n" +
    "        <div class=\"media-left\" ng-switch=\"p.infoType\">\n" +
    "            <span ng-switch-when=\"disclaimer\" class=\"icon-disclaimer\"></span>\n" +
    "            <span ng-switch-when=\"installertip\" class=\"icon-installer-tips\"></span>\n" +
    "            <span ng-switch-when=\"supportlink\" class=\"icon-support-link\"></span>\n" +
    "            <span ng-switch-when=\"techbulletin\" class=\"icon-tech-bulletin\"></span>\n" +
    "            <span ng-switch-when=\"warning\" class=\"icon-warning\"></span>\n" +
    "            <span ng-switch-default class=\"icon-forum-post\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"media-body\">\n" +
    "            <div class=\"media-heading\">{{p.subject}}</div>\n" +
    "\n" +
    "            <more-less hmtext=\"{{p.content}}\" hmlimit=\"80\" hm-more-text=\"read more\" hm-less-text=\"read less\" icon=\"arrow\"></more-less>\n" +
    "\n" +
    "            <div class=\"link\" ng-hide=\"{{ p.link=='' }}\"><a href=\"{{p.link}}\" target=\"_blank\">{{p.link}}</a></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/firmware.posts.modal.html',
    "<div ng-repeat=\"p in posts\" class=\"description-box less-margin\">\n" +
    "  <div class=\"title\">{{p.subject}}</div>\n" +
    "  <div>{{p.content}}</div>\n" +
    "      <!-- <more-less hmtext=\"{{p.content}}\" hmlimit=\"100\" hm-more-text=\"read more\" hm-less-text=\"read less\" icon=\"arrow\"></more-less> -->\n" +
    "  <div class=\"link\">\n" +
    "    <a href=\"{{p.link}}\" target=\"_blank\" class=\"button small-btn black-btn\" ng-hide=\"{{ p.link=='' }}\">{{ 'MORE_BTN' | translate }}</a>\n" +
    "  </div>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/directives-partial/firmwareSlider.client.directive.view.html',
    "<div>\n" +
    "    <div class=\"modal-heading\">{{ 'DEVICE_BANNER_FIRMWARE_UPDATE_TITLE' | translate }}</div>\n" +
    "    \n" +
    "    <!-- Main device fw -->\n" +
    "    <div ng-show=\"ctrl.mainDeviceFirmwareInfo\">\n" +
    "        <div class=\"section-title\">\n" +
    "            <div ng-if=\"ctrl.devicesFirmwareInfo.latestFirmware !== null\">\n" +
    "                {{'DETECTION_MAIN_DEVICE_TITLE' | translate}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"firmware-container\">\n" +
    "            <div>\n" +
    "                <div class=\"firmware-title\">\n" +
    "                    {{ ctrl.mainDeviceFirmwareInfo.latestFirmware.name }}\n" +
    "                </div>\n" +
    "                <div class=\"firmware-sub-title\">\n" +
    "                    {{ ctrl.mainDeviceFirmwareInfo.latestFirmware.familyName }} <span ng-show=\"ctrl.mainDeviceFirmwareInfo.latestFirmware.firmwareFullVersion\">v{{ctrl.mainDeviceFirmwareInfo.latestFirmware.firmwareFullVersion}}</span>\n" +
    "                </div>\n" +
    "                <div>{{ ctrl.mainDeviceFirmwareInfo.latestFirmware.releaseNotes }}</div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"ctrl.devicesFirmwareInfo.latestFirmware !== null\" class=\"switch-container\">\n" +
    "                <div class=\"toggle-bg toggle-alternate\" ng-class=\"inputs[ctrl.mainDeviceFirmwareInfo.serial] ? 'on' : 'off'\">\n" +
    "                    <label ng-class=\"inputs[mainDeviceFirmwareInfo.serial] ? 'on' : 'off'\"></label>\n" +
    "                    <input type=\"radio\"  value=\"off\" ng-init=\"values[ctrl.mainDeviceFirmwareInfo.serial] = inputs[ctrl.mainDeviceFirmwareInfo.serial] ? 'on' : 'off'\" ng-model=\"values[ctrl.mainDeviceFirmwareInfo.serial]\" ng-click=\"toggleItem(ctrl.mainDeviceFirmwareInfo.serial)\">\n" +
    "                    <input type=\"radio\"  value=\"on\" ng-init=\"values[ctrl.mainDeviceFirmwareInfo.serial] = inputs[ctrl.mainDeviceFirmwareInfo.serial] ? 'on' : 'off'\" ng-model=\"values[ctrl.mainDeviceFirmwareInfo.serial]\" ng-click=\"toggleItem(ctrl.mainDeviceFirmwareInfo.serial)\">\n" +
    "                    <span class=\"switch\" ng-class=\"inputs[ctrl.mainDeviceFirmwareInfo.serial] ? 'on' : 'off'\"></span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <!-- Embedded devices fw -->\n" +
    "    <div ng-show=\"ctrl.embeddedDevicesFirmwareInfo && ctrl.embeddedDevicesFirmwareInfo.length\">\n" +
    "        <div class=\"section-title\">\n" +
    "            <div ng-if=\"ctrl.devicesFirmwareInfo.latestFirmware !== null\">\n" +
    "                {{'DETECTION_DEVICE_EMBEDDED_DEVICES' | translate}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"firmware-container\" ng-repeat=\"embbededDevice in ctrl.embeddedDevicesFirmwareInfo\">\n" +
    "            <div>\n" +
    "                <div class=\"firmware-title\">\n" +
    "                    {{ embbededDevice.latestFirmware.name }}\n" +
    "                </div>\n" +
    "                <div class=\"firmware-sub-title\">\n" +
    "                    {{ embbededDevice.latestFirmware.familyName }} <span ng-show=\"embbededDevice.latestFirmware.version\">v{{embbededDevice.latestFirmware.version}}</span>\n" +
    "                </div>\n" +
    "                <div>{{ embbededDevice.latestFirmware.releaseNotes }}</div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"embbededDevice.latestFirmware !== null\" class=\"switch-container\">\n" +
    "                <div class=\"toggle-bg toggle-alternate\" ng-class=\"inputs[embbededDevice.serial] ? 'on' : 'off'\">\n" +
    "                    <label ng-class=\"inputs[embbededDevice.serial] ? 'on' : 'off'\"></label>\n" +
    "                    <input type=\"radio\"  value=\"off\" ng-init=\"values[embbededDevice.serial] = inputs[embbededDevice.serial] ? 'on' : 'off'\" ng-model=\"values[embbededDevice.serial]\" ng-click=\"toggleItem(embbededDevice.serial)\">\n" +
    "                    <input type=\"radio\"  value=\"on\" ng-init=\"values[embbededDevice.serial] = inputs[embbededDevice.serial] ? 'on' : 'off'\" ng-model=\"values[embbededDevice.serial]\" ng-click=\"toggleItem(embbededDevice.serial)\">\n" +
    "                    <span class=\"switch\" ng-class=\"inputs[embbededDevice.serial] ? 'on' : 'off'\"></span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    \n" +
    "    <!--RSU device fw -->\n" +
    "    <div ng-show=\"rsuDevice && hasRsuFirmwareUpdate\">\n" +
    "        <div class=\"section-title\">\n" +
    "            {{ rsuDevice.Product.deviceFunction }}\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"firmware-container\">\n" +
    "            <div>\n" +
    "                <div class=\"firmware-title\">\n" +
    "                    {{ rsuDevice.Latest.name }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"rsuDevice.Latest && rsuDevice.Latest.id !== null\" class=\"switch-container\">\n" +
    "                <div class=\"toggle-bg toggle-alternate\" ng-class=\"inputs[rsuDevice.serial] ? 'on' : 'off'\">\n" +
    "                    <label ng-class=\"inputs[rsuDevice.serial] ? 'on' : 'off'\"></label>\n" +
    "                    <input type=\"radio\"  value=\"off\" ng-init=\"values[rsuDevice.serial] = inputs[rsuDevice.serial] ? 'on' : 'off'\" ng-model=\"values[rsuDevice.serial]\" ng-click=\"toggleItem(rsuDevice.serial)\">\n" +
    "                    <input type=\"radio\"  value=\"on\" ng-init=\"values[rsuDevice.serial] = inputs[rsuDevice.serial] ? 'on' : 'off'\" ng-model=\"values[rsuDevice.serial]\" ng-click=\"toggleItem(rsuDevice.serial)\">\n" +
    "                    <span class=\"switch\" ng-class=\"inputs[rsuDevice.serial] ? 'on' : 'off'\"></span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"button-container\">\n" +
    "        <button class=\"small-btn\" ng-click=\"confirm(inputs)\" ng-disabled=\"disabledBtn\">{{ 'DEVICE_BANNER_FIRMWARE_UPDATE_CONFIRM_BTN' | translate}}</button>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/image.info.html',
    "<div class=\"image-info\">\n" +
    "    <div class=\"component-details-container\" data-ng-if=\"data.componentName\">\n" +
    "        <div>{{ 'IMAGE_INFO_COMPONENT_NAME'  | translate}}</div>\n" +
    "        <div class=\"component-name\">{{data.componentName}}</div>\n" +
    "    </div>\n" +
    "    <ul class=\"component-list\">\n" +
    "        <li data-ng-repeat=\"t in data.imageTags.tags\">\n" +
    "            <div data-ng-if=\"t.tag_type === 'COMPONENT'\" class=\"image-info-container\" ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\">\n" +
    "                <div class=\"tags-container\"\n" +
    "                     ng-class=\"{activeClass: wire.component_id !== t.component_id && t.over === true, highLight: wire.component_id === t.component_id}\"\n" +
    "                >\n" +
    "                    <i class=\"fa fa-circle\" aria-hidden=\"true\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"wire-function-info\">\n" +
    "                      <span>\n" +
    "                       {{t.component}}\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "    <div class=\"connector-details-container\" data-ng-if=\"data.connectorName\" >\n" +
    "        <div>{{ 'IMAGE_INFO_CONNECTOR_NAME'  | translate }}</div>\n" +
    "        <div class=\"connector-name\" ng-hide=\"hideDuplicatedConnector(data.imageTags.tags,wire.connector_id)\">\n" +
    "            {{data.connectorName | connectorlocation }}\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <ul>\n" +
    "        <li data-ng-repeat=\"t in data.imageTags.tags\">\n" +
    "            <div data-ng-if=\"t.tag_type === 'CONNECTOR'\" class=\"image-info-container\" ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\">\n" +
    "                <div class=\"tags-container\" ng-class=\"{ activeClass: wire.connector_id !== t.connector_id && t.over === true, highLight: wire.connector_id === t.connector_id  }\">\n" +
    "                    <i class=\"fa fa-stop\" aria-hidden=\"true\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"wire-function-info\">\n" +
    "                      <span>\n" +
    "                          {{t | connectorlocation}}\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "    <div data-ng-show=\"data.wireFound\">\n" +
    "        {{ 'IMAGE_INFO_WIRES'  | translate }}\n" +
    "    </div>\n" +
    "    <ul>\n" +
    "        <li data-ng-repeat=\"t in data.imageTags.tags\">\n" +
    "            <div data-ng-if=\"t.tag_type === 'WIRE'\" class=\"image-info-container\" ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\">\n" +
    "                <div class=\"tags-container\"\n" +
    "                     ng-class=\"{activeClass: wire.wire_id !== t.wire_id && t.over === true, highLight: wire.wire_id === t.wire_id}\">\n" +
    "                   {{t.tag_name}}\n" +
    "                </div>\n" +
    "                <div class=\"wire-function-info wire\">\n" +
    "                    <span>\n" +
    "                       {{t.wire_function}}\n" +
    "                   </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/image.section.error.html',
    "<div class=\"image-section-error text-center col-md-6 col-lg-6\">\n" +
    "    <div class=\"row error-container\">\n" +
    "        <div>\n" +
    "            <img data-ng-src=\"img/wiring/image-section/generic.jpg\" alt=\"image-error\"/>\n" +
    "        </div>\n" +
    "        <div class=\"error-msg\">\n" +
    "            <h3>{{ 'GUIDES_WIRING_NO_IMAGE_SECTION' | translate }}</h3>\n" +
    "        </div>\n" +
    "        <div>\n" +
    "            {{ 'GUIDES_WIRING_NO_IMAGE_CONTACT_MSG' | translate }}<a href=\"http://www.idatalink.com/contact\" title=\"Idatalink Contact\">{{ 'GUIDES_WIRING_NO_IMAGE_CONTACT_URL_TITLE' | translate }}</a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/image.section.html',
    "<div class=\"image-section\" data-ng-show=\"data.showContent\">\n" +
    "    <div class=\"col-md-3 thumbnails\"     style=\" width: inherit;\">\n" +
    "        <a class=\"thumbnail\">\n" +
    "            <img data-ng-src=\"{{data.image_url}}\" alt=\"image-section\" />\n" +
    "        </a>\n" +
    "        <image-tag data-ng-show=\"data.showContent\" wire=\"wire\" tags=\"data.imageTags\"></image-tag>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/directives-partial/image.tag.html',
    "<div class=\"image-tag\" data-ng-repeat=\"t in tags.tags\">\n" +
    "    <div data-ng-if=\"t.tag_type === 'CONNECTOR'\" class=\"tags-container\"\n" +
    "         ng-class=\"{activeClass: wire.connector_id !== t.connector_id && t.over === true , highLight: wire.connector_id === t.connector_id}\"\n" +
    "         ng-style ='{\"left\":(t.x+15)+\"px\",\"top\":(t.y+10)+\"px\"}'\n" +
    "         ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\"\n" +
    "         uib-tooltip-html=\"'<div class=\\'tooltip-container\\'><div class=\\'tooltip-main-text\\'>{{t|connectorlocation}}</div></div>'\"\n" +
    "         tooltip-placement=\"left\">\n" +
    "        <i class=\"fa fa-stop\" aria-hidden=\"true\"></i>\n" +
    "    </div>\n" +
    "    <div data-ng-if=\"t.tag_type === 'COMPONENT'\"  class=\"tags-container\"\n" +
    "\n" +
    "         ng-class=\"{activeClass: wire.component_id !== t.component_id && t.over === true , highLight: wire.component_id === t.component_id}\"\n" +
    "         ng-style ='{\"left\":(t.x+15)+\"px\",\"top\":(t.y+10)+\"px\"}'\n" +
    "         ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\"\n" +
    "         uib-tooltip-html=\"(t.tag_type ==='COMPONENT' ) ? '<div class=tooltip-container><div class=tooltip-main-text>'+'&nbsp;'+t.component+'&nbsp;'+'</div></div>' : '' \"\n" +
    "         tooltip-placement=\"left\">\n" +
    "        <i class=\"fa fa-circle\" aria-hidden=\"true\"></i>\n" +
    "    </div>\n" +
    "    <div data-ng-if=\"t.tag_type === 'WIRE' \" class=\"tags-container\"\n" +
    "         ng-class=\"{activeClass: (wire.wire_id !== t.wire_id || wire.connector_id !== t.connector_id ) && t.over === true, highLight: wire.wire_id === t.wire_id}\"\n" +
    "\n" +
    "         ng-style ='{\"left\":(t.x+15)+\"px\",\"top\":(t.y+10)+\"px\"}'\n" +
    "         ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\"\n" +
    "         uib-tooltip-html=\"(t.tag_type ==='WIRE' ) ? '<div class=tooltip-container><div class=tooltip-main-text>'+t.wire_function+'</div>'+t.wire_color+'&bull;'+t.wire_polarity+'</div>' : '' \"\n" +
    "         tooltip-placement=\"left\">\n" +
    "        <span class=\"tag-element\">{{t.tag_name}}</span>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/image.thumbnail.html',
    "<div data-ng-if=\"images\" class=\"image-thumbnail-container\">\n" +
    "    <a ng-click=\"getImageSection(im,$index,wire)\" \n" +
    "        class=\"small-image-thumbnail thumbnail\" \n" +
    "        data-ng-repeat=\"im in images\" \n" +
    "        ng-class=\"data.activeTab == $index ? 'active-thumbnail': ''\"\n" +
    "    >\n" +
    "        <img data-ng-src=\"{{data.image_thumbnail_url}}{{im.thumbnail_name}}\" err-src=\"img/wiring/generic.png\"/>\n" +
    "    </a>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/images.gallery.html',
    "<div class=\"gallery-container\">\n" +
    "    <image-thumbnail  images=\"images\"></image-thumbnail>\n" +
    "    <image-section wire=\"wire\"></image-section>\n" +
    "    <image-section-error data-ng-hide =\"data.ErrorMessage\"></image-section-error>\n" +
    "    <images-gallery-info></images-gallery-info>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/directives-partial/images.gallery.info.html',
    "<div class=\"image-info\" data-ng-if=\"data.imageTags.tags\">\n" +
    "    <div data-ng-if=\"data.componentFound\">\n" +
    "        {{ 'IMAGE_INFO_COMPONENT_NAME'  | translate}}\n" +
    "    </div>\n" +
    "    <ul class=\"component-list\">\n" +
    "        <li data-ng-repeat=\"t in data.imageTags.tags\">\n" +
    "            <div data-ng-if=\"t.tag_type === 'COMPONENT'\" class=\"image-info-container\" ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\">\n" +
    "                <div class=\"tags-container\" ng-class='{ activeClass:t.over }'>\n" +
    "                    <i class=\"fa fa-circle\" aria-hidden=\"true\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"wire-function-info\">\n" +
    "                    <span>{{t.component}}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "    <div>\n" +
    "        <component-container data-ng-if=\"data.imageTags.tags[0].tag_type === 'CONNECTOR' || data.imageTags.tags[0].tag_type === 'WIRE'\" tags=\"data.imageTags.tags\"></component-container>\n" +
    "        <connector-container  data-ng-if=\"data.imageTags.tags[0].tag_type === 'WIRE'\" tags=\"data.imageTags.tags\"></connector-container>\n" +
    "    </div>\n" +
    "    <div data-ng-if=\"data.connectorFound  && data.imageTags.tags[0].tag_type !== 'WIRE' \">\n" +
    "        {{ 'IMAGE_INFO_CONNECTOR_NAME'  | translate }}\n" +
    "    </div>\n" +
    "    <ul>\n" +
    "        <li data-ng-repeat=\"t in data.imageTags.tags\">\n" +
    "            <div data-ng-if=\"t.tag_type === 'CONNECTOR'\" class=\"image-info-container\" ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\">\n" +
    "                <div class=\"tags-container\" ng-class='{ activeClass:t.over }'>\n" +
    "                    <i class=\"fa fa-stop\" aria-hidden=\"true\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"wire-function-info\">\n" +
    "                    <span>{{t | connectorlocation }}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "    <div data-ng-show=\"data.wireFound\">\n" +
    "        {{ 'IMAGE_INFO_WIRES'  | translate }}\n" +
    "    </div>\n" +
    "    <ul>\n" +
    "        <li data-ng-repeat=\"t in data.imageTags.tags\">\n" +
    "            <div data-ng-if=\"t.tag_type === 'WIRE'\" class=\"image-info-container wire-tag-name\" ng-mouseover=\"t.over=true\" ng-mouseleave=\"t.over=false\">\n" +
    "                <div class=\"tags-container\" ng-class='{ activeClass:t.over }'>\n" +
    "                    <span>{{t.tag_name}}</span>\n" +
    "                </div>\n" +
    "                <div class=\"wire-function-info\">\n" +
    "                   <span>\n" +
    "                       {{t.wire_function}}\n" +
    "                   </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/directives-partial/internalRemotes.client.directive.view.html',
    "<section class=\"remotes-container\">\n" +
    "  <div class=\"row\">\n" +
    "      <div class=\"col-xs-12 col-sm-8 col-sm-offset-2\">\n" +
    "        <p>{{ 'CONTROLS_SELECTION_INSTRUCTIONS' | translate }}</p>\n" +
    "        <p class=\"note\">{{ 'CONTROLS_SELECTION_INSTRUCTIONS_NOTE' | translate }}</p>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"text-center row\"> \n" +
    "    <div ng-repeat=\"dc in selectedRemotes\" class=\"col-xs-12 col-sm-6\">\n" +
    "      <div class=\"remotes-box\">\n" +
    "        <div class=\"remote-img-box\">\n" +
    "          <img ng-src=\"{{!dc.id ? 'img/remotes/genericGray.png' : dc.mediumImage}}\" err-src=\"img/remotes/generic.png\" />\n" +
    "        </div>\n" +
    "        <div class=\"bold-text blue-text\">{{ !dc.id ? 'CONTROLS_TITLE_ADDNEW' : 'CONTROLS_TITLE_INCLUDED' | translate }}</div>\n" +
    "        <div class=\"small-text\">{{ 'CONTROLS_TITLE_IDATASTART_REMOTE' | translate }}</div>\n" +
    "\n" +
    "        <div ng-show=\"{{dc.id}}\">\n" +
    "          <div class=\"description-container\">\n" +
    "            <div class=\"remotes-name \">{{dc.esn}} <img ng-if=\"dc.isProgrammedTo2ndCar\" class=\"second-car-warning\" ng-src=\"img/remotes/two_car.png\" uib-tooltip=\"{{'REMOTE_PROGRAMMED_2ND_CAR' | translate }}\" tooltip-placement=\"top\" /></div>\n" +
    "            <div class=\"part-number\">{{dc.partnumber}}</div>\n" +
    "            <div class=\"remotes-info\">{{dc.description}}</div>\n" +
    "          </div>\n" +
    "          <button class=\"round-btn error-btn\" ng-click=\"ctrl.removeSelectedRemote(dc)\" ng-disabled=\"isGlobalLoading\">\n" +
    "            <i class=\"fa fa-times fa-2x\"></i>\n" +
    "          </button>\n" +
    "        </div>\n" +
    "        <div ng-hide=\"{{dc.id}}\">\n" +
    "          <div class=\"description-container\">\n" +
    "            <div class=\"input-box\">\n" +
    "              <input type=\"text\" placeholder=\"{{ 'CONTROLS_SELECTION_ESN_ENTER' | translate }}\" ng-model=\"dc.esn\" ng-change=\"dc.esn = dc.esn.trim().split(' ').join('')\" maxlength=\"10\" ng-blur=\"ctrl.addRemote(dc)\" ng-class=\"dc.invalid?'invalid':''\" />\n" +
    "              <span class=\"remote-tooltip\" ng-click=\"ctrl.openDialog()\">\n" +
    "                <i class=\"fa fa-question-circle fa-lg\"></i>\n" +
    "              </span>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <button class=\"round-btn\" ng-disabled=\"isGlobalLoading\"><i class=\"fa fa-plus fa-2x\"></i></button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    \n" +
    "    <div class=\"button-container\">\n" +
    "      <button ng-disabled=\"ctrl.isGlobalLoading\" class=\"large-btn\" ng-click=\"ctrl.submit()\">{{ ctrl.saveBtnTitle | translate }}</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/loader.client.view.html',
    "<div class=\"fullscreen-loader\" ng-class=\"'ng-hide'\">\n" +
    "    <div class=\"loader-title\">{{ 'DETECTION_IN_PROGRESS' | translate}}</div>\n" +
    "    <div class=\"spinner\"><i class=\"fa fa-spinner fa-pulse fa-4x\"></i></div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/notification.bar.html',
    "<div class=\"notification-header\" ng-hide=\"hideNotificationBar\">\n" +
    "   <div class=\"content-box row\" >\n" +
    "       <div class=\"col-xs-12 col-sm-9\">\n" +
    "            <h5 class=\"white-header\">{{ title }}</h5>\n" +
    "            <p>{{ message }}</p>\n" +
    "       </div>\n" +
    "       <div class=\"col-xs-12 col-sm-4\">\n" +
    "           <button class=\"black-btn\" type=\"button\" ng-click=\"event()\">{{ button }}</button>\n" +
    "       </div>\n" +
    "   </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/oemRemoteMessageBox.html',
    "<div>\n" +
    "    <div class=\"modal-heading\">\n" +
    "        {{ 'OEM_REMOTE_MODAL_TITLE' | translate}}\n" +
    "    </div>\n" +
    "    <div class=\"description-box\">\n" +
    "        {{ 'OEM_REMOTE_MODAL_TEXT' | translate}}\n" +
    "    </div>\n" +
    "    <div class=\"button-container\">\n" +
    "        <button type=\"button\" class=\"large-btn\"\n" +
    "            ng-click=closeThisDialog()>{{ 'OEM_REMOTE_MODAL_BUTTON' | translate}}</button>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/page.navigation.html',
    "<div class=\"page-navigation\">\n" +
    "    <div class=\"navi-left\" ng-hide=\"{{hideBackBtn==undefined?true:hideBackBtn}}\" data-ng-click=\"skipstep(backSkipStepName)\"><i class=\"fa fa-angle-left fa-4x\"></i></div>\n" +
    "    <div class=\"navi-right\" ng-hide=\"hidenextbtn\" data-ng-click=\"skipstep(nextSkipStepName)\"><i class=\"fa fa-angle-right fa-4x\"></i></div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/remotes.client.directive.view.html',
    "<div class=\"controls-partial\">\n" +
    "  <div class=\"row text-center\">\n" +
    "      <div class=\"col-xs-12\">\n" +
    "          <div class=\"nav-ctn\">\n" +
    "              <page-navigation hidebackbtn=\"false\" hidenextbtn=\"!ctrl.useIDataStart\" backskipstep={{ctrl.backSkipStep}} nextskipstep={{ctrl.nextSkipStep}} ></page-navigation>\n" +
    "              <h1 class=\"main-header\">{{ 'CONTROLS_SELECTION_TITLE' | translate }}</h1>\n" +
    "          </div>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <!-- select box here -->\n" +
    "  <div class=\"text-center\">\n" +
    "    <div class=\"brand-select\">{{ 'CONTROLS_TITLE_SELECTBRAND' | translate }}</div>\n" +
    "    <div>\n" +
    "        <ui-select ng-change=\"ctrl.onChangeBrand(ctrl.selectedBrand)\"\n" +
    "          ng-model=\"ctrl.selectedBrand\"\n" +
    "          search-enabled=\"false\"\n" +
    "          style=\"width: 250px\"\n" +
    "          theme=\"select2\"\n" +
    "        >\n" +
    "            <ui-select-match placeholder=\"Select a remote brand\" >\n" +
    "                {{ctrl.selectedBrand.name}}\n" +
    "            </ui-select-match>\n" +
    "            <ui-select-choices repeat=\"brand in ctrl.remoteBrands\">\n" +
    "                <div ng-bind-html=\"brand.name | highlight: $select.search\"></div>\n" +
    "            </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <!-- internal Remote directive -->\n" +
    "  <div class=\"brand-select margin-top\">{{ 'CONTROLS_TITLE_SELECTREMOTE' | translate }}</div>\n" +
    "\n" +
    "  <internal-remotes\n" +
    "    ng-show=\"ctrl.useIDataStart\"\n" +
    "    response-data=\"ctrl.responseData\"\n" +
    "    request=\"ctrl.request\"\n" +
    "    user-selection=\"userSelection\"\n" +
    "    selected-remotes=\"ctrl.selectedRemotes\"\n" +
    "    load-telematics-partial=\"ctrl.loadTelematicsPartial\"\n" +
    "    >\n" +
    "  </internal-remotes>\n" +
    "\n" +
    "  <!-- external remote directive -->\n" +
    "  <external-remotes\n" +
    "    ng-show=\"!ctrl.useIDataStart\"\n" +
    "    select-external-remote=\"ctrl.selectExternalRemote\"\n" +
    "    brand=\"ctrl.brand\"\n" +
    "    user-selection=\"userSelection\"\n" +
    "    remotes=\"ctrl.remotes\"\n" +
    "  >\n" +
    "  </external-remotes>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/remoteshelp.client.directive.view.html',
    "<div class=\"remotes-help-dialog\">\n" +
    "  <div class=\"dialog-content\">\n" +
    "    <span class=\"dialog-leader\">{{ 'REMOTE_MODAL_TITLE' | translate }}</span>\n" +
    "    <span class=\"dialog-more\">{{ 'REMOTE_MODAL_SUBTITLE' | translate }}</span>\n" +
    "\n" +
    "    <div class=\"remotes-img\">\n" +
    "      <img class=\"\" src=\"img/remotes/REMOTE-ESN.jpg\" alt=\"esn\" />\n" +
    "      <span class=\"description\">{{ 'REMOTE_MODAL_ESN' | translate }}</span>\n" +
    "    </div>\n" +
    "    <div class=\"remotes-img\">\n" +
    "      <img class=\"\" src=\"img/remotes/REMOTE-SN.jpg\" alt=\"sn\" />\n" +
    "      <span class=\"description\">{{ 'REMOTE_MODAL_SN' | translate }}</span>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/rsuBrandsMessageBox.html',
    "<div>\n" +
    "    <div class=\"modal-heading\">{{ 'RSU_BRANDS_MODAL_TITLE' | translate}}</div>\n" +
    "    <div class=\"description-box\">{{ 'RSU_BRANDS_MODAL_TEXT' | translate}}</div>\n" +
    "    <div class=\"button-container\">\n" +
    "        <button type=\"button\" class=\"large-btn\" ng-click=redirectToVehicle()>{{ 'RSU_BRANDS_MODAL_BUTTON' | translate}}</button>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/directives-partial/sidebar.html',
    "<div ng-class=\"{disabled: disableHeader, 'toggle-right': !ctrl.toggle}\"\n" +
    "     class=\"sidebar-wrapper\" id=\"sidebar-wrapper\"\n" +
    "     ng-mouseenter=\"ctrl.toggleSidebarOnSmallScreen(true)\"\n" +
    "     ng-mouseleave=\"ctrl.toggleSidebarOnSmallScreen(false)\">\n" +
    "    <div class=\"logo-ctn-weblink\" ng-class=\"!ctrl.toggle ? 'small' : ''\"></div>\n" +
    "    <ul class=\"sidebar\">\n" +
    "        <li ng-if=\"ctrl.isWeblinkDesktop\" class=\"sidebar-category-container\"><div class=\"sidebar-category\">{{'SIDEBAR_CATEGORY_TOOLS' | translate}}</div></li>\n" +
    "        <li ng-if=\"ctrl.isWeblinkDesktop\" class=\"sidebar-connection-type\" ng-switch=\"ctrl.showFirmwareHubDownloadButton()\">\n" +
    "            <a ng-switch-when=\"true\">\n" +
    "                <div class=\"menu-sidebar\">\n" +
    "                    <img class=\"sidebar-connection-type-logo\" ng-src=\"{{ 'img/sidebar/connection-type/'+ctrl.getConnectionTypeIcon()+'.png' }}\" alt=\"tool logo\"/>\n" +
    "                </div>\n" +
    "                <span>{{ctrl.getConnectionTypeDisplayName() | translate}}</span>\n" +
    "                <div uib-tooltip=\"{{'HUB_FIRMWAREUPDATE_TOOLTIP' | translate }}\" tooltip-placement=\"auto\" tooltip-class=\"sidebar-reboot-tooltip\" class=\"sidebar-reboot-button\" ng-click=\"ctrl.rebootHub()\">\n" +
    "                    <i class=\"fa fa-download\" aria-label=\"Update Hub\" ></i>\n" +
    "                </div>\n" +
    "            </a>\n" +
    "            <a ng-switch-default>\n" +
    "                <div class=\"menu-sidebar\">\n" +
    "                    <img class=\"sidebar-connection-type-logo\" ng-src=\"{{ 'img/sidebar/connection-type/'+ctrl.getConnectionTypeIcon()+'.png' }}\" alt=\"tool logo\"/>\n" +
    "                </div>\n" +
    "                <span>{{ctrl.getConnectionTypeDisplayName() | translate}}</span>\n" +
    "            </a>\n" +
    "        </li>\n" +
    "        <li ng-if=\"ctrl.isWeblinkDesktop\" class=\"sidebar-divider\"></li>\n" +
    "        <li ng-if=\"ctrl.isWeblinkDesktop\" class=\"sidebar-category-container\"><div class=\"sidebar-category\">{{'SIDEBAR_CATEGORY_PROCESS' | translate}}</div></li>\n" +
    "        <li class=\"sidebar-list\">\n" +
    "            <a ng-init=\"(src='img/sidebar/hover/device.png')\"\n" +
    "               ui-sref=\"core.home\"\n" +
    "               ui-sref-opts=\"{ reload: true }\"\n" +
    "               ng-class=\"{ active: ctrl.sidebar.currentIndex === 0 }\"\n" +
    "            ><span class=\"menu-sidebar\"><img ng-src=\"{{ src }}\" alt=\"device logo\"/></span> {{ 'MENU_SIDEBAR_DEVICE' | translate}}</a>\n" +
    "        </li>\n" +
    "        <li ng-repeat=\"item in ctrl.sidebarTags\" class=\"sidebar-list\" ng-hide=\"item.hide\">\n" +
    "            <a ng-init=\"(src='img/sidebar/hover/'+item.name+'.png')\"\n" +
    "               ng-mouseover=\"(src='img/sidebar/hover/'+item.name+'.png')\"\n" +
    "               ng-class=\"ctrl.manageSidebar(item.index, item.disabled)\"\n" +
    "               ng-click=\"item.onClick()(item.param1, item.param2)\">\n" +
    "                <span class=\"menu-sidebar\">\n" +
    "                    <img ng-src=\"{{ ctrl.getSidebarImg(item.index, item.name, item.disabled)}}\" alt={{item.name}}>\n" +
    "                </span>\n" +
    "                {{ item.title | translate}}\n" +
    "            </a>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "    <div class=\"sidebar-footer\">\n" +
    "        ADS &copy {{ctrl.currentYear}} - v{{ ctrl.appVersion }}{{ ctrl.showUS()? ' (US)':'' }} <a href=\"#\" agreements>{{'TERMS_OF_USE_MESSAGE_MODAL_LINK' | translate }}</a>\n" +
    "        <div class=\"demo\" ng-show=\"ctrl.app.is_in_dev\">{{ ctrl.app.demo_mode? 'Demo-Mode':'' }}{{ ctrl.app.is_in_dev? ' DEV':'' }}</div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/sirenModalBox.html',
    "<div class=\"ngdialog-theme-plain siren-modal\">\n" +
    "    <div class=\"modal-heading\">\n" +
    "        {{ 'SIREN_MODAL_BOX_TITLE' | translate }}\n" +
    "    </div>\n" +
    "    <div class=\"modal-sub-heading form-group\">\n" +
    "        <input ng-class=\"ctrl.inputClass\" class=\"form-control\" ng-model=\"input\"\n" +
    "            ng-change=\"ctrl.validateSirenCode(input)\" type=\"text\"\n" +
    "            placeholder=\"{{ 'SIREN_MODAL_BOX_ENTERVALIDCODE' | translate }}\">\n" +
    "    </div>\n" +
    "    <div class=\"description-box\" ng-show=\"ctrl.showErrorMessage\">\n" +
    "        <div>{{ 'SIREN_MODAL_BOX_ERROR_MESSAGE_TEXT' | translate }}</div>\n" +
    "    </div>\n" +
    "    <div class=\"text-center button-container\">\n" +
    "        <button type=\"button\" class=\"small-btn\" ng-click=\"closeThisDialog('close');\" >{{ 'SIREN_MODAL_BOX_BUTTON_CANCEL' | translate}}</button>\n" +
    "        <button type=\"submit\" ng-disabled=\"ctrl.isCodeValid || ctrl.isSubmitting\" class=\"small-btn\" ng-click=\"ctrl.setActivationCode(input)\">{{ 'SIREN_MODAL_BOX_BUTTON' | translate }}</button>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/directives-partial/videos.section.html',
    "<div class=\"text-center\">\n" +
    "    <div class=\"image-thumbnail-container\">\n" +
    "        <div class=\"carousel video\" ng-show=\"videos && videos.length > 0\">\n" +
    "            <button ng-disabled=\"currentPage === 0\" ng-click=\"previousPage()\" class=\"navigation-button previous\"><i\n" +
    "                    class=\"fa fa-angle-left\" aria-hidden=\"true\"></i></button>\n" +
    "            <button ng-disabled=\"(currentPage + 1) === pagesRequired\" type=\"button\" ng-click=\"nextPage()\"\n" +
    "                class=\"navigation-button nextPage\"><i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i></button>\n" +
    "            <div class=\"carousel-inner\">\n" +
    "                <div class=\"item\" ng-repeat=\"item in itemsList track by $index\" ng-class=\"currentPage === $index ? 'active' : ''\">\n" +
    "                    <div ng-repeat=\"vi in videos.slice($index * perPage, ($index * perPage) + perPage)\">\n" +
    "                        <a title=\"{{vi.title}}\" \n" +
    "                            ng-click=\"playVideo($index + (currentPage * perPage))\"\n" +
    "                            class=\"img-container\" \n" +
    "                            ng-class=\"($index + (currentPage * perPage)) === playlistPosition ? 'active-video-tab' : ''\"\n" +
    "                        >\n" +
    "                            <img data-ng-src=\"{{ getThumbnail(vi.videoType, vi.videoThumbnail) }}\" alt=\"video guide\"\n" +
    "                                class=\"img-responsive\" err-src=\"img/guides/videos/generic.png\" />\n" +
    "                            <span class=\"glyphicon glyphicon-play-circle\"></span>\n" +
    "                        </a>\n" +
    "\n" +
    "                        <div class=\"video-title\">{{vi.title}}</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <!-- can switch to using videoContainer? -->\n" +
    "    <div class=\"video-container-responsive\">\n" +
    "        <iframe ng-src=\"{{ videos[playlistPosition].htmlVideoPlayer }}\"\n" +
    "            height=\"{{ videos[playlistPosition].videoHeight }}\"\n" +
    "            width=\"{{ videos[playlistPosition].videoWidth }}\" allowFullScreen=\"true\">\n" +
    "        </iframe>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/header.client.view.html',
    "<section id=\"HeaderController\" class=\"core__header\" data-ng-controller=\"HeaderController\">\n" +
    "    <div class=\"navbar-outlink\">\n" +
    "       <nav class=\"collapse navbar-collapse\">\n" +
    "            <ul class=\"nav navbar-nav navbar-left\" ng-hide=\"isInWeblinkDesktop\">\n" +
    "                <li>\n" +
    "                    <a href=\"{{ getSourceSiteUrl() }}\" title=\"{{ getSourceSiteName() }}\" target=\"_new\">\n" +
    "                        <span>{{ setSourceSite(globalUser && globalUser.profile ? globalUser.profile.srcsite : '') }}{{ getSourceSiteName() }}</span>\n" +
    "                    </a>\n" +
    "                </li>\n" +
    "                <li>\n" +
    "                    <a href=\"{{ getSourceSiteUrl() }}/weblink/index/no-redirect\" title=\"{{ getSourceSiteName() }}\">\n" +
    "                        <span>{{'HEADER_FLASH_CLASSIC' | translate}}</span>\n" +
    "                    </a>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "           <ul class=\"nav navbar-nav navbar-right\">\n" +
    "               <li ng-show=\"isInDev\" class=\"dev-indicator\"><p>DEV</p></li>\n" +
    "               <li class=\"dropdown-custom\" ng-class=\"globalUser && globalUser.profile ? '' : 'black'\">\n" +
    "                   <a class=\"\">\n" +
    "                       <span ng-show=\"globalUser && globalUser.profile\">{{ globalUser.profile.firstName + ' ' + globalUser.profile.lastName}}</span>\n" +
    "                       <span ng-show=\"!globalUser || !globalUser.profile\" ng-bind=\"'HEADER_TITLE_NO_USER' | translate\"></span>\n" +
    "\n" +
    "                   </a>\n" +
    "                   <ul class=\"dropdown-menu user\">\n" +
    "                       <li class=\"user-link\" ng-show=\"!globalUser\"><a ng-bind=\"'HEADER_LOGIN' | translate\" ng-click=\"goToLogin()\"></a></li>\n" +
    "                       <li class=\"user-link\" ng-show=\"globalUser\">\n" +
    "                           <a ng-show=\"!isInWeblinkDesktop\" ng-bind=\"'HEADER_LOGOUT' | translate\" ng-click=\"logout()\"></a>\n" +
    "                           <a ng-show=\"isInWeblinkDesktop\" ng-bind=\"'HEADER_LOGOUT' | translate\" ng-click=\"goToWeblinkDesktopHome()\"></a>\n" +
    "                        </li>\n" +
    "\n" +
    "                       <li><a ng-click=\"changeLanguage()\" ng-bind=\"getNextFormattedLanguage()\"></a></li>\n" +
    "                   </ul>\n" +
    "               </li>\n" +
    "            </ul>\n" +
    "        </nav>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/home.client.view.html',
    "<section id=\"HeaderController\" class=\"core__header\" data-ng-controller=\"HeaderController\">\n" +
    "    <div class=\"navbar-outlink\">\n" +
    "        <nav class=\"collapse navbar-collapse\">\n" +
    "            <ul class=\"nav navbar-nav navbar-left\" ng-hide=\"isInWeblinkDesktop\">\n" +
    "                <li>\n" +
    "                    <a href=\"{{ getSourceSiteUrl() }}\" title=\"{{ getSourceSiteName() }}\" target=\"_new\">\n" +
    "                        <span>{{ setSourceSite(globalUser && globalUser.profile ? globalUser.profile.srcsite : '') }}{{ getSourceSiteName() }}</span>\n" +
    "                    </a>\n" +
    "                </li>\n" +
    "                <li>\n" +
    "                    <a href=\"{{ getSourceSiteUrl() }}/weblink/index/no-redirect\" title=\"{{ getSourceSiteName() }}\">\n" +
    "                        <span>{{'HEADER_FLASH_CLASSIC' | translate}}</span>\n" +
    "                    </a>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "            <ul class=\"nav navbar-nav navbar-right\">\n" +
    "                <li ng-show=\"isInDev\" class=\"dev-indicator\"><p>DEV</p></li>\n" +
    "                <li class=\"dropdown-custom\" ng-class=\"globalUser && globalUser.profile ? '' : 'black'\">\n" +
    "                    <a class=\"\">\n" +
    "                        <span ng-show=\"globalUser && globalUser.profile\">{{ globalUser.profile.firstName + ' ' + globalUser.profile.lastName}}</span>\n" +
    "                        <span ng-show=\"!globalUser || !globalUser.profile\"\n" +
    "                              ng-bind=\"'HEADER_TITLE_NO_USER' | translate\"></span>\n" +
    "\n" +
    "                    </a>\n" +
    "                    <ul class=\"dropdown-menu user\">\n" +
    "                        <li class=\"user-link\" ng-show=\"!globalUser\"><a ng-bind=\"'HEADER_LOGIN' | translate\"\n" +
    "                                                                       ng-click=\"goToLogin()\"></a></li>\n" +
    "                        <li class=\"user-link\" ng-show=\"globalUser\">\n" +
    "                            <a ng-show=\"!isInWeblinkDesktop\" ng-bind=\"'HEADER_LOGOUT' | translate\" ng-click=\"logout()\"></a>\n" +
    "                            <a ng-show=\"isInWeblinkDesktop\" ng-bind=\"'HEADER_LOGOUT' | translate\" ng-click=\"goToWeblinkDesktopHome()\"></a>\n" +
    "                        </li>\n" +
    "\n" +
    "                        <li><a ng-click=\"changeLanguage()\" ng-bind=\"getNextFormattedLanguage()\"></a></li>\n" +
    "                    </ul>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </nav>\n" +
    "    </div>\n" +
    "\n" +
    "    <script type=\"text/ng-template\" id=\"networkErrorModal\">\n" +
    "        <div class=\"modal-heading warning\">{{ 'NETWORK_ERROR_TITLE' | translate }}</div>\n" +
    "        <div class=\"modal-sub-heading bottom-margin\">{{ 'NETWORK_ERROR_TXT' | translate }}</div>\n" +
    "    </script>\n" +
    "</section>\n" +
    "\n" +
    "\n" +
    "<section id=\"HomeController\" ng-init=\"initHome()\">\n" +
    "    <div class=\"fullscreen-loader\" ng-hide=\"!loading\">\n" +
    "        <div class=\"loader-title\">{{ 'DETECTION_IN_PROGRESS' | translate}}</div>\n" +
    "        <div class=\"spinner\"><i class=\"fa fa-spinner fa-pulse fa-4x\"></i></div>\n" +
    "    </div>\n" +
    "    <div class=\"fullscreen-loader detection-error\" ng-hide=\"!detectionError\">\n" +
    "        <div>\n" +
    "            <div class=\"error-icon\"><i class=\"fa fa-exclamation-triangle\"></i></div>\n" +
    "            <h1 class=\"main-header white-header\">{{ detectionErrorMsgHeader | translate}}</h1>\n" +
    "            <div ng-if=\"!detectionErrorUsbDetected\" class=\"sub-header no-text-transform white-text\">{{ detectionErrorMsg | translate}}</div>\n" +
    "            <div ng-if=\"detectionErrorUsbDetected\" class=\"sub-header no-text-transform white-text\">{{ detectionErrorMsg | translate:({ CONNECTION_TYPE : detectionErrorConnectionType })}}</div>\n" +
    "            <div class=\"button-container top-margin\">\n" +
    "                <button class=\"large-btn white-btn\" type=\"button\" ng-click=\"refreshPage()\">\n" +
    "                    {{ 'DETECTION_ERROR_PAGE_BUTTON_TRYAGAIN' | translate}}\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"fullscreen-loader detection-nonhcx\" ng-hide=\"!redirectIdatalink\">\n" +
    "        <div>\n" +
    "            <div class=\"error-icon\"><i class=\"fa fa-exclamation-triangle\"></i></div>\n" +
    "            <h1 class=\"main-header white-header\">{{ redirectTitle | translate}}</h1>\n" +
    "            <div class=\"sub-header no-text-transform white-text\">\n" +
    "                {{ redirectText | translate:({ 'WAIT_TIME':redirectTimeInSeconds }) }}</div>\n" +
    "\n" +
    "            <div>{{ detectedDevice.productName + ' | ' + detectedDevice.partName }}</div>\n" +
    "\n" +
    "            <div class=\"button-container top-margin\" ng-show=\"showCancelRedetectBtn\">\n" +
    "                <button class=\"large-btn white-btn\" type=\"button\" ng-click=\"refreshPage()\">\n" +
    "                    {{ redirectButtonTitle | translate}}\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"page-wrapper\" ng-class=\"{'open': toggle}\" ng-cloak ng-hide=\"loading\">\n" +
    "        <sidebar app=\"app\" disable-header=\"disableHeader\" load-internal-remotes-partial=\"loadInternalRemotesPartial\"\n" +
    "                 load-configuration-partial=\"loadConfigurationPartial\" load-controls-partial=\"loadControlsPartial\"\n" +
    "                 load-oem-remote-start-partial=\"loadOemRemoteStartPartial\" load-feature-partial=\"loadFeaturePartial\"\n" +
    "                 load-firmware-partial=\"loadFirmwarePartial\" load-flash-partial=\"loadFlashPartial\"\n" +
    "                 load-guides-partial=\"loadGuidesPartial\" load-telematics-partial=\"loadTelematicsPartial\"\n" +
    "                 load-install-options-partial=\"loadInstallOptionsPartial\" show-wiring-section=\"showWiringSection\"\n" +
    "                 sidebar=\"sidebar\" skip-controls=\"skipControls\" toggle=\"toggle\" used-as-add-on=\"usedAsAddOn\"\n" +
    "                 standalone-flags=\"standaloneFlags\">\n" +
    "        </sidebar>\n" +
    "        <!-- End Sidebar -->\n" +
    "\n" +
    "        <div class=\"content-wrapper\">\n" +
    "            <div class=\"page-content\">\n" +
    "                <div class=\"detected-device-bar\" ng-class=\"{disabled: disableHeader, 'enlarge-your-bar': toggle}\">\n" +
    "                    <div class=\"text-left device-info\">\n" +
    "                        <a class=\"refresh-link\" ng-click=\"refreshPage()\" uib-tooltip=\"{{'FLASH_REDETECT' | translate }}\"\n" +
    "                            tooltip-placement=\"right\"><i class=\"refresh-top-bar fa fa-refresh fa-2x\"></i></a>\n" +
    "                        <span ng-show=\"(rsuDevice && !isRusWithNoBlade) || !rsuDevice\">{{ detectedDevice.productName }} / SN: {{detectedDevice.trackingM && detectedDevice.trackingM + ' - '}}{{ detectedDevice.serial}}</span>\n" +
    "                        <span ng-show=\"isRusWithNoBlade\">{{ rsuDevice.Product.rsuProductName }}</span>\n" +
    "                        <span ng-show=\"!!(detectedDevice.firmwareName) && (selectedVehicle.makeId=='')\">&nbsp;/ Firmware:\n" +
    "                            {{detectedDevice.firmwareName}} v{{detectedDevice.firmwareVersion}}</span>\n" +
    "                        <span ng-hide=\"selectedVehicle.makeId==''\">&nbsp;/ {{ selectedVehicle.makeName }}</span>\n" +
    "                        <span ng-hide=\"selectedVehicle.yearName==''\">&nbsp;/ {{ selectedVehicle.yearName }}</span>\n" +
    "                        <span ng-hide=\"selectedVehicle.modelName==''\">&nbsp;/ {{ selectedVehicle.modelName }}</span>\n" +
    "                        <span ng-hide=\"selectedVehicle.vehicleName==''\">&nbsp;/ {{ selectedVehicle.vehicleName }}</span>\n" +
    "                        <span ng-hide=\"selectedVehicle.firmwareName === ''\">&nbsp;/ {{ selectedVehicle.firmwareName }}\n" +
    "                            v{{selectedVehicle.firmwareVersion}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"alert-bar cssFade uiTest-AlertBar\" ng-class=\"[alertBar.typeClass, alertBar.showFull, toggle]\" ng-show=\"alertBar.showBar\">\n" +
    "                    <div class=\"main-row\">\n" +
    "                        <div ng-hide=\"alertBar.hasUpdate\">\n" +
    "                            <p class=\"alert-bar-title\" ng-if=\"alertBar.hasTitle\">{{ alertBar.titleKey | translate}}</p>\n" +
    "                            <div ng-class=\"alertBar.textClass\" class=\"cell-text alert-bar-content\">\n" +
    "                                {{ alertBar.messageKey | translate: alertBar.messageParams }}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div ng-show=\"alertBar.hasUpdate\" class=\"row main-row text-left\">\n" +
    "                            <div ng-class=\"alertBar.textClass\" class=\"col-xs-8\">\n" +
    "                                {{ alertBar.messageKey | translate: alertBar.messageParams }}\n" +
    "                            </div>\n" +
    "                            <div class=\"col-xs-4\">\n" +
    "                                <button class=\"black-btn no-text-transform\" ng-click=\"goToWeblinkDesktop()\">Click here to Download</button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                </div>\n" +
    "                <!-- Main Content -->\n" +
    "                <div ui-view class=\"main-content\" ng-class=\"alertBar.showBar ? 'enlarge-alert' : ''\">\n" +
    "                    <div class=\"navb\" ng-hide=\"!submenu.show\">\n" +
    "                        <div class=\"\" ng-include=\"part\"></div>\n" +
    "                    </div>\n" +
    "                    <div class=\"slide-animate-container-{{submenu.show}}\">\n" +
    "                        <div class=\"global-loading\"\n" +
    "                            ng-class=\"{'navb-open': submenu.show , 'flash-in-progress': flashInProgress}\"\n" +
    "                            ng-show=\"isGlobalLoading && !keyreadingFlash\">\n" +
    "                            <i class=\"fa fa-spinner fa-pulse fa-4x\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"\" ng-include=\"partial\"></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "            </div><!-- End Page Content -->\n" +
    "        </div><!-- End Content Wrapper -->\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/login/home.client.view.html',
    "<section id=\"LoginHomeController\" class=\"full__height login-section row\">\n" +
    "  <div class=\"global-loading\"\n" +
    "       ng-show=\"isGlobalLoading\">\n" +
    "    <i class=\"fa fa-spinner fa-pulse fa-4x\"></i>\n" +
    "  </div>\n" +
    "    <div class=\"login-view col-xs-12\">\n" +
    "      <div class=\"login-container\">\n" +
    "\n" +
    "        <div ng-show=\"!isMaestro\">\n" +
    "          <div class=\"login-header\">\n" +
    "            <div class=\"img-box\">\n" +
    "              <img src=\"img/login/weblink-logo.png\"></img>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"alert alert-danger error-section\" ng-show=\"loginCtrl.facebookError\">\n" +
    "            <span>{{loginCtrl.facebookError}}</span>\n" +
    "          </div>\n" +
    "          <div class=\"alert alert-danger error-section\" ng-show=\"loginCtrl.serverError\">\n" +
    "            <span>{{'LOGIN_MSG_INVALID' | translate }}</span>\n" +
    "          </div>\n" +
    "          <div class=\"login-content\">\n" +
    "\n" +
    "            <form\n" +
    "              autocomplete=\"off\"\n" +
    "              name=\"loginForm\"\n" +
    "              novalidate\n" +
    "              sf-schema=\"loginCtrl.schema\"\n" +
    "              sf-form=\"loginCtrl.form\"\n" +
    "              sf-model=\"loginCtrl.model\"\n" +
    "              ng-submit=\"loginCtrl.submitForm(loginForm)\"\n" +
    "            >\n" +
    "            </form>\n" +
    "            <p class=\"login-links\">\n" +
    "              <a class=\"login-link\" href=\"http://www.idatalink.com/weblink/\">{{ 'LOGIN_FORGOT_PASSWORD' | translate }}</a>\n" +
    "              <a class=\"login-link\" href=\"#\" agreements>{{'TERMS_OF_USE_MESSAGE_MODAL_LINK' | translate }}</a>\n" +
    "            </p>\n" +
    "            <div class=\"button-container flex-btn-box\" ng-if=\"facebookEnable\">\n" +
    "              <button class=\"social facebook\" ng-click=\"fbLogin()\">\n" +
    "                <i class=\"fa fa-facebook\"></i>\n" +
    "                {{ 'LOGIN_FACEBOOK_BUTTON' | translate }}\n" +
    "              </button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/modals/bootloaderupdatedelay.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">{{ 'BOOTLOADER_UPGRADE_DELAY_TITLE' | translate }}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\" ng-if=\"redirectTimeInSeconds > 0\">{{ 'BOOTLOADER_UPGRADE_DELAY_COUNTDOWN' | translate:({ 'WAIT_TIME':redirectTimeInSeconds }) }}</p>\n" +
    "<p class=\"description-center\" ng-if=\"redirectTimeInSeconds > 0\">{{ 'BOOTLOADER_UPGRADE_MODAL_SECOND_TEXT' | translate }}</p>\n" +
    "<p class=\"description-center\" ng-if=\"!(redirectTimeInSeconds > 0)\">{{ 'FLASH_BOOTLOADER_CONFIRMATION_TEXT' | translate }}</p>\n" +
    "<div class=\"button-container\">\n" +
    "    <button class=\"large-btn col-md\" ng-click=\"confirm()\" ng-disabled=\"redirectTimeInSeconds > 0\">OK</button>\n" +
    "</div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/modals/featureConfigWarning.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <div class=\"modal-heading warning\">{{ 'FLASH_FIRMWAREPOSTS_TITLE' | translate }}</div>\n" +
    "    <div class=\"bottom-margin\">{{ 'FLASH_FIRMWAREPOSTS_DESC' | translate }}</div>\n" +
    "</div>\n" +
    "\n" +
    "<firmware-posts posts=\"posts\" theme=\"modal\"></firmware-posts>\n" +
    "<div class=\"button-container\">\n" +
    "    <button class=\"large-btn\" type=\"button\" ng-click=\"confirm()\" >{{ 'OEM_REMOTE_MODAL_BUTTON' | translate }}</button>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/modals/firmware.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">{{ 'FLASH_FIRMWAREUPDATE_ERROR_NOINFO_TITLE' | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{ 'FLASH_FIRMWAREUPDATE_ERROR_NOINFO_MESSAGE' | translate}}</p>\n" +
    "<div class=\"button-container\">\n" +
    "    <button class=\"large-btn col-md\" ng-click=\"confirm()\">{{ 'FLASH_BUTTON_START_FLASH_DEVICE' | translate}}</button>\n" +
    "</div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/modals/flash.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <div class=\"flex-logo\">\n" +
    "        <img class=\"flex\" src=\"img/programs/flash-summary/f-flex-logo.png\">\n" +
    "        <img class=\"config\" alt=\"2-way extended\" src=\"img/packages/configuration/6.png\">\n" +
    "    </div>\n" +
    "    <h2 class=\"text-center\">{{ 'FLASH_TRIAL_POPUP_TITLE' | translate}}</h2>\n" +
    "</div>\n" +
    "<div class=\"ngdialog-theme-plain\">\n" +
    "    <div class=\"row box-container\">\n" +
    "        <div class=\"col-xs-12\">\n" +
    "            <p>{{ 'FLASH_TRIAL_POPUP_P1' | translate }}</p>\n" +
    "            <div class=\"form-group row\">\n" +
    "                <div class=\"col-md-8 col-md-offset-2 no-margin\">\n" +
    "                    <input ng-class=\"inputClass\" class=\"form-control\" ng-model=\"emailInput\"  ng-change=\"validateEmailInput(emailInput)\" type=\"text\" placeholder=\"{{'FLASH_TRIAL_POPUP_INPUT_PLACEHOLDER' | translate}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <p>{{ 'FLASH_TRIAL_POPUP_P2' | translate }}</p>\n" +
    "            <p>{{ 'FLASH_TRIAL_POPUP_P3' | translate }}</p>\n" +
    "            <p>{{ 'FLASH_TRIAL_POPUP_P4' | translate }}</p>\n" +
    "            <!--checkbox-custom-label dark no-margin-top-->\n" +
    "            <div class=\"text-center\">\n" +
    "                <div>\n" +
    "                    <input id=\"checkbox1\" ng-model=\"checkbox\"  class=\"checkbox-custom\" name=\"checkbox-1\" type=\"checkbox\">\n" +
    "                    <label for=\"checkbox1\" ng-class=\"checkboxClass\" class=\"checkbox-custom-label dark no-margin-top\">{{ 'FLASH_TRIAL_POPUP_CHECKBOX_LABEL' | translate }}</label>\n" +
    "                </div>\n" +
    "\n" +
    "            </div>\n" +
    "            <div class=\"text-center ngdialog-buttons buttons-container\">\n" +
    "                <button type=\"button\" class=\"modal__btn cancel\" ng-click=closeThisDialog(\"Cancel\")>{{ 'FLASH_TRIAL_POPUP_CANCEL' | translate }}</button>\n" +
    "                <button type=\"button\" class=\"modal__btn confirm\" ng-click=submit(checkbox)>{{ 'FLASH_TRIAL_POPUP_ACCEPT' | translate }}</button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/modals/flashByFirmware.modal.client.view.html',
    "<div ng-controller=\"PartialsFlashByFirmwareController\" ng-init=\"initFlashByFirmware()\" class=\"flash-by-firmware\">\n" +
    "    <div class=\"ngdialog-header\">\n" +
    "        <h2 class=\"text-center\">{{ 'FLASH_BY_FIRMWARE_TITLE' | translate}}</h2>\n" +
    "        <p>{{ 'FLASH_BY_FIRMWARE_SUBTITLE' | translate}}</p>\n" +
    "    </div>\n" +
    "    <p class=\"description-center\">{{selectedProtocol.name && selectedProtocol.name + ' / '}}{{selectedFirmwareFamily.name && selectedFirmwareFamily.name + ' / '}} {{selectedFirmware.version && selectedFirmware.name + ' - ' +selectedFirmware.version }}</p>\n" +
    "\n" +
    "    <div class=\"firmware-selector\">\n" +
    "        <ul>\n" +
    "            <li ng-repeat=\"protocol in protocols\">\n" +
    "                <a href=\"#\" ng-click=\"selectProtocol(protocol.id)\">{{protocol.isDefault ? '*' : ''}}{{protocol.name}}</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "        <ul>\n" +
    "            <div class=\"modal-loading\" ng-show=\"loading && firmwareFamilies.length === 0 && protocols.length >= 0\">\n" +
    "                <i class=\"fa fa-spinner fa-pulse fa-4x\"></i>\n" +
    "            </div>\n" +
    "            <li ng-if=\"!loading && firmwareFamilies.length === 0 && selectedProtocol.id\">\n" +
    "                {{ 'FLASH_BY_FIRMWARE_NO_FW_FAMILY' | translate}}\n" +
    "            </li>\n" +
    "            <li ng-repeat=\"firmwareFamily in firmwareFamilies\">\n" +
    "                <a href=\"#\" ng-click=\"selectFirmwareFamily(firmwareFamily.id)\">{{firmwareFamily.name}}</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "        <ul>\n" +
    "            <div class=\"modal-loading\" ng-show=\"loading && firmwares.length === 0 && firmwareFamilies.length > 0\">\n" +
    "                <i class=\"fa fa-spinner fa-pulse fa-4x\"></i>\n" +
    "            </div>\n" +
    "            <li ng-if=\"!loading && firmwares.length === 0 && selectedFirmwareFamily.id\">\n" +
    "                {{ 'FLASH_BY_FIRMWARE_NO_FW' | translate}}\n" +
    "            </li>\n" +
    "            <li ng-repeat=\"firmware in firmwares\">\n" +
    "                <a href=\"#\" ng-click=\"selectFirmware(firmware.id)\">{{firmware.name}} - {{firmware.version}} - {{firmware.buildPublicationStatus}}</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "    <div class=\"button-container\">\n" +
    "        <button type=\"button\" class=\"modal__btn error-btn\" ng-click=closeThisDialog()>{{ 'KEYREADING_CANCEL_BTN' | translate }}</button>\n" +
    "        <button class=\"modal__btn\" ng-disabled=\"!firmwareSelectionConfirmed\" ng-click=\"confirm()\">{{ 'FLASH_BY_FIRMWARE_CONFIRM_FIRMWARE' | translate}}</button>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/modals/hubfirmware.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "  <h2 class=\"text-center\">{{ 'HUB_FIRMWAREUPDATE_TITLE' | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{ 'HUB_FIRMWAREUPDATE_MESSAGE' | translate}}</p>\n" +
    "<div class=\"button-container\">\n" +
    "  <button class=\"large-btn col-md\" ng-click=\"confirm()\">{{ 'HUB_FIRMWAREUPDATE_CONFIRM_BTN' | translate}}</button>\n" +
    "</div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/modals/hubrequired.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "  <h2 class=\"text-center\">\n" +
    "    <span ng-show=\"hubReq\">{{ 'TRIM_HUB_MODAL_TITLE_HUB' | translate }}</span>\n" +
    "    <span ng-show=\"hubSerialMissing && !hubReq\">{{ 'TRIM_HUB_MODAL_TITLE_SERIAL' | translate }}</span>\n" +
    "    <span ng-show=\"(hubReq || hubSerialMissing) && rfidReq\"> & </span>\n" +
    "    <span ng-show=\"rfidReq\">{{ 'TRIM_HUB_MODAL_TITLE_ACCESSORY' | translate }}</span>\n" +
    "    {{ 'TRIM_HUB_MODAL_TITLE_REQUIRED' | translate }}\n" +
    "  </h2>\n" +
    "</div>\n" +
    "<div class=\"description-left\">\n" +
    "  <p ng-show=\"hubReq\">{{ 'TRIM_HUB_MODAL_DESC_HUB' | translate }} <a href=\"https://www.weblinkupdater.com\" target=\"_blank\" tabindex=\"0\">{{ 'TRIM_HUB_MODAL_DESC_HUB_LINK' | translate }}</a></p>\n" +
    "  <p ng-show=\"hubSerialMissing && !hubReq\">{{ 'TRIM_HUB_MODAL_DESC_HUB_SERIAL' | translate }}</p>\n" +
    "  <p ng-show=\"rfidReq\">{{ 'TRIM_HUB_MODAL_DESC_ACC_LIST' | translate }}\n" +
    "    <ul>\n" +
    "      <li ng-repeat=\"item in requiredList\">\n" +
    "        {{ item.name }}\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "  </p>\n" +
    "  <p ng-show=\"rfidReq\">{{ 'TRIM_HUB_MODAL_DESC_ACC_FOOTER' | translate }}</p>\n" +
    "</div>\n" +
    "<div class=\"button-container\">\n" +
    "  <button class=\"large-btn col-md\" ng-click=\"confirm(doNotContinue)\">OK</button>\n" +
    "</div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/modals/hubreset.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "  <h2 class=\"text-center\">{{ 'HUB_RESET_TITLE' | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{ 'HUB_RESET_MESSAGE' | translate}}</p>\n" +
    "<div class=\"button-container\">\n" +
    "  <button class=\"large-btn col-md\" ng-click=\"confirm()\">{{ 'HUB_RESET_CONFIRM_BTN' | translate}}</button>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/modals/keyreading.modal.client.view.html',
    "<div class=\"keyreading-container\" ng-init=\"init()\">\n" +
    "  <div class=\"ngdialog-header keyreading-header\">\n" +
    "    <h2 class=\"text-center\">{{ 'KEYREADING_TITLE' | translate}}</h2>\n" +
    "    <p class=\"keyreading-sub-title\">{{ 'KEYREADING_MESSAGE' | translate}}</p>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"keyreading-content\">\n" +
    "    <div ng-repeat=\"step in $parent.klonSteps\"  ng-switch=\"step.canonicalName\" class=\"keyreading-content-element {{step.stepOrder - 0 ===  $parent.klonSteps.length ? 'last' : ''}}\" >\n" +
    "      <div class=\"keyreading-content-element-wrapper {{keyreadingError ? 'keyreading-error' : '' }}\" ng-switch-when=\"key-reading\">\n" +
    "        <p ng-if-start=\"keyreadingError\" class=\"bold\">{{ 'KEYREADING_STEP2_ERROR_TITLE' | translate}}</p>\n" +
    "        <p>{{ keyreadingErrorMessage | translate}}</p>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md inverted\" ng-click=\"startKeyReading()\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "          <button ng-if=\"showRedetectInError\" class=\"small-btn col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "        </div>\n" +
    "        <h3 ng-if-start=\"!keyreadingError\">{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "        <p>{{ 'KEYREADING_STEP1_MESSAGE' | translate}}</p>\n" +
    "        <div ng-if=\"!showWaitTime\">\n" +
    "          <p>{{ 'KEYREADING_STEP2_READ' | translate}}</p>\n" +
    "          <div class=\"keyreading-progress-border\">\n" +
    "            <div ng-style=\"keyreadingProgressBarGraphStyle\" class=\"keyreading-progress\"></div>\n" +
    "            <div class=\"keyreading-flash-percentage\" ng-style=\"keyreadingPercentageStyle\">{{keyreadingPercentage > 0 ? keyreadingPercentage : ''}}</div>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "        <div ng-if=\"showWaitTime\">\n" +
    "          <p>{{ 'KEYREADING_STEP2_COUNTDOWN' | translate}}</p>\n" +
    "          <h1>{{waitTime}}</h1>\n" +
    "        </div>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder -0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKeyReading()\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"keyreading-content-element-wrapper {{flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1 ? 'keyreading-error' : '' }}\" ng-switch-when=\"flash-main-module\">\n" +
    "        <p ng-if-start=\"flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"bold\">{{ 'FLASH_ERROR_FLASHING' | translate}}</p>\n" +
    "        <p>{{ flashErrorMessage | translate}}</p>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md inverted\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "          <button ng-if=\"showRedetectInError\" class=\"small-btn col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "        </div>\n" +
    "        <h3 ng-if-start=\"!(flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1)\">{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "\n" +
    "        <p>{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate}}</p>\n" +
    "        <div class=\"flash-progress-border\">\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"progressBarGraphStyle\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\">{{percentage > 0 ? percentage : ''}}</div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100</div>\n" +
    "\n" +
    "        </div>\n" +
    "        <div class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!accRFIDRequired && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"closeThisDialog()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"keyreading-content-element-wrapper {{flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1 ? 'keyreading-error' : '' }}\" ng-switch-when=\"flash-main-module-step1\">\n" +
    "        <p ng-if-start=\"flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"bold\">{{ 'FLASH_ERROR_FLASHING' | translate}}</p>\n" +
    "        <p>{{ flashErrorMessage | translate}}</p>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md inverted\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "          <button ng-if=\"showRedetectInError\" class=\"small-btn col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "        </div>\n" +
    "        <h3 ng-if-start=\"!(flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1)\">{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "\n" +
    "        <p>{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate}}</p>\n" +
    "        <div class=\"flash-progress-border\">\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"progressBarGraphStyle\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\">{{percentage > 0 ? percentage : ''}}</div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100</div>\n" +
    "        </div>\n" +
    "        <div class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!accRFIDRequired && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"closeThisDialog()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"keyreading-content-element-wrapper {{flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1 ? 'keyreading-error' : '' }}\" ng-switch-when=\"flash-main-module-step2\">\n" +
    "        <p ng-if-start=\"flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"bold\">{{ 'FLASH_ERROR_FLASHING' | translate}}</p>\n" +
    "        <p>{{ flashErrorMessage | translate}}</p>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md inverted\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "          <button ng-if=\"showRedetectInError\" class=\"small-btn col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "        </div>\n" +
    "        <h3 ng-if-start=\"!(flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1)\">{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "\n" +
    "        <p>{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate}}</p>\n" +
    "        <div class=\"flash-progress-border\">\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"progressBarGraphStyle\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\">{{percentage > 0 ? percentage : ''}}</div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100</div>\n" +
    "        </div>\n" +
    "        <div class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!accRFIDRequired && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"closeThisDialog()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"keyreading-content-element-wrapper {{showAccRfidError ? 'keyreading-error' : ''}}\" ng-switch-when=\"flash-acc-rfid\">\n" +
    "        <p ng-if-start=\"showAccRfidError\" class=\"bold\">{{ 'KEYREADING_RFID_ERROR_TITLE' | translate}}</p>\n" +
    "        <p>{{ 'KEYREADING_RFID_ERROR_MESSAGE' | translate}}</p>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startAccRfidFlash()\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "        </div>\n" +
    "        <h3 ng-if-start=\"!showAccRfidError\">{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "        <p>{{ 'KEYREADING_RFID_MESSAGE' | translate:({ DEVICE_NAME : mainDevice.productName }) }}</p>\n" +
    "        <p>{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate}}</p>\n" +
    "        <div class=\"flash-progress-border\">\n" +
    "          <div ng-style=\"rfidProgressBarGraphStyle\" class=\"flash-progress\"></div>\n" +
    "          <div class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageRfidStyle\">{{rfidPercentage > 0 ? rfidPercentage : ''}}</div>\n" +
    "        </div>\n" +
    "        <div class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-click=\"startAccRfidFlash()\" ng-disabled=\"started[step.stepOrder - 0] || $parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md\" ng-click=\"closeThisDialog()\" ng-if=\"accRfidFlashDone && step.stepOrder - 0 ===  $parent.klonSteps.length\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"keyreading-content-element-wrapper\" ng-switch-when=\"go-to-vehicle-read-readings\">\n" +
    "        <h3>{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "        <p>{{'FLASH_KLON_STEP2_INSTRUCTION_DETAIL_HEADER' | translate}}</p>\n" +
    "        <p>{{ 'FLASH_KLON_STEP2_INSTRUCTION_SUMMARY_1' | translate}}</p>\n" +
    "        <p>{{ 'FLASH_KLON_STEP2_INSTRUCTION_SUMMARY_2' | translate}}</p>\n" +
    "        <div class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"started[step.stepOrder - 0] || $parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"confirmVehicleReadings(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"keyreading-content-element-wrapper {{klonError ? 'keyreading-error' : ''}}\" ng-switch-when=\"plug-main-module\">\n" +
    "        <p ng-if-start=\"klonError\" class=\"bold\">{{ 'KLON_ERROR_TITLE' | translate}}</p>\n" +
    "        <p>{{ klonErrorMessage | translate}}</p>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <button class=\"small-btn col-md\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKlonReadings(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "        </div>\n" +
    "        <h3 ng-if-start=\"!klonError\">{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "        <p>{{'FLASH_KLON_STEP2_INSTRUCTION_DETAIL_ITEM5' | translate}}</p>\n" +
    "        <div class=\"flash-progress-border\">\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"klonProgressBarGraphStyle\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"klonProgressBarPercentageKeyreadingStyle\">{{klonPercentage > 0 ? klonPercentage : ''}}</div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"klonProgressBarPercentageKeyreadingStyle\"></div>\n" +
    "          <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100</div>\n" +
    "        </div>\n" +
    "        <div ng-if-end class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"started[step.stepOrder - 0] || $parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKlonReadings(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"keyreading-content-element-wrapper\" ng-switch-default>\n" +
    "        <h3>{{ 'KEYREADING_STEP_TITLE' | translate:({ 'STEP_COUNT': step.stepOrder + '/' + $parent.klonSteps.length })}}</h3>\n" +
    "        <p>{{ 'KEYREADING_STEP1_MESSAGE' | translate}}</p>\n" +
    "        <div class=\"button-container\">\n" +
    "          <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "          <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n" +
    "  <div class=\"button-container\">\n" +
    "    <button class=\"small-btn col-md error-btn\" ng-disabled=\"started[$parent.mostRecentCompletedStep+1]\" ng-click=\"closeThisDialog()\">{{ 'KEYREADING_CANCEL_BTN' | translate}}</button>\n" +
    "  </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/modals/keyreadingerror.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "  <h2 class=\"text-center\">{{ 'KEYREADING_ERROR_TITLE' | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{ 'KEYREADING_ERROR_MESSAGE' | translate}}</p>\n" +
    "<div class=\"button-container\">\n" +
    "  <button class=\"large-btn col-md\" ng-click=\"confirm()\">{{ 'KEYREADING_ERROR_CONFIRM_BTN' | translate}}</button>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/modals/vehiclefirmware.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">{{ 'DEVICE_FLEX_WARNING_TITLE' | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{ 'CONFIGURATION_TRIMS_ERROR_EMBEDDED' | translate:({ 'DEVICE_NAME':embeddedDeviceName }) }}</p>\n" +
    "<div class=\"text-center button-container\">\n" +
    "    <button type=\"button\" class=\"modal__btn cancel\" ng-click=closeThisDialog()>{{ 'KEYREADING_CANCEL_BTN' | translate }}</button>\n" +
    "    <button type=\"button\" class=\"modal__btn confirm\" ng-click=confirm()>{{ 'KEYREADING_CONTINUE_BTN' | translate }}</button>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/partials/configuration/directives/trimMessageBox.directive.view.html',
    "<div>\n" +
    "    <div class=\"modal-heading\">\n" +
    "        {{ title | translate}}\n" +
    "    </div>\n" +
    "    <div class=\"description-box\">\n" +
    "        {{ message | translate}}\n" +
    "    </div>\n" +
    "    <div class=\"button-container\">\n" +
    "        <button type=\"button\" class=\"large-btn\" ng-click=\"event()\">{{ buttonTitle | translate}}</button>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/partials/configuration/makes.client.view.html',
    "<section id=\"PartialsConfigurationMakesController\" data-ng-controller=\"PartialsConfigurationMakesController\">\n" +
    "    <div class=\"main-content-sidebar-offset\"  ng-init=\"initVehicleMakes()\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"{{backSkipStep}}\" nextskipstep=\"vehicle.years\" ></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'CONFIGURATION_MAKES_TITLE' | translate }}</h1>\n" +
    "        </div>\n" +
    "        <div ng-if=\"showMakesToggle\" class=\"toggle-makes\">\n" +
    "            <div class=\"toggle-makes-description\">\n" +
    "                <p>{{ 'CONFIGURATION_MAKES_TOGGLE' | translate}}</p>\n" +
    "                <div uib-tooltip=\"{{'CONFIGURATION_MAKES_TOGGLE_TOOLTIP' | translate }}\" tooltip-placement=\"right\" class=\"toggle-makes-tooltip-icon\">\n" +
    "                    <i class=\"fa fa-info-circle\" aria-label=\"toggle makes info tooltip\" ></i>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"switch-container\">\n" +
    "                <div class=\"toggle-bg toggle-alternate\" ng-class=\"configurationParameters.showAllMakes ? 'on' : 'off'\">\n" +
    "                    <input type=\"radio\" name=\"toggle\" ng-value=\"false\" ng-model=\"configurationParameters.showAllMakes\" ng-click=\"toggleVehicleMakes()\">\n" +
    "                    <input type=\"radio\" name=\"toggle\" ng-value=\"true\" ng-model=\"configurationParameters.showAllMakes\" ng-click=\"toggleVehicleMakes()\">\n" +
    "                    <span class=\"switch\" ng-class=\"configurationParameters.showAllMakes ? 'on' : 'off'\"></span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"config-list make-list\">\n" +
    "            <div ng-repeat=\"ma in $parent.responseData.vehicleMakes\" class=\"list-item\"\n" +
    "                 ng-init=\"(ma.imgsrc=($parent.selectedVehicle.makeId==ma.id?ma.imageHover:ma.image))\"\n" +
    "                 ng-mouseenter=\"(ma.imgsrc=ma.imageHover)\"\n" +
    "                 ng-mouseleave=\"(ma.imgsrc=($parent.selectedVehicle.makeId==ma.id?ma.imageHover:ma.image))\"\n" +
    "                 ng-class=\"{active: $parent.selectedVehicle.makeId==ma.id}\">\n" +
    "                <span ng-click=\"loadVehicleYearsPage( ma.id, ma.name, false )\" ng-if=\"(ma.id | num) !== otherMakeId\">\n" +
    "                    <img ng-src=\"{{ ma.imgsrc }}\" err-src=\"img/makes/generic.png\" />\n" +
    "                    <br/>{{ ma.name }}\n" +
    "                </span>\n" +
    "                <span ng-click=\"loadVehicleYearsPage( ma.id, ma.name, true )\" ng-if=\"(ma.id | num) === otherMakeId \">\n" +
    "                    <span class=\"icon-circle-exclamation\"></span>\n" +
    "                    <br/>{{ ma.name }}\n" +
    "                </span>\n" +
    "\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/configuration/models.client.view.html',
    "<section data-ng-controller=\"PartialsConfigurationModelsController\" ng-init=\"initVehicleModels()\">\n" +
    "\n" +
    "    <div class=\"main-content-sidebar-offset\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "        <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"vehicle.years\" nextskipstep=\"vehicle.trims\" ></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'CONFIGURATION_MODELS_TITLE' | translate:({ VEHICLE_MAKE:selectedVehicle.makeName, VEHICLE_YEAR:selectedVehicle.year }) }}</h1>\n" +
    "        </div>\n" +
    "        <div class=\"config-list model-list\">\n" +
    "            <div ng-repeat=\"mdl in $parent.responseData.vehicleModels\" class=\"list-item\"  ng-class=\"{active: $parent.selectedVehicle.modelId==mdl.id}\" ng-click=\"loadVehicleTrims( mdl.id ,mdl.name , mdl.image)\">\n" +
    "                <span ng-if=\"(mdl.id | num) !== otherModelId\" >\n" +
    "                    <img ng-src=\"{{mdl.image}}\" />\n" +
    "                    <br />{{ mdl.name }}\n" +
    "                </span>\n" +
    "                <span ng-if=\"(mdl.id | num) === otherModelId\">\n" +
    "                    <span class=\"icon-circle-exclamation\"></span>\n" +
    "                    <br/>{{ mdl.name }}\n" +
    "                </span>\n" +
    "\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/configuration/packages.client.view.html',
    "<section data-ng-controller=\"PartialsConfigurationPackagesController\" ng-init=\"initPackages()\">\n" +
    "    <div class=\"main-content-sidebar-offset\">\n" +
    "        <div class=\"col-xs-12\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"device\" nextskipstep=\"vehicle.makes\" ></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'CONFIGURATION_PACKAGE_TITLE' | translate }}</h1>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-offset-3 col-xs-6\">\n" +
    "            <p class=\"description-packages\">{{ 'CONFIGURATION_PACKAGE_INSTRUCTIONS' | translate }}</p>\n" +
    "        </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"package-container\">\n" +
    "        <div class=\"config-list package-list row text-center\">\n" +
    "            <div class=\"{{ $parent.responseData.packages.packageClassGrid === 1 ? 'col-xs-12 col-md-12' : 'col-xs-12 col-md-4'}}\" ng-repeat=\"pa in $parent.responseData.packages.packagesList\">\n" +
    "                <div class=\"box\">\n" +
    "                    <div class=\"radio\">\n" +
    "                        <label>\n" +
    "                            <img ng-src=\"img/programs/configuration/s-r-flex-logo.png\" alt=\"{{pa.name}}\"><br>\n" +
    "                            <img ng-src=\"img/packages/configuration/{{pa.id}}.png\" alt=\"{{pa.name}}\"><br>\n" +
    "                            {{pa.description}}<br>\n" +
    "                            <input type=\"radio\" ng-model=\"userSelection.packages.packageId\" value=\"{{pa.id}}\" ng-click=\"loadVehicleMakePage(pa.id)\" required/>\n" +
    "                            <span class=\"overlay\"></span>\n" +
    "                        </label>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"clearfix\"></div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/configuration/submenu.client.view.html',
    "<section data-ng-controller=\"PartialsConfigurationSubmenuController\">\n" +
    "    <div class =\"submenu-container\">\n" +
    "        <ul class=\"submenu\">\n" +
    "            <li ng-show=\"item !== $parent.showConfigSubMenuPackage\"\n" +
    "                class=\"list-item\" ng-repeat=\"item in $parent.submenu.items\"\n" +
    "                ng-click=\"loadPartial(item)\"\n" +
    "                ng-class=\"{active: $parent.submenu.selectedItem==item , disabled:$parent.submenu.selectedItem!==item && !manageSubMenu(item)} \"\n" +
    "            >\n" +
    "                <a>{{ 'CONFIGURATION_SUBMENU_' + item.toUpperCase() | translate }}</a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/configuration/trims.client.view.html',
    "<section data-ng-controller=\"PartialsConfigurationTrimsController\" ng-init=\"initVehicleTrims()\">\n" +
    "\n" +
    "    <div class=\"main-content-sidebar-offset\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"vehicle.models\" nextskipstep={{nextSkipStep}} ></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'CONFIGURATION_TRIMS_TITLE' | translate}}</h1>\n" +
    "        </div>\n" +
    "        <div class=\"generic-config-box vehicle-list\">\n" +
    "            <div ng-repeat=\"ma in $parent.responseData.vehicleTrims\" class=\"list-container\">\n" +
    "                <button class=\"list-item\" ng-disabled=\"$root.isGlobalLoading\" ng-click=\"setVehicle(ma.id , ma.vehicleProperties, ma.vehicleTransmissionHex)\" ng-class=\"{active: $parent.selectedVehicle.vehicleId==ma.id}\" ng-show=\"{{ ma.market == 'north_america' }}\">\n" +
    "                  <span>{{ ma.vehicleProperties }}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"$parent.responseData.vehicleFirmwareFamilies.length > 1\" class=\"generic-config-box\">\n" +
    "            <div class=\"list-title\">{{ 'CONFIGURATION_TRIMS_CHOOSE_FAMILY' | translate }}</div>\n" +
    "            <div ng-repeat=\"pr in $parent.responseData.vehicleFirmwareFamilies\" class=\"list-container\">\n" +
    "                <button class=\"list-item large-ext\" ng-click=\"setFirmwareFamilyUser(pr.id, pr.name)\" ng-class=\"{active:$parent.responseData.vehicleFirmwareFamilies.id === pr.id}\" ng-disabled=\"$root.isGlobalLoading\">\n" +
    "                    <span class=\"title\">{{ pr.name }}</span>\n" +
    "                    <span class=\"small-text\">{{ pr.description === '' ? pr.projectFunction : pr.description }}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <script type=\"text/ng-template\" id=\"warningModal\">\n" +
    "      <div class=\"modal-heading warning\">{{ 'TRIM_MESSAGE_MODAL_MANUAL_TITLE' | translate}}</div>\n" +
    "      <div class=\"modal-sub-heading\">{{ 'TRIM_MESSAGE_MODAL_MANUAL_DESC' | translate}}</div>\n" +
    "\n" +
    "      <div class=\"description-box\">{{ 'TRIM_MESSAGE_MODAL_MANUAL_TXT' | translate}}</div>\n" +
    "\n" +
    "      <div class=\"button-container\">\n" +
    "        <button class=\"uiTest-flash-device large-btn\" type=\"button\" ng-click=\"closeThisDialog('agree');\" >{{ 'TRIM_MESSAGE_MODAL_MANUAL_BUTTON' | translate}}</button>\n" +
    "      </div>\n" +
    "    </script>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/configuration/years.client.view.html',
    "<section data-ng-controller=\"PartialsConfigurationYearsController\" ng-init=\"initVehicleYears()\">\n" +
    "\n" +
    "    <div class=\"main-content-sidebar-offset\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"vehicle.makes\" nextskipstep=\"vehicle.models\" ></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'CONFIGURATION_YEARS_TITLE' | translate:({ VEHICLE_MAKE: selectedVehicle.makeName }) }}</h1>\n" +
    "        </div>\n" +
    "        <br>\n" +
    "        <div class=\"config-list\">\n" +
    "            <div ng-repeat=\"yr in $parent.responseData.vehicleYears\" class=\"list-item\"  uiTest-year-id=\"{{yr.id}}\" ng-class=\"{active: $parent.selectedVehicle.year==yr.id}\">\n" +
    "                <span class=\"year-list\" ng-if=\"(yr.id | num) !== otherYearId\" ng-click=\"loadVehicleModelsPage( yr.id , yr.name, false)\">{{ yr.name }}</span>\n" +
    "                <div ng-if=\"(yr.id | num) === otherYearId\" ng-click=\"loadVehicleModelsPage( yr.id , yr.name, true )\" >\n" +
    "                    <span class=\"icon-circle-exclamation\"></span>\n" +
    "                    <div>{{ yr.name }} </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/controls/controls.client.view.html',
    "<section data-ng-controller=\"PartialsControlsControlsController\"  ng-init=\"initControls()\" >\n" +
    "    <div class=\"controls-partial\">\n" +
    "        <div class=\"row text-center\">\n" +
    "            <div class=\"col-xs-12\">\n" +
    "                <div class=\"nav-ctn\">\n" +
    "                    <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"device\" nextskipstep=\"features-save-remotes\"></page-navigation>\n" +
    "                    <h1 class=\"main-header\">{{ 'CONTROLS_SELECTION_TITLE' | translate }}</h1>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"packages-list\" data-ng-show=\"{{ !editControlsOnly  && $parent.showPackageMode }}\">\n" +
    "            <div class=\"row text-center\">\n" +
    "                <p>{{ 'CONTROLS_PACKAGE_DESCRIPTION' | translate }}</p>\n" +
    "                <div class=\"list-item {{ $parent.responseData.packages.packageClassGrid === 1 ? 'col-xs-12 col-md-12' : 'col-xs-12 col-md-4'}}\" ng-repeat=\"pa in $parent.responseData.packages.packagesList\" >\n" +
    "                    <div class=\"box\">\n" +
    "                        <div class=\"radio\">\n" +
    "                            <label>\n" +
    "                                <img ng-src=\"img/programs/configuration/s-r-flex-logo.png\" alt=\"{{pa.name}}\"><br>\n" +
    "                                <img ng-src=\"img/packages/configuration/{{pa.id}}.png\" alt=\"{{pa.name}}\"><br/>\n" +
    "                                <input type=\"radio\" ng-model=\"userSelection.packages.packageId\" value=\"{{pa.id}}\" ng-click=\"modifySelectedPackage(pa.id)\" required/>\n" +
    "                                <span class=\"overlay\"></span>\n" +
    "                            </label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\"  ng-show=\"hasOemRemoteConfig\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <div class=\"oem-remote-section\">\n" +
    "                    <img src=\"img/remotes/remoteOEM.png\" alt=\"OEM Remote\" />\n" +
    "                    <div class=\"bold-text capitalize\">\n" +
    "                        {{ 'CONTROLS_REMOTE_OEM_NAME' | translate }}\n" +
    "                    </div>\n" +
    "                    <ui-select ng-model=\"userSelection.oemRemote\" theme=\"select2\" search-enabled=\"false\" style=\"width:260px\">\n" +
    "                        <ui-select-match>\n" +
    "                            {{userSelection.oemRemote.name}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices repeat=\"op in responseData.deviceOemRemoteConfig.option\">\n" +
    "                            <div ng-bind-html=\"op.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"remotes-container\">\n" +
    "            <div class=\"row text-center\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <div class=\"brand-select margin-top\">{{ 'CONTROLS_TITLE_SELECTREMOTE' | translate }}</div>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-12 col-sm-8 col-sm-offset-2\">\n" +
    "                    <p>{{ 'CONTROLS_SELECTION_INSTRUCTIONS' | translate }}</p>\n" +
    "                    <p class=\"note\">{{ 'CONTROLS_SELECTION_INSTRUCTIONS_NOTE' | translate }}</p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"text-center row uiTestlist\">\n" +
    "                <div ng-repeat=\"dc in reservedRemotes\" class=\"list-item col-xs-12 col-sm-6\">\n" +
    "                    <div class=\"remotes-box\">\n" +
    "                        <div class=\"remote-img-box\">\n" +
    "                            <img ng-src=\"{{!dc.id?'img/remotes/genericGray.png':dc.mediumImage}}\" err-src=\"img/remotes/generic.png\" />\n" +
    "                        </div>\n" +
    "                        <div class=\"bold-text blue-text\">{{ !dc.id?'CONTROLS_TITLE_ADDNEW':'CONTROLS_TITLE_INCLUDED' | translate }}</div>\n" +
    "                        <div class=\"small-text\">{{ 'CONTROLS_TITLE_IDATASTART_REMOTE' | translate }}</div>\n" +
    "                        <div ng-show=\"{{dc.id}}\">\n" +
    "                            <div class=\"description-container\" >\n" +
    "                                <div class=\"remotes-name\">{{dc.esn}} <img ng-if=\"dc.isProgrammedTo2ndCar\" class=\"second-car-warning\" ng-src=\"img/remotes/two_car.png\" uib-tooltip=\"{{'REMOTE_PROGRAMMED_2ND_CAR' | translate }}\" tooltip-placement=\"top\" /></div>\n" +
    "                                <div class=\"part-number \">{{dc.partnumber}}</div>\n" +
    "                                <div class=\"remotes-info\">{{dc.description}}</div>\n" +
    "                            </div>\n" +
    "                            <button class=\"round-btn error-btn\" ng-click=\"removeSelectedRemote(dc)\" ng-disabled=\"isGlobalLoading\">\n" +
    "                                <i class=\"fa fa-times fa-2x\"></i>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                        <div ng-hide=\"{{dc.id}}\">\n" +
    "                            <div class=\"description-container\">\n" +
    "                                <div class=\"input-box\">\n" +
    "                                    <input type=\"text\" placeholder=\"{{ 'CONTROLS_SELECTION_ESN_ENTER' | translate }}\" ng-model=\"dc.esn\" ng-change=\"dc.esn = dc.esn.trim().split(' ').join('')\" maxlength=\"10\" ng-blur=\"addReservedRemote(dc)\" ng-class=\"dc.invalid?'invalid':''\" />\n" +
    "                                    <span class=\"remote-tooltip\" ng-click=\"openDialog()\">\n" +
    "                                        <i class=\"fa fa-question-circle fa-lg\"></i>\n" +
    "                                    </span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <button class=\"round-btn\" ng-disabled=\"isGlobalLoading\" uib-tooltip=\"{{'CONTROLS_SELECTION_ESN_ADD' | translate }}\" tooltip-placement=\"right\"><i class=\"fa fa-plus fa-2x\"></i></button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"button-container\">\n" +
    "                    <button ng-disabled=\"isGlobalLoading || remoteEepromError\" class=\"large-btn\" ng-click=\"editControlsOnly_saveRemotes()\">{{ 'CONTROLS_BUTTON_SAVE_REMOTESONLY' | translate }}</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/device/device.client.view.html',
    "<section data-ng-controller=\"PartialsDeviceDeviceController\" class=\"device-controller\">\n" +
    "    <div class=\"device-page\">\n" +
    "        <detected-device\n" +
    "            detected-devices = detectedDevices\n" +
    "            retry-update-by-serial=\"retryUpdateBySerial\"\n" +
    "            rsu-device=\"rsuDevice\"\n" +
    "            has-imbedded=\"detectedDevices.length > 1\"\n" +
    "            device=\"detectedDevices[0]\"\n" +
    "            embedded-device =\"detectedDevices.length > 1 ? detectedDevices[1] : ''\"\n" +
    "            latest-request=\"$parent.latestRequest\"\n" +
    "            last-vehicle-flashed=\"$parent.lastVehicleFlashed\"\n" +
    "            rsu-remote-control=\"$parent.rsuRemoteControl\"\n" +
    "            is-antenna=\"$parent.isAntenna\"\n" +
    "            is-device-accessory=\"$parent.isDeviceAccessory\"\n" +
    "            is-device-rfid=\"$parent.isDeviceRfid\"\n" +
    "            can-flash-by-vehicle=\"$parent.canFlashByVehicle\"\n" +
    "            is-already-flashed=\"isAlreadyFlashed\"\n" +
    "            skip-controls=\"$parent.skipControls\"\n" +
    "            is-idatastart-remote-pairing-compatible=\"$parent.isIdatastartRemotePairingCompatible\"\n" +
    "            has-klon-fw=\"$parent.hasKlonFw\"\n" +
    "            has-suspended-request=\"$parent.hasSuspendedRequest\"\n" +
    "            has-firmware-updates=\"$parent.hasFirmwareUpdates\"\n" +
    "            has-rsu-firmware-update=\"$parent.hasRsuFirmwareUpdate\"\n" +
    "            flash-from-favorites=\"flashFromFavorites()\"\n" +
    "            configure-new-module=\"ConfigureNewModule()\"\n" +
    "            config-module-only=\"ConfigModuleOnly\"\n" +
    "            config-remote-only=\"ConfigRemoteOnly()\"\n" +
    "            config-rsu-features=\"configRsuFeatures()\"\n" +
    "            load-klon-progress-partial=\"loadKlonProgressPartial()\"\n" +
    "            do-firmware-update=\"doFirmwareUpdate\"\n" +
    "            has-flex-package-no-bill=\"hasFlexPackageNoBill\"\n" +
    "            device-has-blank-firmware=\"deviceHasBlankFirmware\"\n" +
    "            telematic=\"telematic\"\n" +
    "            get-wiring-section=\"getWiringSection()\"\n" +
    "            get-guides-section=\"getGuidesSection()\"\n" +
    "            controls-object=\"controlsObject\"\n" +
    "            toggle-flex-slider=\"toggleFlexSlider()\"\n" +
    "            flex-slider-open=\"flexSliderOpen\"\n" +
    "            apply-flex-upgrade=\"applyFlexUpgrade()\"\n" +
    "            package-selection=\"packageSelection\"\n" +
    "            hide-controls-card=\"hideControlsCard\"\n" +
    "            is-classic-device=\"isClassicDevice\"\n" +
    "            is-favorite-active=\"isFavoriteActive\"\n" +
    "            classic-device-install-option=\"classicDeviceInstallOption\"\n" +
    "            can-modify-features=\"canModifyFeatures\"\n" +
    "            re-flash-device=\"reFlashDevice\"\n" +
    "            re-flash-device-by-serial=\"reFlashDeviceBySerial\"\n" +
    "            flash-by-firmware=\"$parent.flashByFirmware\"\n" +
    "            flash-by-firmware-embedded-device=\"$parent.flashByFirmwareEmbeddedDevice\"\n" +
    "            filter-internal-on=\"globalUser.profile.filterInternalOn\"\n" +
    "            can-modify-features-by-serial=\"canModifyFeaturesBySerial\"\n" +
    "        >\n" +
    "        </detected-device>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/device/loading.client.view.html',
    "<section data-ng-controller=\"PartialsDeviceDeviceController\">\n" +
    "\n" +
    "    <div class=\"table-layout\" style=\"background-color:blue; height:100%;\">\n" +
    "        LOADING\n" +
    "    </div>\n" +
    "\n" +
    "</section>"
  );


  $templateCache.put('modules/core/views/partials/favorites/favorites.client.view.html',
    "<section data-ng-controller=\"PartialsFavoritesController\" ng-init=\"initFavorites()\" class=\"favorites-container main-content-topbar-offset\">\n" +
    "    <div class=\"container text-center\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"true\" backskipstep=\"{{ backSkipStep }}\" ></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'FAVORITE_LIST_TITLE' | translate }}</h1>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"container text-center\">\n" +
    "        <p>{{subtitle | translate}}</p>\n" +
    "    </div>\n" +
    "    <div class=\"container text-center\">\n" +
    "        <p>{{'FAVORITE_REMOTES_WARNING_SUBTITLE' | translate}}</p>\n" +
    "    </div>\n" +
    "    <div class=\"favorites-list\">\n" +
    "        <div class=\"switch-container favorites-compatible-filter\" ng-hide=\"isGlobalLoading\">\n" +
    "            <div class=\"favorites-compatible-filter-label\">\n" +
    "                {{'FAVORITE_SHOW_COMPATIBLE_ONLY' | translate}}\n" +
    "            </div>\n" +
    "            <div class=\"toggle-bg toggle-alternate {{hideIncompatibleFavorites ? 'on' : 'off'}}\">\n" +
    "                <label class=\"{{hideIncompatibleFavorites ? 'on' : 'off'}}\">{{hideIncompatibleFavorites ? 'ON' : 'OFF'}}</label>\n" +
    "                <input type=\"radio\" name=\"toggle\" ng-value=\"false\" ng-model=\"hideIncompatibleFavorites\">\n" +
    "                <input type=\"radio\" name=\"toggle\" ng-value=\"true\" ng-model=\"hideIncompatibleFavorites\">\n" +
    "                <span class=\"switch {{hideIncompatibleFavorites ? 'on' : 'off'}}\"></span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"favorites.length === 0 && !isGlobalLoading\">{{'FAVORITE_LIST_EMPTY' | translate}}</div>\n" +
    "        <div ng-show=\"hideIncompatibleFavorites && !hasIncompatibleFavorites && !isGlobalLoading\">{{'FAVORITE_COMPATIBLE_LIST_EMPTY' | translate}}</div>\n" +
    "        <div ng-repeat=\"favorite in favorites\" class=\"favorites-item\" ng-if=\"!hideIncompatibleFavorites || favorite.isCompatible\">\n" +
    "            <div class=\"favorites-top-section\">\n" +
    "                <p>{{favorite.name}}</p>\n" +
    "                <div ng-if=\"favorite.isCompatible\" class=\"compatible\">\n" +
    "                    <span class=\"icon icon-check-circle\"></span>&nbsp;Compatible\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"favorites-info-section\">\n" +
    "                <div class=\"favorites-details\">\n" +
    "                    <div>\n" +
    "                        <div ng-if=\"favorite.product\">\n" +
    "                            <span class=\"config-title\">{{ 'DETECTION_DEVICE_PRODUCT' | translate }}</span>\n" +
    "                            {{favorite.product.sku}}\n" +
    "                        </div>\n" +
    "                        <div ng-if=\"favorite.firmware\">\n" +
    "                            <span class=\"config-title\">{{ 'DETECTION_DEVICE_FIRMWARE' | translate }}</span>\n" +
    "                            {{favorite.firmware.name}} v{{favorite.firmware.version}}\n" +
    "                            <span ng-if=\"favorite.hasFirmwareUpdate\" class=\"out-of-date\">{{'FAVORITE_OUTDATED_TOOLTIP' | translate}}</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"favorites-klon-logo\" ng-show=\"favorite.firmware.isKeyCloning\">\n" +
    "                        <img src=\"img/klon/logo/klon-logo-black-56x13.png\"  alt=\"KLON logo\"/>\n" +
    "                        <span class=\"patent-no\">{{ 'FLASH_PATENT_NO' | translate }}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"favorites-description\" ng-bind-html=\"favorite.description\"></div>\n" +
    "            </div>\n" +
    "            <div class=\"favorites-bottom-section\">\n" +
    "                <div class=\"favorites-dates\">\n" +
    "                    <span ng-if=\"favorite.createdDate\">{{'FAVORITE_SAVED_ON' | translate}} {{favorite.createdDate | date}}</span>\n" +
    "                    <span ng-if=\"favorite.lastUpdate\">{{'FAVORITE_LAST_EDIT' | translate}} {{favorite.lastUpdate | date}}</span>\n" +
    "                </div>\n" +
    "                <div class=\"favorites-menu\">\n" +
    "                    <button class=\"favorites-menu-item\" ng-click=\"clickFavorite(favorite.id)\" aria-label=\"{{'FAVORITE_FLASH_TOOLTIP' | translate}}\" title=\"{{'FAVORITE_FLASH_TOOLTIP' | translate}}\" ng-disabled=\"!favorite.isCompatible\">\n" +
    "                        <svg viewBox=\"0 0 100 100\" xmlns=\"http://www.w3.org/2000/svg\"><use xlink:href=\"img/svg-defs.svg#icon-flash\"></use></svg>\n" +
    "                    </button>\n" +
    "                    <button class=\"favorites-menu-item\" ng-click=\"editFavorite(favorite.id)\" aria-label=\"{{'FAVORITE_EDIT_TOOLTIP' | translate}}\" title=\"{{'FAVORITE_EDIT_TOOLTIP' | translate}}\">\n" +
    "                        <svg viewBox=\"0 0 100 100\" xmlns=\"http://www.w3.org/2000/svg\"><use xlink:href=\"img/svg-defs.svg#icon-edit\"></use></svg>\n" +
    "                    </button>\n" +
    "                    <button class=\"favorites-menu-item\" ng-click=\"deleteFavorite(favorite.id)\" aria-label=\"{{'FAVORITE_DELETE_TOOLTIP' | translate}}\" title=\"{{'FAVORITE_DELETE_TOOLTIP' | translate}}\">\n" +
    "                        <svg viewBox=\"0 0 100 100\" xmlns=\"http://www.w3.org/2000/svg\"><use xlink:href=\"img/svg-defs.svg#icon-delete\"></use></svg>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/favorites/modals/delete-favorite.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">{{\"FAVORITE_DELETE_TITLE\" | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{\"FAVORITE_DELETE_SUBTITLE\" | translate:({'FAVORITE_NAME': name})}}</p>\n" +
    "<div class=\"button-container\">\n" +
    "    <button class=\"large-btn col-md\" ng-click=\"confirm(id)\">{{\"FAVORITE_CONFIRM\" | translate}}</button>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/partials/favorites/modals/edit-favorite.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">{{\"FAVORITE_EDIT_TITLE\" | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{\"FAVORITE_EDIT_SUBTITLE\" | translate}}</p>\n" +
    "<form name=\"editFavorite\" ng-submit=\"submitForm(editFavorite)\">\n" +
    "    <div class=\"edit-modal-content\">\n" +
    "        <label for=\"name\">{{\"FAVORITE_EDIT_NAME_LABEL\" | translate}}</label>\n" +
    "        <input type=\"text\" name=\"name\" id=\"name\" ng-model=\"name\" required ng-disabled=\"disableNameField\" disallow-tags minlength=\"3\" maxlength=\"100\">\n" +
    "        <label for=\"description\">{{\"FAVORITE_EDIT_DESCRIPTION_LABEL\" | translate}}</label>\n" +
    "        <textarea id=\"description\" name=\"description\" rows=\"5\" ng-model=\"description\" maxlength=\"500\" disallow-tags></textarea>\n" +
    "    </div>\n" +
    "    <div class=\"button-container\">\n" +
    "        <button class=\"large-btn error-btn col-md\" ng-click=\"closeThisDialog()\">{{\"FAVORITE_CANCEL\" | translate}}</button>\n" +
    "        <button class=\"large-btn col-md\" type=\"submit\" ng-disabled=\"editFavorite.$invalid\">{{\"FEATURES_BUTTON_SAVE\" | translate}}</button>\n" +
    "    </div>\n" +
    "</form>\n"
  );


  $templateCache.put('modules/core/views/partials/favorites/modals/save-favorite.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">{{\"FAVORITE_SAVE_TITLE\" | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{\"FAVORITE_SAVE_SUBTITLE\" | translate}}</p>\n" +
    "<form name=\"saveFavorite\" ng-submit=\"submit(saveFavorite)\">\n" +
    "    <div class=\"edit-modal-content\">\n" +
    "        <label for=\"name\">{{\"FAVORITE_EDIT_NAME_LABEL\" | translate}}</label>\n" +
    "        <input type=\"text\" name=\"name\" id=\"name\" ng-model=\"name\" required ng-disabled=\"disableNameField\" disallow-tags minlength=\"3\" maxlength=\"100\">\n" +
    "        <label for=\"description\">{{\"FAVORITE_EDIT_DESCRIPTION_LABEL\" | translate}}</label>\n" +
    "        <textarea id=\"description\" name=\"description\" rows=\"5\" ng-model=\"description\" maxlength=\"500\" disallow-tags></textarea>\n" +
    "    </div>\n" +
    "    <div class=\"button-container\">\n" +
    "        <button class=\"large-btn error-btn col-md\" ng-click=\"closeThisDialog()\">{{\"FAVORITE_CANCEL\" | translate}}</button>\n" +
    "        <button class=\"large-btn col-md\" type=\"submit\" ng-disabled=\"saveFavorite.$invalid\">{{ (proceedToFlash ? \"FAVORITE_BUTTON_SAVE_FLASH\" : \"FEATURES_BUTTON_SAVE\") | translate}}</button>\n" +
    "    </div>\n" +
    "</form>\n"
  );


  $templateCache.put('modules/core/views/partials/favorites/modals/update-favorite.modal.client.view.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">{{\"FAVORITE_OUTDATED_TITLE\" | translate}}</h2>\n" +
    "</div>\n" +
    "<p class=\"description-center\">{{\"FAVORITE_OUTDATED_SUBTITLE\" | translate:({'FAVORITE_NAME': name})}}</p>\n" +
    "<div class=\"button-container\">\n" +
    "    <button class=\"large-btn col-md error-btn\" ng-click=\"onFavoriteDelete(id)\">{{\"FAVORITE_DELETE_TOOLTIP\" | translate}}</button>\n" +
    "    <button class=\"large-btn col-md\" ng-click=\"confirm(id)\">{{\"FAVORITE_CONFIRM\" | translate}}</button>\n" +
    "</div>\n" +
    "<div class=\"button-container\">\n" +
    "    <button class=\"col-md\" ng-click=\"closeThisDialog(id)\">{{\"FAVORITE_CANCEL\" | translate}}</button>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/features/directives/moreLessDescription.client.view.html',
    "<span ng-if=\"hmtext.length > hmlimit\">\n" +
    "    <span ng-hide=\"hmfulltext\">\n" +
    "        <span class=\"hmtext read-more-less\">\n" +
    "            {{ hmtext | limitTo:hmlimit }}...&nbsp;\n" +
    "            <a ng-click=\"toggleValue()\">\n" +
    "                {{ 'FEATURES_READ_MORE_TEXT'  | translate }}\n" +
    "            </a>\n" +
    "        </span>\n" +
    "    </span>\n" +
    "    <span ng-show=\"hmfulltext\">\n" +
    "\n" +
    "        <span class=\"hmtext read-more-less\">\n" +
    "            {{ hmtext }}\n" +
    "            <a ng-click=\"toggleValue()\">\n" +
    "                {{ 'FEATURES_READ_LESS_TEXT'  | translate }}\n" +
    "            </a>\n" +
    "        </span>\n" +
    "    </span>\n" +
    "</span>\n" +
    "<span ng-if=\"hmtext.length <= hmlimit\">\n" +
    "    <span class=\"hmtext\">{{ hmtext }}</span>\n" +
    "</span>"
  );


  $templateCache.put('modules/core/views/partials/features/features.client.view.html',
    "<section data-ng-controller=\"PartialsFeaturesFeaturesController\" ng-init=\"initFeatures()\">\n" +
    "    <div class=\"main-content-sidebar-offset\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"{{ backSkipStep }}\" nextskipstep=\"{{ nextSkipStep }}\" ></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'FEATURES_TITLE' | translate }}</h1>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"features-list\">\n" +
    "            <div ng-hide=\"isGlobalLoading\">\n" +
    "                <div class=\"no-feature\" ng-hide=\"globalResponseData.deviceFeatures.length > 0\">{{ 'FEATURES_NO_FEATURES' | translate }}</div>\n" +
    "                <div ng-hide=\"globalResponseData.deviceFeatures.length === 0\">\n" +
    "                    <div>\n" +
    "                         <button class=\"small-btn no-margin\" ng-click=\"resetGroupFeatures()\">{{ 'FEATURES_BUTTON_RESETGROUP' | translate }}</button>\n" +
    "                         <button class=\"small-btn no-margin right uiTest-nextGroup-configFeatures\" ng-hide=\"isLastIndex\" ng-click=\"nextGroupFeatures()\">{{ btnSaveNextText | translate }}</button>\n" +
    "                         <button class=\"small-btn no-margin right\" ng-show=\"isLastIndex && editFeatureOnly\" ng-click=\"saveFeatures()\">{{ btnSaveNextText | translate }}</button>\n" +
    "                         <button class=\"small-btn right uiTest-nextGroup-configFeatures\" ng-hide=\"isFirstIndex\" ng-click=\"previousGroupFeatures()\">{{ btnPreviousMenuText | translate }}</button>\n" +
    "                     </div>\n" +
    "                    <div class=\"title\">{{ globalResponseData.deviceFeatures[submenu.selectedItem].name }}\n" +
    "                    </div>\n" +
    "                    <div class=\"description\">{{ globalResponseData.deviceFeatures[submenu.selectedItem].description }}</div>\n" +
    "                    <div class=\"list-item uiTest-configFeatures-page\"\n" +
    "                        ng-repeat=\"feature in globalResponseData.deviceFeatures[submenu.selectedItem].featureConfigurations\"\n" +
    "                        feature-row=\"feature\">\n" +
    "                    </div>\n" +
    "                    <div class=\"footer\" ng-show=\"globalResponseData.deviceFeatures[submenu.selectedItem].featureConfigurations.length > 6\">\n" +
    "                        <button class=\"small-btn no-margin left\" ng-click=\"resetGroupFeatures()\">{{ 'FEATURES_BUTTON_RESETGROUP' | translate }}</button>\n" +
    "                        <button class=\"small-btn uiTest-nextGroup-configFeatures\" ng-hide=\"isFirstIndex\" ng-click=\"previousGroupFeatures()\">{{ btnPreviousMenuText | translate }}</button>\n" +
    "                        <button class=\"small-btn no-margin uiTest-nextGroup-configFeatures-bottom\" ng-hide=\"isLastIndex\" ng-click=\"nextGroupFeatures()\">{{ btnSaveNextText | translate }}</button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"button-container\" ng-hide=\"isGlobalLoading\">\n" +
    "                <button class=\"small-btn error-btn\"  ng-hide=\"!editFeatureOnly || globalResponseData.deviceFeatures.length === 0\" ng-click=\"cancel()\">{{ 'FEATURES_BUTTON_CANCEL_FEATURESONLY' | translate }}</button>\n" +
    "                <button class=\"small-btn\" ng-disabled=\"!hasEditedFeatures\" ng-hide=\"!editFeatureOnly || globalResponseData.deviceFeatures.length === 0\" ng-click=\"saveFeatures()\">{{ 'FEATURES_BUTTON_SAVE_FEATURESONLY' | translate }}</button>\n" +
    "\n" +
    "                <button class=\"large-btn uiTest-SaveAndGoToGuides-btn\" ng-hide=\"editFeatureOnly\" ng-if=\"$parent.configurationParameters.flashByFirmware\" ng-click=\"skipstep('flash')\">{{ 'FLASH_BY_FIRMWARE_SAVE_FEATURES' | translate }}</button>\n" +
    "                <button class=\"large-btn uiTest-SaveAndGoToGuides-btn\" ng-hide=\"editFeatureOnly\" ng-if=\"!$parent.configurationParameters.flashByFirmware\" ng-click=\"skipstep('guides')\">{{ 'FEATURES_BUTTON_SAVE_GO_TO' | translate }}</button>\n" +
    "                <!-- Configuration favorites, not released yet\n" +
    "                <button class=\"large-btn\" type=\"button\" ng-disabled=\"!hasEditedFeatures\" ng-hide=\"editFeatureOnly\" ng-if=\"!$parent.configurationParameters.flashByFirmware && !$parent.configurationParameters.flashFromFavoriteConfig\" ng-click='saveFavoriteConfig()'>{{'FAVORITE_CONFIG_SAVE_AS' | translate}}</button>\n" +
    "                -->\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/features/submenu.client.view.html',
    "<section data-ng-controller=\"PartialsFeaturesSubmenuController\">\n" +
    "    <div class =\"submenu-container features-submenu-container uiTest-subMenu-container\">\n" +
    "        <ul class=\"submenu uiTest-submenu\">\n" +
    "            <li class=\"list-item uiTest-submenu-list-item\" ng-repeat=\"item in $parent.submenu.items track by $index\"\n" +
    "                ng-click=\"loadDeviceFeature($index, item)\" uiTest-subMenu-id=\"{{$parent.submenu.items.length}}\"\n" +
    "                uiTest-itemid=\"{{item}}\"\n" +
    "                uiTest-indexid=\"{{$index}}\"\n" +
    "                ng-class=\"{active: $parent.submenu.selectedItem==$index}\">\n" +
    "                <crop-menu-label label=\"{{ item }}\"></crop-menu-label>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/accessories.container.client.directive.view.html',
    "<div ng-show=\"responseData.optional.length > 0 || responseData.required.length > 0 || responseData.activations.length > 0\">\n" +
    "   <div class=\"accessories-container\" ng-if=\"responseData.required.length > 0\">\n" +
    "       <h4 class=\"title-regular capitalize\">{{ 'ACCESSORIES_REQUIRED_ACCESSORIES' | translate }}</h4>\n" +
    "       <div class=\"sub-text\">\n" +
    "           <span>\n" +
    "               {{ 'ACCESSORIES_REQUIRED_ACCESSORIES_SUB_TEXT' | translate }}\n" +
    "           </span>\n" +
    "       </div>\n" +
    "       <div class=\"row accessory-item-box\">\n" +
    "            <div ng-repeat=\"ac in responseData.required\" class=\"accessory-item col-xs-12 col-sm-4 col-md-3\">\n" +
    "                <div class=\"image-container\">\n" +
    "                    <img ng-src=\"{{ ac.mediumImageName }}\" err-src=\"img/accessories/generic.png\" />\n" +
    "                </div>\n" +
    "                <div>{{ ac.name }}</div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "   </div>\n" +
    "    <div class=\"accessories-container\" ng-if=\"responseData.optional.length > 0\">\n" +
    "        <h4 class=\"title-regular capitalize\">{{ 'ACCESSORIES_OPTIONAL_ACCESSORIES' | translate }}</h4>\n" +
    "        <div class=\"sub-text\">\n" +
    "           <span>\n" +
    "               {{ 'ACCESSORIES_OPTIONAL_ACCESSORIES_SUB_TEXT' | translate }}\n" +
    "           </span>\n" +
    "        </div>\n" +
    "        <div class=\"row accessory-item-box\">\n" +
    "            <div ng-repeat=\"op in responseData.optional\" class=\"accessory-item col-xs-12 col-sm-4 col-md-3\">\n" +
    "                <div class=\"image-container\">\n" +
    "                    <img ng-src=\"{{ op.mediumImageName }}\" err-src=\"img/accessories/generic.png\" />\n" +
    "                </div>\n" +
    "                <div>{{ op.name }}</div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"accessories-container\" ng-if=\"responseData.activations.length > 0\">\n" +
    "        <h4 class=\"title-regular capitalize\">{{ 'ACCESSORIES_ACTIVATION_TITLE' | translate }}</h4>\n" +
    "        <div class=\"sub-text\">\n" +
    "           <span> {{ 'ACCESSORIES_ACTIVATION_SUB_TEXT' | translate }}</span>\n" +
    "        </div>\n" +
    "        <div class=\"row accessory-item-box\">\n" +
    "            <div ng-repeat=\"av in responseData.activations\" class=\"accessory-item col-xs-12 col-sm-4 col-md-3\">\n" +
    "                <div class=\"image-container\">\n" +
    "                    <img ng-src=\"{{ av.mediumImageName }}\" err-src=\"{{av.sku.toLowerCase().indexOf('siren') !== -1 ? 'img/accessories/generic-siren.png' : 'img/accessories/generic.png'}}\" />\n" +
    "                </div>\n" +
    "                <div>{{ av.name }}</div>\n" +
    "                <div ng-if=\"av.isAddButton\" class=\"add-siren-container\"><button type=\"button\" class=\"add-button\" ng-click=\"ctrl.openModalBox()\"><i class=\"fa fa-plus-circle fa-2x\" aria-hidden=\"true\"></i></button></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/firmwareselection.client.directive.view.html',
    "<section class=\"guides-container main-content-topbar-offset\">\n" +
    "    <div class=\"nav-ctn\">\n" +
    "        <page-navigation ng-hide=\"isGuidesMenuUnlocked\" hidebackbtn=\"false\" hidenextbtn=\"!ctrl.firmwareSet || ctrl.userSelection.isFwChanged\" backskipstep={{ctrl.backSkipStep}} nextskipstep=\"oem-remote-start\" ></page-navigation>\n" +
    "        <h1 class=\"main-header\">{{ 'FIRMWARE_TITLE' | translate }}</h1>\n" +
    "    </div>\n" +
    "    <div class=\"container text-center\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-xs-12\">\n" +
    "                <p class=\"description-center\">{{ 'FIRMWARE_DESCRIPTION' | translate}}</p>\n" +
    "                <div ng-show=\"ctrl.userSelection.selectedFirmware.id\">\n" +
    "                    <div class=\"generic-config-box\">\n" +
    "                        <div class=\"list-item large\" ng-click=\"ctrl.setFirmware(ctrl.userSelection.selectedFirmware.id)\" ng-disabled=\"$root.isGlobalLoading\">\n" +
    "                            <div>\n" +
    "                                <div>\n" +
    "                                    {{ ctrl.userSelection.selectedFirmware.name }}\n" +
    "                                </div>\n" +
    "                                <div class=\"list-sub-title\">\n" +
    "                                    {{ ctrl.userSelection.selectedFirmware.familyName }} v{{ ctrl.userSelection.selectedFirmware.version }}\n" +
    "                                </div>\n" +
    "                                <div class=\"list-sub-title\" ng-show=\"ctrl.userSelection.selectedFirmware.id === ctrl.responseData.currentFirmware.id\">\n" +
    "                                    <i class=\"fa fa-check-circle fa-lg\"></i> {{ 'FIRMWARE_RECOMMENDED' | translate }}\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"klon-logo\" ng-show=\"ctrl.userSelection.selectedFirmware.isKeyCloning\">\n" +
    "                        <img src=\"img/klon/logo/klon-logo-black-56x13.png\" />\n" +
    "                        <span class=\"patent-no\">{{ 'FLASH_PATENT_NO' | translate }}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <firmware-slider\n" +
    "                    request=\"ctrl.request\"\n" +
    "                    response-data=\"ctrl.responseData\"\n" +
    "                    user-selection=\"ctrl.userSelection\"\n" +
    "                >\n" +
    "                </firmware-slider>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div>\n" +
    "            <accessories-container\n" +
    "                request=\"ctrl.request\"\n" +
    "                response-data=\"ctrl.userSelection.selectedAccessories\"\n" +
    "                user-selection=\"ctrl.userSelection\"\n" +
    "            >\n" +
    "            </accessories-container>\n" +
    "            <div class=\"button-container firmware-selection-footer\">\n" +
    "                <button class=\"large-btn\" ng-disabled=\"$root.isGlobalLoading\" ng-click=\"ctrl.setFirmware(ctrl.userSelection.selectedFirmware.id)\">{{ 'FIRMWARE_BUTTON_CONTINUE' | translate }}</button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/firmwareslider.client.directive.view.html',
    "<p><span ng-click=\"slidercontroller.openFirmwareSlider()\" class=\"open-firmware\">{{ 'FIRMWARE_CHOOSE_DIFFERENT_FIRMWARE' | translate }}</span></p>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/firmwareslider.modal.client.directive.view.html',
    "<div>\n" +
    "  <div class=\"modal-heading\">{{ 'FIRMWARE_MODEL_TITLE' | translate:({ FIRMWARE : ctrl.currentFirmware.name }) }}</div>\n" +
    "  <div ng-repeat=\"firmware in ctrl.alternativeFirmwares\" ng-if=\"firmware.firmwares.length > 0\">\n" +
    "    <div class=\"section-title\">{{ ('FIRMWARES_TITLE_' + (firmware.name.toUpperCase())) | translate }}</div>\n" +
    "    <div ng-repeat=\"ma in firmware.firmwares\" class=\"firmware-container\">\n" +
    "      <div>\n" +
    "        <div class=\"firmware-title\">{{ ma.name }}</div>\n" +
    "        <div class=\"firmware-sub-title\">{{ ma.familyName }} <span ng-show=\"ma.firmwareFullVersion\">v{{ma.firmwareFullVersion}}</span>\n" +
    "          <span ng-show=\"ma.id === ctrl.currentFirmware.id\">({{ 'FIRMWARE_RECOMMENDED' | translate}})</span>\n" +
    "        </div>\n" +
    "        <div ng-show=\"ma.releaseNotes\">* {{ ma.releaseNotes }}</div>\n" +
    "      </div>\n" +
    "      <div class=\"button-container\">\n" +
    "        <button class=\"small-btn\" ng-click=\"confirm(ma)\">{{ 'DEVICE_BANNER_FIRMWARE_UPDATE_CONFIRM_BTN' | translate}}</button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/hsn.directive.view.html',
    "<div class=\"harness-modal\">\n" +
    "    <div class=\"harness-serial-number-section\">\n" +
    "        <div class=\"ngdialog-header\">\n" +
    "            <h3>{{ 'HSN_HARNESS_TITLE' | translate}}</h3>\n" +
    "            <p>{{ 'HSN_HARNESS_SUBTITLE' | translate}}</p>\n" +
    "        </div>\n" +
    "        <div class=\"harness-list\">\n" +
    "            <div ng-if=\"harnessOptions.length>0\" class=\"harness-item\" ng-click=\"harnessClick(harnessOptions[0].id)\" ng-class=\"{active:selectedHarness === harnessOptions[0].id}\" tabindex=\"0\">\n" +
    "                <div>{{harnessOptions[0].name}} <br> ({{'T_HARNESS' | translate}})</div>\n" +
    "            </div>\n" +
    "            <div ng-repeat=\"harness in harnessOptions.slice(1)\" class=\"harness-item\" ng-click=\"harnessClick(harness.id)\" ng-class=\"{active:selectedHarness === harness.id}\" tabindex=\"0\">\n" +
    "                <div>{{harness.name}} <br> ({{'T_HARNESS' | translate}})</div>\n" +
    "            </div>\n" +
    "            <div ng-disabled=\"!analogHarness\" class=\"harness-item\" ng-click=\"harnessClick(99999)\" ng-class=\"{active:selectedHarness === 99999}\" tabindex=\"0\">\n" +
    "                <div>{{ 'T_HARNESS_SELECTION_MODAL_ANALOG' | translate}}</div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"harness-serial-number-section\" ng-disabled=\"!selectedHarness\">\n" +
    "        <div class=\"ngdialog-header\">\n" +
    "            <h3>{{ 'HSN_SERIAL_TITLE' | translate }}</h3>\n" +
    "        </div>\n" +
    "        <div class=\"harness-serial-section\">\n" +
    "            <div class=\"harness-serial-location\">\n" +
    "                <img ng-src=\"img/harness-serial/harness-serial-location.png\" alt=\"\" />\n" +
    "            </div>\n" +
    "            <div class=\"harness-input-section\">\n" +
    "                <div>\n" +
    "                    <p ng-if=\"!harnessSerialInfo\">{{ 'HSN_SERIAL_DESCRIPTION_SHORT' | translate}}</p>\n" +
    "                    <p ng-if=\"harnessSerialInfo\">{{ 'HSN_SERIAL_DESCRIPTION_LONG' | translate}}</p>\n" +
    "                    <a href=\"#\" ng-click=\"harnessSerialInfoToggle()\">{{ (harnessSerialInfo ? 'FEATURES_READ_LESS_TEXT' : 'FEATURES_READ_MORE_TEXT') | translate}}</a>\n" +
    "                </div>\n" +
    "                <form name=\"harnessForm\" novalidate ng-submit=\"submit(harnessForm)\" class=\"harness-serial-form\">\n" +
    "                    <div class=\"harness-serial-input\">\n" +
    "                        <input type=\"text\"\n" +
    "                               ng-model=\"harnessSerialNumber\"\n" +
    "                               name=\"harnessSerialNumber\"\n" +
    "                               ng-disabled=\"$root.isGlobalLoading\"\n" +
    "                               maxlength=\"10\"\n" +
    "                               minlength=\"10\"\n" +
    "                               required\n" +
    "                               placeholder=\"Ex. CD6AB99221\"\n" +
    "                               harness-serial\n" +
    "                        />\n" +
    "                        <div ng-show=\"!harnessForm.$pristine && !$root.isGlobalLoading\">\n" +
    "                            <span class='invalid-icon' ng-show=\"harnessForm.harnessSerialNumber.$error.serial\"><i class=\"fa fa-times\" aria-hidden=\"true\"></i></span>\n" +
    "                            <span class='valid-icon' ng-show=\"showHarnessValidationMessage && harnessValidationResponse.status !== 0 && !harnessForm.harnessSerialNumber.$error.serial\"><i class=\"fa fa-check\" aria-hidden=\"true\"></i></span>\n" +
    "                        </div>\n" +
    "                        <span ng-show=\"$root.isGlobalLoading\"><i class=\"fa fa-spinner fa-pulse\" aria-hidden=\"true\"></i></span>\n" +
    "                    </div>\n" +
    "                    <button ng-disabled=\"harnessForm.$invalid || $root.isGlobalLoading\" type=\"submit\" class=\"harness-prompt-button\">{{ 'HSN_SERIAL_VALIDATE' | translate}}</button>\n" +
    "                    <button ng-disabled=\"!selectedHarness || $root.isGlobalLoading\" ng-click=\"useCredit()\" class=\"harness-prompt-button left-pad\">{{ 'HSN_SERIAL_NONE' | translate}}</button>\n" +
    "                </form>\n" +
    "                <div ng-if=\"showHarnessValidationMessage\" class=\"harness-validation-message\" ng-class=\"harnessValidationMessageClass\">\n" +
    "                    <div ng-switch=\"harnessValidationResponse.status\" class=\"harness-validation-message-icon\" ng-class=\"harnessValidationMessageClass\">\n" +
    "                        <i ng-switch-when=\"0\" class=\"fa fa-times fa-3x\" aria-hidden=\"true\"></i>\n" +
    "                        <i ng-switch-when=\"1\" class=\"fa fa-check fa-3x\" aria-hidden=\"true\"></i>\n" +
    "                        <i ng-switch-when=\"2\" class=\"fa fa-warning fa-3x\" aria-hidden=\"true\"></i>\n" +
    "                    </div>\n" +
    "                    <p>{{harnessValidationResponse.message | translate:({'HSN': harnessSerialNumber, 'SKU': harnessInfo.sku})}} <small>{{harnessValidationResponse.message}}</small></p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"harness-validation-footer\">\n" +
    "        <div class=\"harness-credit-section\">\n" +
    "            <p>{{ 'HSN_CREDIT_REMAINING' | translate:({'NB_CREDITS' : remainingCredit})}}</p>\n" +
    "            <a href=\"#\" ng-click=\"viewCreditDetails()\">{{ 'HSN_CREDIT_LINK_TEXT' | translate}}</a>\n" +
    "        </div>\n" +
    "        <button ng-disabled=\"!selectedHarness || harnessValidationResponse.status <= 0 || $root.isGlobalLoading\" ng-click=\"event()\">{{ 'T_HARNESS_SELECTION_MODAL_BUTTON' | translate}}</button>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/hubfirmwareslider.modal.client.directive.view.html',
    "<div>\n" +
    "  <div class=\"modal-heading\">{{ 'HUB_FIRMWARE_STAFF_TITLE' | translate }}</div>\n" +
    "  <div ng-repeat=\"firmware in ctrl.alternativeFirmwares\" ng-if=\"firmware.firmwares.length > 0\">\n" +
    "    <div class=\"section-title\">{{ ('FIRMWARES_TITLE_' + (firmware.name.toUpperCase())) | translate }}</div>\n" +
    "    <div ng-repeat=\"ma in firmware.firmwares\" class=\"firmware-container\">\n" +
    "      <div>\n" +
    "        <div class=\"firmware-title\">{{ ma.name }}</div>\n" +
    "        <div class=\"firmware-sub-title\">{{ ma.familyName }} <span ng-show=\"ma.version\">v{{ma.version}}</span>\n" +
    "          <span ng-show=\"ma.id === ctrl.currentFirmware.id\">({{ 'FIRMWARE_RECOMMENDED' | translate}})</span>\n" +
    "        </div>\n" +
    "        <div ng-show=\"ma.releaseNotes\">* {{ ma.releaseNotes }}</div>\n" +
    "      </div>\n" +
    "      <div class=\"button-container\">\n" +
    "        <button class=\"small-btn\" ng-click=\"confirm(ma)\">{{ 'DEVICE_BANNER_FIRMWARE_UPDATE_CONFIRM_BTN' | translate}}</button>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/rfidrequired.modal.client.directive.html',
    "<div class=\"ngdialog-header\">\n" +
    "    <h2 class=\"text-center\">\n" +
    "        {{ 'TRIM_HUB_MODAL_TITLE_ACCESSORY' | translate }}\n" +
    "        {{ 'TRIM_HUB_MODAL_TITLE_REQUIRED' | translate }}\n" +
    "    </h2>\n" +
    "</div>\n" +
    "<div class=\"description-left\">\n" +
    "    <p>{{ 'TRIM_HUB_MODAL_DESC_ACC_LIST' | translate }}\n" +
    "    <ul>\n" +
    "        <li>\n" +
    "            ACC-RFID\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "    </p>\n" +
    "    <p>{{ 'TRIM_HUB_MODAL_DESC_ACC_FOOTER' | translate }}</p>\n" +
    "</div>\n" +
    "<div class=\"button-container\">\n" +
    "    <button class=\"large-btn col-md\" ng-click=\"confirm()\">OK</button>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/directives/tharnessselection.directive.view.html',
    "<div>\n" +
    "    <div class=\"ngdialog-header keyreading-header\">\n" +
    "        <h3 class=\"text-center\">{{ 'T_HARNESS_SELECTION_MODAL_TITLE' | translate}}</h3>\n" +
    "        <p class=\"keyreading-sub-title\">{{ 'T_HARNESS_SELECTION_MODAL_SUBTITLE' | translate}}</p>\n" +
    "    </div>\n" +
    "    <div class=\"generic-config-box\">\n" +
    "        <div class=\"list-container\">\n" +
    "            <div ng-repeat=\"harness in harnessOptions\" class=\"list-item xs\" ng-click=\"harnessClick(harness.id)\" ng-class=\"{active:selectedHarness === harness.id}\">{{harness.name}} <br> ({{'T_HARNESS' | translate}})</div>\n" +
    "            <div ng-disabled=\"!analogHarness\" class=\"list-item xs\" ng-click=\"harnessClick(99999)\" ng-class=\"{active:selectedHarness === 99999}\">{{ 'T_HARNESS_SELECTION_MODAL_ANALOG' | translate}}</div>\n" +
    "        </div>\n" +
    "        <div class=\"button-container\">\n" +
    "            <button ng-disabled=\"!selectedHarness || $root.isGlobalLoading\" ng-click=\"event()\">{{ 'T_HARNESS_SELECTION_MODAL_BUTTON' | translate}}</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/firmware/firmware.client.view.html',
    "<firmware-selection\n" +
    "    load-controls-partial=\"loadControlsPartial\"\n" +
    "    load-device-partial=\"loadDevicePartial\"\n" +
    "    load-oem-remote-start-partial=\"loadOemRemoteStartPartial\"\n" +
    "    load-feature-partial=\"loadFeaturePartial\"\n" +
    "    request=\"request\"\n" +
    "    response-data=\"responseData\"\n" +
    "    skip-controls=\"skipControls\"\n" +
    "    user-selection=\"userSelection\"\n" +
    "    vehicle-selection=\"selectedVehicle\"\n" +
    "    prev-vehicle-selection=\"prevSelectedVehicle\"\n" +
    ">\n" +
    "</firmware-selection>\n"
  );


  $templateCache.put('modules/core/views/partials/flash/flash.client.view.html',
    "<section data-ng-controller=\"PartialsFlashFlashController\" ng-init=\"initFlash()\" xmlns=\"http://www.w3.org/1999/html\">\n" +
    "\n" +
    "    <notification-bar\n" +
    "        class=\"notification-bar\"\n" +
    "        ng-show=\"$parent.showDeviceNotificationBar\"\n" +
    "        ng-class=\"{'sidebar-open': toggle}\"\n" +
    "        img=\"{{ $parent.responseData.rfDecoder.smallImage }}\"\n" +
    "        title=\"{{ 'FLASH_BANNER_RF_TITLE'   | translate }}\" message=\"{{ 'FLASH_BANNER_RF_TEXT'  | translate }}\"\n" +
    "        event=\"closeDeviceNotificationBar()\" button=\"{{ 'FLASH_BANNER_RF_BUTTON' | translate }}\">\n" +
    "    </notification-bar>\n" +
    "\n" +
    "    <div class=\"keyreading-progress-view\" id=\"keyreading-progress-view\" ng-if=\"keyreadingFlash\">\n" +
    "        <div class=\"keyreading-container\" ng-controller=\"PartialsFlashKeyreadingFlashController\">\n" +
    "            <div class=\"ngdialog-header keyreading-header\">\n" +
    "                <p class=\"keyreading-title\">{{ 'KEYREADING_MESSAGE' | translate}}</p>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"keyreading-content\">\n" +
    "                <div ng-repeat=\"step in $parent.klonSteps\"  ng-switch=\"step.canonicalName\" class=\"keyreading-content-element {{step.stepOrder - 0 ===  $parent.klonSteps.length ? 'last' : ''}} {{$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1 ? 'current-step' : ''}}\" >\n" +
    "                    <div class=\"keyreading-content-element-wrapper {{keyreadingError ? 'keyreading-error' : '' }}\" ng-switch-when=\"key-reading\">\n" +
    "                        <p ng-if-start=\"keyreadingError\" class=\"bold\">{{ 'KEYREADING_STEP2_ERROR_TITLE' | translate}}</p>\n" +
    "                        <p>{{ keyreadingErrorMessage | translate}}</p>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <button class=\"keyreading-button col-md inverted\" ng-click=\"startKeyReading()\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "                            <button ng-if=\"showRedetectInError\" class=\"keyreading-button col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "                        </div>\n" +
    "                        <div ng-if-start=\"!keyreadingError\">\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%204_KEY%20READING.gif\" alt=\"Key reading animation\" >\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-4_KEY-READING.gif\" alt=\"Key reading thumbnail\" >\n" +
    "                            <p ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ 'KEYREADING_IN_PROGRESS' | translate}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\">{{ 'KEYREADING_PENDING' | translate}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\">{{ 'KEYREADING_DONE' | translate}}</p>\n" +
    "\n" +
    "                        </div>\n" +
    "                        <div ng-if=\"showWaitTime\">\n" +
    "                            <p>{{ 'KEYREADING_STEP2_COUNTDOWN' | translate}}</p>\n" +
    "                            <h1>{{waitTime}}</h1>\n" +
    "                        </div>\n" +
    "                        <div class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if-end>\n" +
    "                            <div class=\"extra-warning\">\n" +
    "                                <span class=\"icon-warning\"></span>\n" +
    "                                <p>{{ 'KEYREADING_ALERT' | translate}}</p>\n" +
    "                            </div>\n" +
    "                            <div  class=\"button-container\">\n" +
    "                                <div class=\"keyreading-progress-border\">\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"keyreadingProgressBarGraphStyle\" class=\"keyreading-progress animate-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"keyreading-progress\"></div>\n" +
    "\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage {{keyreadingPercentage >= 0 && 'keyreading-flash-percentage-active'}}\" ng-style=\"keyreadingPercentageStyle\">{{keyreadingPercentage >= 0 ? keyreadingPercentage : ''}}</div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"keyreadingPercentageStyle\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100%</div>\n" +
    "                                </div>\n" +
    "                                <button class=\"keyreading-button col-md\" ng-if=\"!accRFIDRequired && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"$parent.cancelKeyreading()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "                            </div>\n" +
    "                            </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper {{flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1 ? 'keyreading-error' : '' }}\" ng-switch-when=\"flash-main-module\">\n" +
    "                        <p ng-if-start=\"flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"bold\">{{ 'FLASH_ERROR_FLASHING' | translate}}</p>\n" +
    "                        <p>{{ flashErrorMessage | translate}}</p>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <button class=\"keyreading-button col-md inverted\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "                            <button ng-if=\"showRedetectInError\" class=\"keyreading-button col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "                        </div>\n" +
    "                        <div ng-if-start=\"!(flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1)\">\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%206_MODULE%20FLASHING.gif\" alt=\"Module flashing animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-6_MODULE-FLASHING.gif\" alt=\"Module flashing thumbnail\">\n" +
    "                            <p ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_PENDING' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_DONE' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "\n" +
    "                        </div>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <div ng-if=\"!showUpdateMessage && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ 'FLASH_PROGRESS_TITLE_FIRMWARE_UPDATE_EMBEDDED_DEVICE' | translate:({ 'ACTUAL_STEP_NUM':flashnumber, 'TOTAL_STEPS':totalflash }) }}</div>\n" +
    "                            <p ng-if=\"showUpdateMessage && !showProgressMessage && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ $parent.progress.message | translate }}</p>\n" +
    "                            <div class=\"flash-progress-border {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"progressBarGraphStyle\" class=\"flash-progress animate-progress\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage {{percentage >= 0 && 'keyreading-flash-percentage-active'}}\" ng-style=\"progressBarPercentageKeyreadingStyle\">{{percentage >= 0 ? percentage : ''}}</div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100%</div>\n" +
    "                            </div>\n" +
    "                            <button class=\"keyreading-button col-md\" ng-if=\"!accRFIDRequired && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"$parent.cancelKeyreading()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper {{flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1 ? 'keyreading-error' : '' }}\" ng-switch-when=\"flash-main-module-step1\">\n" +
    "                        <p ng-if-start=\"flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"bold\">{{ 'FLASH_ERROR_FLASHING' | translate}}</p>\n" +
    "                        <p>{{ flashErrorMessage | translate}}</p>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <button class=\"keyreading-button col-md inverted\" ng-click=\"startMainModuleFlash(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "                            <button ng-if=\"showRedetectInError\" class=\"keyreading-button col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "                        </div>\n" +
    "                        <div ng-if-start=\"!(flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1)\">\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%206_MODULE%20FLASHING.gif\" alt=\"Module flashing animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-6_MODULE-FLASHING.gif\" alt=\"Module flashing thumbnail\">\n" +
    "                            <p ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_PENDING' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_DONE' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div ng-if-end class=\"button-container {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <div ng-if=\"!showUpdateMessage && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ 'FLASH_PROGRESS_TITLE_FIRMWARE_UPDATE_EMBEDDED_DEVICE' | translate:({ 'ACTUAL_STEP_NUM':flashnumber, 'TOTAL_STEPS':totalflash }) }}</div>\n" +
    "                            <p ng-if=\"showUpdateMessage && !showProgressMessage && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ $parent.progress.message | translate }}</p>\n" +
    "                            <div class=\"flash-progress-border\" ng-if=\"started[step.stepOrder - 0]  || step.stepOrder - 0 === 1\">\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"progressBarGraphStyle\" class=\"flash-progress animate-progress\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage {{percentage >= 0 && 'keyreading-flash-percentage-active'}}\" ng-style=\"progressBarPercentageKeyreadingStyle\">{{percentage >= 0 ? percentage : ''}}</div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100%</div>\n" +
    "                            </div>\n" +
    "                            <button class=\"keyreading-button col-md\" ng-if=\"!started[step.stepOrder - 0] && step.stepOrder - 0 !== 1\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startMainModuleFlash(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                            <button class=\"keyreading-button col-md\" ng-if=\"!accRFIDRequired && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"$parent.cancelKeyreading()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "                        </div>\n" +
    "\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper {{flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1 ? 'keyreading-error' : '' }}\" ng-switch-when=\"flash-main-module-step2\">\n" +
    "                        <p ng-if-start=\"flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"bold\">{{ 'FLASH_ERROR_FLASHING' | translate}}</p>\n" +
    "                        <p>{{ flashErrorMessage | translate}}</p>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <button class=\"keyreading-button col-md inverted\" ng-click=\"doKlonFlash(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "                            <button ng-if=\"showRedetectInError\" class=\"small-btn col-md\" ng-click=\"refreshPage()\">{{ 'FLASH_REDETECT_BUTTON' | translate}}</button>\n" +
    "                        </div>\n" +
    "                        <div ng-if-start=\"!(flashError && $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1)\">\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%206_MODULE%20FLASHING.gif\" alt=\"Module flashing animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-6_MODULE-FLASHING.gif\" alt=\"Module flashing thumbnail\">\n" +
    "                            <p ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_PENDING' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_DONE' | translate:({ DEVICE_NAME : mainDevice.productName })}}</p>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <div ng-if=\"!($parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length)\" class=\"flash-progress-border  {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"progressBarGraphKlonStyle\" class=\"flash-progress animate-progress\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage {{percentage >= 0 && 'keyreading-flash-percentage-active'}}\" ng-style=\"progressBarPercentageKlonStyle\">{{percentage >= 0 ? percentage : ''}}</div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\"></div>\n" +
    "                                <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100%</div>\n" +
    "                            </div>\n" +
    "                            <button class=\"keyreading-button col-md\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"$parent.cancelKeyreading()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper {{showAccRfidError ? 'keyreading-error' : ''}}\" ng-switch-when=\"flash-acc-rfid\">\n" +
    "                        <p ng-if-start=\"showAccRfidError\" class=\"bold\">{{ 'KEYREADING_RFID_ERROR_TITLE' | translate}}</p>\n" +
    "                        <p>{{ 'KEYREADING_RFID_ERROR_MESSAGE' | translate}}</p>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <button class=\"keyreading-button col-md\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startAccRfidFlash()\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "                            <button class=\"skip-step-button inverted\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"skipAccRfid(step.stepOrder - 1); navProgress(step.stepOrder - 1)\">{{ 'KEYREADING_RFID_SKIP' | translate}}</button>\n" +
    "                        </div>\n" +
    "                        <div ng-if-start=\"!showAccRfidError\">\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%208_RFID%20FLASHING.gif\" alt=\"rfid flashing animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-8_RFID-FLASHING.gif\" alt=\"rfid flashing thumbnail\">\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_PENDING' | translate:({ DEVICE_NAME : accRfidDeviceName }) }}</p>\n" +
    "                            <p ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_IN_PROGRESS' | translate:({ DEVICE_NAME : accRfidDeviceName }) }}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"accRfidSkipped && $parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_SKIPPED' | translate:({ DEVICE_NAME : accRfidDeviceName }) }}</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if=\"!accRfidSkipped && $parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\">{{ 'KEYREADING_STEP2_FLASH_DONE' | translate:({ DEVICE_NAME : accRfidDeviceName }) }}</p>\n" +
    "                        </div>\n" +
    "                        <div ng-if-end>\n" +
    "                            <div class=\"button-container\">\n" +
    "                                <div ng-if=\"!($parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length)\" class=\"flash-progress-border {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"rfidProgressBarGraphStyle\" class=\"flash-progress animate-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage {{rfidPercentage >= 0 && 'keyreading-flash-percentage-active'}}\" ng-style=\"progressBarPercentageRfidStyle\">{{rfidPercentage >= 0 ? rfidPercentage : ''}}</div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageKeyreadingStyle\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100%</div>\n" +
    "                                </div>\n" +
    "                                <button class=\"keyreading-button col-md\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 0 && step.stepOrder - 0 ===  $parent.klonSteps.length\" ng-click=\"$parent.cancelKeyreading()\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 0\">{{'KEYREADING_DONE_BTN' | translate}}</button>\n" +
    "\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper  {{klonError ? 'keyreading-error' : ''}}\" ng-switch-when=\"go-to-vehicle-read-readings\">\n" +
    "                        <p ng-if-start=\"klonError\" class=\"bold\">{{ 'KLON_ERROR_TITLE' | translate}}</p>\n" +
    "                        <p>{{ klonErrorMessage | translate:klonErrorMessageParams}}</p>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <button class=\"keyreading-button col-md\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKlonReadings(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "                        </div>\n" +
    "                        <div ng-if-start=\"!klonError\">\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%204_ALL.gif\" alt=\"Module connect animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\"  ng-if=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-4_ALL.gif\" alt=\"Module connect thumbnail\">\n" +
    "                            <p class=\"sub-step-number {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">1</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{'KEYREADING_VEHICLE_READING_1' | translate}}</p>\n" +
    "                            <p class=\"sub-step-number {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">2</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_VEHICLE_READING_2' | translate}}</p>\n" +
    "                            <p class=\"sub-step-number {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">3</p>\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_VEHICLE_READING_3' | translate}}</p>\n" +
    "                        </div>\n" +
    "                        <div class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if-end>\n" +
    "                            <div class=\"button-container\">\n" +
    "                                <div class=\"flash-progress-border\" ng-if=\"started[step.stepOrder - 0]\">\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"klonProgressBarGraphStyle\" class=\"flash-progress animate-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage {{klonPercentage >= 0 && 'keyreading-flash-percentage-active'}}\" ng-style=\"klonProgressBarPercentageKeyreadingStyle\">{{klonPercentage >= 0 ? klonPercentage : ''}}</div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"klonProgressBarPercentageKeyreadingStyle\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100</div>\n" +
    "                                </div>\n" +
    "                                <button class=\"keyreading-button col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"started[step.stepOrder - 0] || $parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKlonReadings(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper {{klonError ? 'keyreading-error' : ''}}\" ng-switch-when=\"plug-main-module\">\n" +
    "                        <p ng-if-start=\"klonError\" class=\"bold\">{{ 'KLON_ERROR_TITLE' | translate}}</p>\n" +
    "                        <p>{{ klonErrorMessage | translate}}</p>\n" +
    "                        <div ng-if-end class=\"button-container\">\n" +
    "                            <button class=\"keyreading-button col-md\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKlonReadings(step.stepOrder)\">{{ 'KEYREADING_STEP2_RETRY' | translate}}</button>\n" +
    "                        </div>\n" +
    "                        <div ng-if-start=\"!klonError\">\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/GM13_STEP%204c_MODULE%20CONNECT.gif\" alt=\"Module connect animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/GM13_STEP-4c_MODULE-CONNECT.gif\" alt=\"Module connect thumbnail\">\n" +
    "\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{'FLASH_KLON_STEP2_INSTRUCTION_DETAIL_ITEM5' | translate}}</p>\n" +
    "                        </div>\n" +
    "                        <div class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-if-end>\n" +
    "                            <div class=\"button-container\">\n" +
    "                                <div class=\"flash-progress-border\" ng-if=\"started[step.stepOrder - 0]\">\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" ng-style=\"klonProgressBarGraphStyle\" class=\"flash-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"flash-progress\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" ng-style=\"progressBarCompleteGraphStyle\" class=\"flash-progress\"></div>\n" +
    "\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" class=\"keyreading-flash-percentage {{klonPercentage >= 0 && 'keyreading-flash-percentage-active'}}\" ng-style=\"klonProgressBarPercentageKeyreadingStyle\">{{klonPercentage >= 0 ? klonPercentage : ''}}</div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber < step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"klonProgressBarPercentageKeyreadingStyle\"></div>\n" +
    "                                    <div ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\" class=\"keyreading-flash-percentage\" ng-style=\"progressBarPercentageCompleteStyle\">100%</div>\n" +
    "                                </div>\n" +
    "                                <button class=\"keyreading-button col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"started[step.stepOrder - 0] || $parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKlonReadings(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper\" ng-switch-when=\"warning-single-key\">\n" +
    "                        <div>\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%201_GENERIC%20KEY.gif\" alt=\"Single key fob animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-1_GENERIC-KEY.gif\" alt=\"Single key fob thumbnail\">\n" +
    "\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_KEYFOB_WARNING_1' | translate}}</p>\n" +
    "                        </div>\n" +
    "                        <div class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <div class=\"extra-warning\">\n" +
    "                                <span class=\"icon-warning\"></span>\n" +
    "                                <p>{{ 'KEYREADING_KEYFOB_ALERT' | translate}}</p>\n" +
    "                            </div>\n" +
    "                            <div class=\"button-container\">\n" +
    "                                <button class=\"keyreading-button col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"confirmVehicleReadings(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper\" ng-switch-when=\"warning-insert-key\">\n" +
    "                        <div>\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%202_KEY%20INSERT.gif\" alt=\"Key insert animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-2_KEY-INSERT.gif\" alt=\"Key insert thumbnail\">\n" +
    "\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_KEYFOB_WARNING_2' | translate}}</p>\n" +
    "                        </div>\n" +
    "                        <div class=\"button-container {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <button class=\"keyreading-button col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"confirmVehicleReadings(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper\" ng-switch-when=\"warning-key-radius\">\n" +
    "                        <div>\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%203_SAFE%20AREA.gif\" alt=\"Key radius animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-3_SAFE-AREA.gif\" alt=\"Key radius thumbnail\">\n" +
    "\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_KEYFOB_WARNING_3' | translate}}</p>\n" +
    "                        </div>\n" +
    "                        <div class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <div class=\"extra-warning\">\n" +
    "                                <span class=\"icon-warning\"></span>\n" +
    "                                <p>{{ 'KEYREADING_KEYFOB_ALERT' | translate}}</p>\n" +
    "                            </div>\n" +
    "                            <div class=\"button-container\">\n" +
    "                                <button class=\"keyreading-button col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startKeyReading(); confirmVehicleReadings(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper\" ng-switch-when=\"warning-acc-rfid\">\n" +
    "                        <div>\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-7_RFID-CONNECT.gif\" alt=\"rfid connect thumbnail\">\n" +
    "                            <img ng-show=\" $parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%207_RFID%20CONNECT.gif\" alt=\"rfid connect animation\">\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_RFID_MESSAGE' | translate:({ DEVICE_NAME : mainDevice.productName }) }}</p>\n" +
    "\n" +
    "                        </div>\n" +
    "                        <div class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <div class=\"extra-warning\">\n" +
    "                                <span class=\"icon-warning\"></span>\n" +
    "                                <p>{{ 'KEYREADING_RFID_ALERT' | translate}}</p>\n" +
    "                            </div>\n" +
    "                            <div class=\"button-container\">\n" +
    "                                <button class=\"keyreading-button col-md\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"startAccRfidFlash()\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                                <button class=\"skip-step-button\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"skipAccRfid(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_RFID_SKIP' | translate}}</button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper\" ng-switch-when=\"remove-key\">\n" +
    "                        <div>\n" +
    "                            <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                                <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                                <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                            </div>\n" +
    "                            <img ng-show=\"$parent.mostRecentCompletedStep.stepNumber === step.stepOrder - 1\" src=\"videos/STEP%205_KEY%20REMOVAL.gif\" alt=\"Key removal animation\">\n" +
    "                            <img class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\" ng-show=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" src=\"videos/thumbnails/STEP-2_KEY-INSERT.gif\" alt=\"Key removal thumbnail\">\n" +
    "\n" +
    "                            <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_COMPLETE' | translate}}</p>\n" +
    "                        </div>\n" +
    "                        <div class=\"button-container {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <button class=\"keyreading-button col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"confirmVehicleReadings(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"keyreading-content-element-wrapper\" ng-switch-default>\n" +
    "                        <div class=\"keyreading-step-header {{step.stepOrder > $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <div class=\"keyreading-step-counter\" >{{step.stepOrder + '/' + $parent.klonSteps.length}} </div>\n" +
    "                            <span class=\"icon-check-circle\" ng-if=\"$parent.mostRecentCompletedStep.stepNumber > step.stepOrder - 1\"></span>\n" +
    "                        </div>\n" +
    "                        <p class=\"{{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">{{ 'KEYREADING_STEP1_MESSAGE' | translate}}</p>\n" +
    "                        <div class=\"button-container {{step.stepOrder !== $parent.mostRecentCompletedStep.stepNumber + 1 ? 'disabled' : ''}}\">\n" +
    "                            <span class=\"icon-check-circle\" ng-if=\"started[step.stepOrder - 0]\"></span>\n" +
    "                            <button class=\"small-btn col-md\" ng-if=\"!started[step.stepOrder - 0]\" ng-disabled=\"$parent.mostRecentCompletedStep.stepNumber !== step.stepOrder - 1\" ng-click=\"confirmVehicleReadings(step.stepOrder); navProgress(step.stepOrder)\">{{ 'KEYREADING_CONTINUE_BTN' | translate}}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <ul class=\"nav-dots\">\n" +
    "                <li ng-repeat=\"step in $parent.klonSteps track by $index\" ng-if=\"!($index % 5) || $first\" >\n" +
    "                    <div class=\"{{$index === selectedNavButton ? 'nav-dot selected-dot' : 'nav-dot'}}\" ng-click=\"clickNavButton($index)\"></div>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "            <div class=\"button-container keyreading-footer-buttons\">\n" +
    "                <button class=\"small-btn col-md error-btn\" ng-disabled=\"started[$parent.mostRecentCompletedStep.stepNumber+1] && !keyreadingError && !flashError && !klonError && !showAccRfidError\" ng-click=\"$parent.cancelKeyreading()\">{{ 'KEYREADING_CANCEL_BTN' | translate}}</button>\n" +
    "                <a ng-if=\"$parent.responseData.deviceGuides[0]\" class=\"button small-btn col-md guide-button\"  ng-href=\"{{$parent.responseData.deviceGuides[0].link}}\" target=\"_blank\">{{ 'KEYREADING_INSTALL_GUIDE' | translate}}</a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"progress-view\" id=\"progress-view\" ng-hide=\"!flashing || keyreadingFlash\">\n" +
    "        <error-notification-bar \n" +
    "            class=\"notification-bar\"\n" +
    "            ng-if=\"showErrorNotificationBar\" title=\"{{  errorTitle   | translate}}\"\n" +
    "            message=\"{{ errorMessage| translate}}\" event=\"event()\"\n" +
    "            button=\"{{ 'FLASH_ANTENNA_RETRY_BUTTON' | translate }}\" ng-cloak>\n" +
    "        </error-notification-bar>\n" +
    "        <div>\n" +
    "            <h1 class=\"main-header white-header flash-text\">\n" +
    "                <span ng-hide=\"!showUpdateMessage\">\n" +
    "                    <span\n" +
    "                        data-ng-show=\"showProgressMessage\">{{ 'FLASH_PROGRESS_TITLE' | translate:({ 'ACTUAL_STEP_NUM':flashnumber, 'TOTAL_STEPS':totalflash }) }}</span>\n" +
    "                    <span data-ng-hide=\"showProgressMessage\">\n" +
    "                        {{ !$parent.flashForFirmwareUpdateOnly ? progress.message : 'FLASH_PROGRESS_TITLE_FIRMWAREUPDATE' | translate }}</span>\n" +
    "                </span>\n" +
    "                <span\n" +
    "                    data-ng-hide=\"showUpdateMessage\">{{'FLASH_PROGRESS_TITLE_FIRMWARE_UPDATE_EMBEDDED_DEVICE' | translate:({ 'ACTUAL_STEP_NUM':flashnumber, 'TOTAL_STEPS':totalflash })  }}</span>\n" +
    "            </h1>\n" +
    "            <div class=\"flash-description\">{{ 'FLASH_PROGRESS_PLEASE_WAIT_MESSAGE' | translate }}</div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "            <div ng-class=\"!progressBarActive ? '' : 'inactive'\" class=\"progress-bar-container container text-left\">\n" +
    "                <span ng-style=\"progressBarPercentageStyle\"\n" +
    "                    class=\"progress-bar-percentage\">{{ percentage > 0 ? percentage : 0 }} %</span>\n" +
    "                <div class=\"progress-bar-borders\">\n" +
    "                    <div ng-style=\"progressBarGraphStyle\" class=\"progress-bar-graph\"></div>\n" +
    "                    <!--<div class=\"progress-bar-arrow\" id=\"progress-bar-arrow\"></div>-->\n" +
    "                </div>\n" +
    "\n" +
    "                <br />\n" +
    "                <div class=\"text-center\" ng-hide=\"!$parent.flashForFirmwareUpdateOnly\">\n" +
    "                    <div ng-hide=\"isGlobalLoading\">\n" +
    "                        <button class=\"white-btn\" type=\"button\" ng-hide=\"firmwareUpdateFlashing\"\n" +
    "                            ng-click=\"firmwareUpdateRetry()\">{{ 'FLASH_FIRMWAREUPDATE_BTN_RETRYFLASH' | translate }}</button>\n" +
    "                        <button class=\"white-btn\" type=\"button\" ng-hide=\"firmwareUpdateFlashing\"\n" +
    "                            ng-click=\"quitFirmwareUpdate()\">{{ 'FLASH_FIRMWAREUPDATE_BTN_QUIT' | translate }}</button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"text-center\"  ng-hide=\"!$parent.configurationParameters.flashByFirmware\">\n" +
    "                    <div ng-hide=\"isGlobalLoading\">\n" +
    "                        <button class=\"white-btn\" type=\"button\" ng-hide=\"firmwareUpdateFlashing\"\n" +
    "                                ng-click=\"flashByFirmwareRetry()\">{{ 'FLASH_FIRMWAREUPDATE_BTN_RETRYFLASH' | translate }}</button>\n" +
    "                        <button class=\"white-btn\" type=\"button\" ng-hide=\"firmwareUpdateFlashing\"\n" +
    "                                ng-click=\"quitFirmwareUpdate()\">{{ 'FLASH_FIRMWAREUPDATE_BTN_QUIT' | translate }}</button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"text-center\" ng-hide=\"!$parent.flashForFlexUpgradeOnly\">\n" +
    "                    <button class=\"white-btn\" type=\"button\" ng-show=\"showQuitProcessBtn\"\n" +
    "                        ng-click=\"quitFirmwareUpdate()\">{{ 'FLASH_FIRMWAREUPDATE_BTN_QUIT' | translate }}</button>\n" +
    "                </div>\n" +
    "\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"container\">\n" +
    "            <div class=\"feedback-container\">\n" +
    "                <p>{{ 'FLASH_PROGRESS_TIP_MESSAGE' | translate}}</p>\n" +
    "                <p class=\"text-center\" data-ng-hide=\"true\">\n" +
    "                    <input type=\"radio\" name=\"feedback\" value=\"1\" id=\"feedback1\"><label\n" +
    "                        for=\"feedback1\">{{ 'FLASH_PROGRESS_INPUT_YES' | translate}}</label></input>\n" +
    "                    <input type=\"radio\" name=\"feedback\" value=\"0\" id=\"feedback0\"><label\n" +
    "                        for=\"feedback0\">{{ 'FLASH_PROGRESS_INPUT_NO' | translate}}</label></input>\n" +
    "                </p>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"flash-step2-klon\" ng-hide=\"!showKlonStep\">\n" +
    "        <div class=\"container-hack-relative\">\n" +
    "            <div class=\"container-hack-absolute\">\n" +
    "                <div class=\"top-flash-part\">\n" +
    "                    <h1 class=\"main-header white-header\">{{ 'FLASH_KLON_STEP2_INSTRUCTION_TITLE' | translate }}</h1>\n" +
    "                    <div class=\"flash-description\">{{ 'FLASH_KLON_STEP2_INSTRUCTION_SUBTITLE' | translate }}</div>\n" +
    "                    <div class=\"row directives text-center\">\n" +
    "                        <div class=\"dire-box\"><img class=\"con-img\" ng-src=\"img/klon/connect.png\"\n" +
    "                                alt=\"Connect\" /><i class=\"fa fa-angle-right fa-3x\"></i><br />\n" +
    "                            <div class=\"text-directives\">{{ 'FLASH_KLON_STEP2_INSTRUCTION_SUMMARY_1' | translate }}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"dire-box\"><img ng-src=\"img/klon/vehicle_klon.png\" alt=\"Car\" /><i\n" +
    "                                class=\"fa fa-angle-right fa-3x\"></i> <br />\n" +
    "                            <div class=\"text-directives\">{{ 'FLASH_KLON_STEP2_INSTRUCTION_SUMMARY_2' | translate }}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"dire-box\"><img class=\"rec-img\" ng-src=\"img/klon/reconnect.png\"\n" +
    "                                alt=\"Reconnect\" /><br />\n" +
    "                            <div class=\"text-directives\">{{ 'FLASH_KLON_STEP2_INSTRUCTION_SUMMARY_3' | translate }}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"buttons text-center\">\n" +
    "                        <div class=\"klon-logo\">\n" +
    "                            <img src=\"img/klon/logo/klon-logo-white-94x21.png\" />\n" +
    "                            <span class=\"patent-no\">{{ 'FLASH_PATENT_NO' | translate }}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"button-container\">\n" +
    "                            <button ng-disabled=\"isGlobalLoading\" class=\"black-btn large-btn uiTest-Klon-btn\"\n" +
    "                                type=\"button\"\n" +
    "                                ng-click=\"getSuspendedRequest()\">{{ 'FLASH_KLON_STEP2_BUTTON_CONTINUE' | translate }}</button>\n" +
    "                        </div>\n" +
    "                        <div>\n" +
    "                            <button class=\"white-btn\" type=\"button\" ng-click=\"gotoElement('bottom2')\"\n" +
    "                                ng-show=\"installGuide\">{{ 'FLASH_KLON_STEP2_BUTTON_DIRECTIVES' | translate }}</button>\n" +
    "                            <button class=\"white-btn\" ng-disabled=\"isGlobalLoading\" type=\"button\"\n" +
    "                                ng-click=\"quitKlonProcess()\">{{ 'FLASH_KLON_STEP2_BUTTON_QUIT' | translate }}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"bottom-advice-part text-center\">\n" +
    "                    <div class=\"col-xs-6\" ng-show=\"hasFirmwarePost\">\n" +
    "                        <div ng-click=\"gotoElement('bottom2')\"></div>\n" +
    "                        <h2 class=\"main-header as-h1\">{{ 'FLASH_FIRMWAREPOSTS_TITLE' | translate }}</h2>\n" +
    "                        <p>{{ 'FLASH_FIRMWAREPOSTS_DESC' | translate }}</p>\n" +
    "                        <div class=\"col-xs-offset-1 col-xs-10 fw-post-content\">\n" +
    "                            <firmware-posts posts=\"$parent.responseData.firmwarePosts\"></firmware-posts>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"{{ !hasFirmwarePost?'col-xs-offset-2 col-xs-8':'col-xs-6' }} text-left\">\n" +
    "                        <h2 class=\"main-header as-h1\">{{ 'FLASH_KLON_STEP2_INSTRUCTION_DETAIL_TITLE' | translate }}</h2>\n" +
    "                        <div class=\"col-xs-12 instruction-content\">\n" +
    "                            <p class=\"header\">{{ 'FLASH_KLON_STEP2_INSTRUCTION_DETAIL_HEADER' | translate }}</p>\n" +
    "                            <p ng-repeat=\"n in [1,2,3,4,5]\"><i class=\"fa fa-check\"></i>\n" +
    "                                {{ 'FLASH_KLON_STEP2_INSTRUCTION_DETAIL_ITEM'+n | translate }}</p>\n" +
    "                            <p>{{ 'FLASH_KLON_STEP2_INSTRUCTION_DETAIL_NOTE' | translate }}</p>\n" +
    "\n" +
    "                            <div class=\"guide-download\" ng-show=\"installGuide\">\n" +
    "                                <a href=\"{{ installGuide.link }}\" target=\"_blank\" class=\"guide-item\">\n" +
    "                                    <div>\n" +
    "                                        <div class=\"guide-name\">{{installGuide.name}}{{installGuide.type | translate}}\n" +
    "                                        </div>\n" +
    "                                        <p>{{installGuide.filename}}<br />{{installGuide.version}}</p>\n" +
    "                                    </div>\n" +
    "                                </a>\n" +
    "                                <a href=\"#\" ng-click=\"clickRsuGuide()\" class=\"guide-item\" ng-if=\"hasRsuGuide.showExtraGuide\">\n" +
    "                                    <div>\n" +
    "                                        <div class=\"guide-name rsu-guide-button\">{{'RSU_GUIDE_BUTTON' | translate:({BRAND: hasRsuGuide.extraGuideName})}}</div>\n" +
    "                                    </div>\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div id=\"bottom2\"></div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"flash-step2-klon\" ng-hide=\"!flashingStep2\">\n" +
    "        <div class=\"container-hack-relative\">\n" +
    "            <div class=\"container-hack-absolute\">\n" +
    "                <div class=\"top-flash-part\">\n" +
    "                    <div>\n" +
    "                        <h1 class=\"main-header white-header\">{{ 'FLASH_KLON_STEP2_PROGRESS_TITLE' | translate }}</h1>\n" +
    "                        <div class=\"flash-description\" ng-hide=\"waitTimeInSeconds==0\">\n" +
    "                            {{ waitTimeInSecondsString = (waitTimeInSeconds | time:'ss':waitTimeFormat: false); 'FLASH_KLON_STEP2_PROGRESS_TIME_REMAINING' | translate:({ WAIT_TIME : waitTimeInSecondsString }) }}\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"container\">\n" +
    "                        <div ng-class=\"!klonProgressBarActive ? '' : 'inactive'\"\n" +
    "                            class=\"progress-bar-container container text-left\">\n" +
    "                            <span ng-style=\"progressBarPercentageKlonStyle\"\n" +
    "                                class=\"progress-bar-percentage\">{{ percentage > 0 ? percentage : 0 }} %</span>\n" +
    "                            <div class=\"progress-bar-borders\">\n" +
    "                                <div ng-style=\"progressBarGraphKlonStyle\" class=\"progress-bar-graph\"></div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <br />\n" +
    "                        <div class=\"text-center klon-logo\">\n" +
    "                            <img src=\"img/klon/logo/klon-logo-white-94x21.png\" />\n" +
    "                            <span class=\"patent-no\">{{ 'FLASH_PATENT_NO' | translate }}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"text-center\"><button class=\"white-btn\" ng-disabled=\"isGlobalLoading\" type=\"button\"\n" +
    "                                ng-click=\"finishLaterKlon()\"\n" +
    "                                ng-hide=\"!showQuitFinishLater || waitTimeInSeconds < 30\">{{ 'FLASH_QUIT_AND_FINISH_LATER' | translate }}</button>\n" +
    "                        </div>\n" +
    "                        <div class=\"container\">\n" +
    "                            <button class=\"white-btn\" ng-disabled=\"isGlobalLoading\" type=\"button\"\n" +
    "                                ng-click=\"klonDownload()\"\n" +
    "                                ng-hide=\"!flashExtProgError\">{{'FLASH_KLON_STEP2_BUTTON_RETRY' | translate}}</button>\n" +
    "                            <button class=\"white-btn\" ng-disabled=\"isGlobalLoading\" type=\"button\"\n" +
    "                                ng-click=\"finishLaterKlon()\"\n" +
    "                                ng-hide=\"!flashExtProgError\">{{'FLASH_KLON_STEP2_BUTTON_QUIT' | translate}}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"container\">\n" +
    "                        <div class=\"feedback-container\" data-ng-hide=\"true\">\n" +
    "                            <p>{{ 'FLASH_PROGRESS_TIP_MESSAGE' | translate}}</p>\n" +
    "                            <p class=\"text-center\">\n" +
    "                                <input type=\"radio\" name=\"feedback\" value=\"1\" id=\"feedbackTrue\"><label\n" +
    "                                    for=\"feedbackTrue\">{{ 'FLASH_PROGRESS_INPUT_YES' | translate}}</label></input>\n" +
    "                                <input type=\"radio\" name=\"feedback\" value=\"0\" id=\"feedbackFalse\"><label\n" +
    "                                    for=\"feedbackFalse\">{{ 'FLASH_PROGRESS_INPUT_NO' | translate}}</label></input>\n" +
    "                            </p>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"bottom-advice-part\">\n" +
    "                    <h2 class=\"as-h1 main-header\">{{ 'FLASH_KLON_STEP2_PROGRESS_LEAVE_TITLE' | translate }}</h2>\n" +
    "                    <p><i class=\"fa fa-check\"></i> {{ 'FLASH_KLON_STEP2_PROGRESS_LEAVE_TEXT' | translate }}</p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"progress-view flash-antenna\" id=\"antenna-view\" ng-hide=\"!showAntennaStep\">\n" +
    "        <error-notification-bar ng-show=\"showErrorAntennaNotificationBar\" title=\"{{ errorMessage | translate}}\"\n" +
    "            message=\"{{ 'FLASH_ANTENNA_ERROR_DESCRIPTION' | translate}}\" event=\"reDetectAntenna()\"\n" +
    "            button=\"{{ 'FLASH_ANTENNA_RETRY_BUTTON' | translate }}\">\n" +
    "        </error-notification-bar>\n" +
    "        <div class=\"container antenna-summary-container\">\n" +
    "            <div class=\"row header-container text-center\">\n" +
    "                <span class=\" col-xs-2 icon-container\">\n" +
    "                    <img ng-src=\"img/flash/eclair.png\">\n" +
    "                </span>\n" +
    "                <h3 class=\"col-xs-10 header-title\">\n" +
    "                    {{ \"FLASH_CONNECT_ANTENNA\" | translate}}\n" +
    "                </h3>\n" +
    "            </div>\n" +
    "            <div class=\"row antenna-flash-details-container\">\n" +
    "                <div class=\"col-xs-offset-2 col-xs-2\">\n" +
    "                    <img src=\"img/flash/antenna.png\">\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-6\">\n" +
    "                    <div class=\"row list-container\">\n" +
    "                        <ul> {{ \"FLASH_ANTENNA_INSTRUCTION_LIST_TITLE\" | translate}}\n" +
    "                            <li> {{ \"FLASH_ANTENNA_INSTRUCTION_LIST_FIRST_ITEM\" | translate}} </li>\n" +
    "                            <li> {{ \"FLASH_ANTENNA_INSTRUCTION_LIST_SECOND_ITEM\" | translate}} </li>\n" +
    "                        </ul>\n" +
    "                    </div>\n" +
    "                    <div class=\"row\">\n" +
    "                        <h3>\n" +
    "                            {{ \"FLASH_ANTENNA_NOTE\" | translate}}\n" +
    "                        </h3>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"container\">\n" +
    "            <div class=\"button-container\">\n" +
    "                <button ng-disabled=\"isGlobalLoading\" class=\"black-btn large-btn uiTest-Klon-btn\" type=\"button\"\n" +
    "                    ng-click=\"updateAntenna()\">{{ 'FLASH_UPDATE_ANTENNA' | translate }}</button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"initial-summary\" id=\"summary\" ng-class=\"$parent.showDeviceNotificationBar ? 'notification-bar-open' : ''\" >\n" +
    "        <div class=\"text-center\">\n" +
    "            <div class=\"showVariable show0\">\n" +
    "                <div class=\"nav-ctn\">\n" +
    "                    <page-navigation hidebackbtn=\"$parent.configurationParameters.flashFromFavorite\" hidenextbtn=\"true\" backskipstep=\"guides\" nextskipstep=\"\">\n" +
    "                    </page-navigation>\n" +
    "                    <h1 class=\"main-header\">{{ 'FLASH_TITLE'  | translate }}</h1>\n" +
    "                </div>\n" +
    "                <div class=\"summary-description\">{{ 'FLASH_MAIN_SUBTITLE' | translate }}</div>\n" +
    "            </div>\n" +
    "            <div class=\"showVariable show1\">\n" +
    "                <div class=\"nav-ctn\">\n" +
    "                    <page-navigation hidebackbtn=\"$parent.configurationParameters.flashFromFavorite\" hidenextbtn=\"true\" backskipstep=\"guides\" nextskipstep=\"\">\n" +
    "                    </page-navigation>\n" +
    "                    <h2 class=\"main-header as-h1\">{{ 'FLASH_ERROR_TITLE'  | translate }}</h2>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"showVariable show2\">\n" +
    "                <h1 class=\"main-header\">\n" +
    "                    <div class=\"icon-status icon-status-success\"></div>{{ 'FLASH_SUCCESS_TITLE'  | translate }}\n" +
    "                </h1>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"generic-device-box\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-xs-12 col-sm-4 device-section border-right\">\n" +
    "                    <div class=\"heading-container\">\n" +
    "                        <h5>{{ 'DETECTION_MAIN_DEVICE_TITLE' | translate }}</h5>\n" +
    "                    </div>\n" +
    "                    <div class=\"image-container\">\n" +
    "                        <img class=\"product-img\" ng-src=\"{{mainDevice.mediumImage}}\"\n" +
    "                            err-src=\"img/products/generic.png\" />\n" +
    "                        <div class=\"product-name\">{{mainDevice.deviceFunction}}</div>\n" +
    "                    </div>\n" +
    "                    <div class=\"device-container\">\n" +
    "                        <div class=\"device-name\">{{mainDevice.partName}}</div>\n" +
    "                        <div class=\"device-details\" ng-hide=\"mainDevice.partName === mainDevice.productName\">\n" +
    "                            <span class=\"config-title\">{{ 'DETECTION_DEVICE_PRODUCT' | translate }}</span>\n" +
    "                            <span>{{ mainDevice.productName }}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"device-details\">\n" +
    "                            <span class=\"config-title\">{{ 'DETECTION_DEVICE_SERIAL' | translate }} </span>\n" +
    "                            <span>{{mainDevice.trackingM && mainDevice.trackingM + ' - '}}{{ mainDevice.serial}}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"device-details\">\n" +
    "                            <span class=\"config-title\">{{ 'DETECTION_DEVICE_FIRMWARE' | translate }}</span>\n" +
    "                            <span>{{ userSelection.selectedFirmware.name }}\n" +
    "                                v{{userSelection.selectedFirmware.version}}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"device-details\" ng-show=\"userSelection.selectedProtocol.usedAsAddOn || userSelection.selectedProtocol.isStandalone\">\n" +
    "                            <span class=\"config-title\">{{ 'DETECTION_DEVICE_INSTALL_OPTION' | translate }}</span>\n" +
    "                            <br />\n" +
    "                            <span>{{ userSelection.selectedProtocol.usedAsAddOn ? 'INSTALLOPT_CHOICE_ADDON_INSTALLED_AS' : 'INSTALLOPT_CHOICE_STANDALONE_INSTALLED_AS' | translate }}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"logo-container\" ng-show=\"responseData.currentFirmware.isKeyCloning\">\n" +
    "                            <img src=\"img/klon/logo/klon-logo-black-70x16.png\" />\n" +
    "                            <div class=\"patent-no\">{{ 'FLASH_PATENT_NO' | translate }}</div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"button-container top\">\n" +
    "                        <div class=\"showVariable show0 show1\">\n" +
    "                            <button class=\"small-btn\" type=\"button\"\n" +
    "                                ng-click='detect()'>{{ 'FLASH_REDETECT_BUTTON' | translate }}</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-12 col-sm-4 device-section border-right\">\n" +
    "                    <div class=\"heading-container\">\n" +
    "                        <h5>{{ 'MENU_SIDEBAR_VEHICLE' | translate }}</h5>\n" +
    "                    </div>\n" +
    "                    <div class=\"image-container\">\n" +
    "                        <img ng-src=\"{{selectedVehicle.modelImage}}\"\n" +
    "                            err-src=\"img/generic/generic-vehicle.png\"\n" +
    "                            alt=\"no-vehicle-img\"/>\n" +
    "                    </div>\n" +
    "                    <div class=\"device-container\">\n" +
    "                        <div class=\"device-name\"> {{selectedVehicle.makeName}} {{selectedVehicle.yearName}} {{selectedVehicle.modelName}} </div>\n" +
    "                        <div class=\"vehicle-details no-margin\"> {{selectedVehicle.vehicleName}}\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"showVariable show0 show1 button-container\">\n" +
    "                        <button class=\"small-btn\" type=\"button\"\n" +
    "                            ng-click=\"skipstep('vehicle.makes')\" ng-hide=\"configurationParameters.flashFromFavorite\">{{ 'FLASH_MODIFY_BUTTON' | translate }}</button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"col-xs-12 col-sm-4 device-section\">\n" +
    "                    <div class=\"heading-container\">\n" +
    "                        <h5>{{'DETECTION_CONTROLS_TITLE' | translate}}</h5>\n" +
    "                        <div class=\"sub-title\">{{'DETECTION_REMOTES_TELEMATICS_TITLE' | translate}}</div>\n" +
    "                    </div>\n" +
    "                    <div class=\"not-available-text\" ng-show=\"hideControlsCard\">\n" +
    "                        {{ 'DETECTION_DEVICE_NOT_AVAILABLE' | translate }}\n" +
    "                    </div>\n" +
    "                    <div class=\"carousel\" ng-init=\"initControlsBox()\">\n" +
    "                        <div class=\"device-container carousel\" ng-if=\"controlsObject.array.length > 1 && !hideControlsCard\">\n" +
    "                            <ul rn-carousel rn-carousel-indicator=\"true\" rn-carousel-index=\"carouselIndex\" class=\"large-height\" >\n" +
    "                                <li ng-repeat=\"controlItem in controlsObject.array\">\n" +
    "                                    <control-preview  skipstep='$parent.skipstep()' control=\"controlItem\" flash-from-favorite=\"configurationParameters.flashFromFavorite\"/>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                            <div class=\"small-margin\" rn-carousel-indicators ng-if=\"controlsObject.array.length > 1\" slides=\"controlsObject.slides\" rn-carousel-index=\"carouselIndex\"></div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div>\n" +
    "                <div class=\"showVariable show0 button-container top-margin\">\n" +
    "                    <button ng-disabled=\"disableFlashButton\" class=\"large-btn black-btn\"\n" +
    "                        type=\"button\"\n" +
    "                        ng-click=\"hasFirmwarePost ? openWarningModal() : flashClick()\">{{ 'FLASH_BUTTON_START_FLASH_DEVICE' | translate }}</button>\n" +
    "                    <button ng-disabled=\"disableFlashButton\" class=\"large-btn favorites-button\" type=\"button\" ng-hide=\"$parent.configurationParameters.flashFromFavorite || !isFavoriteActive\"\n" +
    "                            ng-click='saveFavorite()'>{{'FAVORITE_SAVE_AS' | translate}}</button>\n" +
    "                </div>\n" +
    "                <div class=\"showVariable show1 button-container top-margin\">\n" +
    "                    <button ng-disabled=\"disableFlashButton\" class=\"large-btn black-btn uiTest-flash-retry\"\n" +
    "                        type=\"button\"\n" +
    "                        ng-click=\"hasFirmwarePost ? openWarningModal() : flashRestart()\">{{ 'FLASH_ERROR_RETRY' | translate }}</button><br />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"initial-summary initial-summary-hack\">\n" +
    "        <div class=\"button-container showVariable show2\">\n" +
    "            <button class=\"large-btn\" type=\"button\"\n" +
    "                ng-click=\"refreshPage()\">{{ 'DETECTION_USER_START_CONFIG' | translate }}</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"blue-bottom row\">\n" +
    "        <div class=\"col-xs-12 col-sm-6\" ng-show=\"hasFirmwarePost\">\n" +
    "            <h1 class=\"main-header white-header\">{{ 'FLASH_FIRMWAREPOSTS_TITLE' | translate }}</h1>\n" +
    "            <p>{{ 'FLASH_FIRMWAREPOSTS_DESC' | translate }}</p>\n" +
    "            <div class=\"col-sm-offset-1 col-xs-12 col-sm-10 styled-container\">\n" +
    "                <firmware-posts posts=\"$parent.responseData.firmwarePosts\" theme=\"white\"></firmware-posts>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"{{ !hasFirmwarePost?'col-sm-offset-1 col-sm-10 col-xs-12': 'col-xs-12 col-sm-6' }}\">\n" +
    "            <h1 class=\"main-header white-header\">{{ 'FLASH_INSTALLATION_GUIDES' | translate }}</h1>\n" +
    "            <p>{{ 'GUIDES_DESCRIPTION' | translate:({ 'PART_NAME': detectedDevice.partName }) }}</p>\n" +
    "            <div class=\"guides-list styled-container\">\n" +
    "                <a href=\"{{dg.link}}\" target=\"_blank\" ng-repeat=\"dg in $parent.responseData.deviceGuides\"\n" +
    "                    class=\"guide-item newer-version uiTest-Guides-new\" ng-if=\"dg.isNewVersion == 1\">\n" +
    "                    <div>\n" +
    "                        <div class=\"new-version-block\">N</div>\n" +
    "                        <div class=\"guide-name\">{{dg.name}}{{dg.type | translate}}</div>\n" +
    "                        <p>{{dg.filename}}<br />{{dg.version}}</p>\n" +
    "                    </div>\n" +
    "                </a>\n" +
    "                <a href=\"{{dg.link}}\" target=\"_blank\" ng-repeat=\"dg in $parent.responseData.deviceGuides\"\n" +
    "                    class=\"guide-item uiTest-Guides text-left\" ng-if=\"dg.isNewVersion != 1\">\n" +
    "                    <div>\n" +
    "                        <div class=\"guide-name\">{{dg.name}}{{dg.type | translate}}</div>\n" +
    "                        <p>{{dg.filename}}<br />{{dg.version}}</p>\n" +
    "                    </div>\n" +
    "                </a>\n" +
    "                <a href=\"#\" ng-click=\"clickRsuGuide()\" class=\"guide-item uiTest-Guides text-left\" ng-if=\"hasRsuGuide.showExtraGuide\">\n" +
    "                    <div>\n" +
    "                        <div class=\"guide-name rsu-guide-button\">{{'RSU_GUIDE_BUTTON' | translate:({BRAND: hasRsuGuide.extraGuideName})}}</div>\n" +
    "                    </div>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"clearfix\" id=\"bottom\"></div>\n" +
    "    </div>\n" +
    "\n" +
    "    <script type=\"text/ng-template\" id=\"warningModal\">\n" +
    "        <div class=\"modal-heading warning\">{{ 'FLASH_FIRMWAREPOSTS_TITLE' | translate }}</div>\n" +
    "        <div class=\"modal-sub-heading bottom-margin\">{{ 'FLASH_FIRMWAREPOSTS_DESC' | translate }}</div>\n" +
    "        <firmware-posts posts=\"$parent.responseData.firmwarePosts\" theme=\"modal\"></firmware-posts>\n" +
    "        <div class=\"button-container\">\n" +
    "        <button class=\"large-btn\" type=\"button\" ng-click=\"activePageview == 1 ? flashRestart() : flashClick()\" >{{ 'FLASH_BUTTON_START_FLASH_DEVICE' | translate }}</button>\n" +
    "        </div>\n" +
    "    </script>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/guides/directives/rsuGuide.modal.client.directive.view.html',
    "<div>\n" +
    "    <div class=\"ngdialog-header\" ng-if=\"vehicles.length > 0\">\n" +
    "        <h3 class=\"text-center rsu-guide-title\">{{ 'RSU_GUIDE_SELECTION_MODAL_TITLE' | translate:({BRAND: rsuBrandName})}}</h3>\n" +
    "        <br />\n" +
    "        <p>{{ 'RSU_GUIDE_SELECTION_MODAL_SUBTITLE' | translate}}</p>\n" +
    "        <p>{{ 'RSU_GUIDE_SELECTION_MODAL_WARNING' | translate}}</p>\n" +
    "    </div>\n" +
    "    <div class=\"ngdialog-header\" ng-if=\"vehicles.length === 0\">\n" +
    "        <h3 class=\"text-center rsu-guide-title\">{{ 'RSU_GUIDE_SELECTION_MODAL_TITLE' | translate:({BRAND: rsuBrandName})}}</h3>\n" +
    "        <br />\n" +
    "        <p ng-if=\"websiteUrl\">{{ 'RSU_GUIDE_UNAVAILABLE_MODAL_SUBTITLE' | translate:({BRAND: rsuBrandName})}}<a href=\"#\" ng-click=\"openUrl(websiteUrl)\">{{websiteUrl}}</a></p>\n" +
    "    </div>\n" +
    "    <div class=\"generic-config-box\">\n" +
    "        <div class=\"list-container\" ng-if=\"vehicles.length > 0\">\n" +
    "            <div ng-repeat=\"vehicle in vehicles\" class=\"list-item xs\" ng-click=\"openUrl(vehicle.url)\">{{vehicle.name}}</div>\n" +
    "        </div>\n" +
    "        <div class=\"text-center button-container\">\n" +
    "            <button type=\"button\" class=\"modal__btn cancel\" ng-click=closeThisDialog()>{{ 'KEYREADING_CANCEL_BTN' | translate }}</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/guides/guides.client.view.html',
    "<section data-ng-controller=\"PartialsGuidesGuidesController\" ng-init=\"initGuides()\" class=\"guides-container main-content-topbar-offset\">\n" +
    "\n" +
    "\n" +
    "    <div class=\"container text-center\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation ng-hide=\"isGuidesMenuUnlocked\" hidebackbtn=\"false\" hidenextbtn=\"false\" backskipstep=\"features\" nextskipstep=\"flash-from-guides\" ></page-navigation>\n" +
    "            <p>{{ 'GUIDES_WIRING_HEADER_GUIDES_TAB_TITLE' | translate }}</p>\n" +
    "            <h1 class=\"text-center\">{{ 'GUIDES_TITLE' | translate }}</h1>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"container text-center\">\n" +
    "        <h1 class=\"main-header\">{{ 'GUIDES_SUBTITLE' | translate }}</h1>\n" +
    "        <p>{{ 'GUIDES_DESCRIPTION' | translate:({ 'PART_NAME': detectedDevice.partName })}}</p>\n" +
    "    </div>\n" +
    "    <div class=\"guides-list text-center container\" >\n" +
    "        <a href=\"{{dg.link}}\" target=\"_blank\" ng-repeat=\"dg in responseData.deviceGuides\"\n" +
    "           class=\"guide-item newer-version uiTest-Guides-new\" ng-if=\"dg.isNewVersion == 1\">\n" +
    "            <div class=\"new-version-block\">N</div>\n" +
    "            <div class=\"guide-name\">{{dg.name}}{{dg.type | translate}}</div>\n" +
    "            <p>{{dg.filename}}<br />\n" +
    "                {{dg.version}}</p>\n" +
    "        </a>\n" +
    "        <a href=\"{{dg.link}}\" target=\"_blank\" ng-repeat=\"dg in responseData.deviceGuides\"\n" +
    "           class=\"guide-item uiTest-Guides\"\n" +
    "           ng-if=\"dg.isNewVersion != 1\">\n" +
    "            <div class=\"guide-name\">{{dg.name}}{{dg.type | translate}}</div>\n" +
    "            <p>{{dg.filename}}<br />\n" +
    "                {{dg.version}}</p>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"container video-container text-center\" ng-hide=\"videos.length === 0\">\n" +
    "        <h2>Videos</h2>\n" +
    "        <videos-section videos=\"videos\"></videos-section>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"clearfix\"></div>\n" +
    "    <div class=\"button-container\" data-ng-hide=\"{{ isGuidesMenuUnlocked || skipWiring }}\">\n" +
    "        <button class=\"large-btn uiTest-GoToFlash-btn\" ng-click=\"goToFlash()\">{{ 'GUIDES_BUTTON_GO_TO' | translate }}</button>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/guides/guidesWires.client.view.html',
    "<section data-ng-controller=\"PartialsGuidesGuidesController\" ng-init=\"initGuides()\" class=\"main-content-sidebar-offset\">\n" +
    "    <!-- <div ng-show=\"hideWiringTable\" class=\"header-container text-center\">\n" +
    "        <div class=\"page-navigation\">\n" +
    "             <div class=\"navi-left\" data-ng-click=\"quitGalleryMode()\"><i class=\"fa fa-angle-left fa-4x\"></i></div>\n" +
    "        </div>\n" +
    "    </div> -->\n" +
    "\n" +
    "    <div class=\"guides-container\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-xs-12\">\n" +
    "                <page-navigation ng-hide=\"isGuidesMenuUnlocked\" hidebackbtn=\"{{ hidePageNaviBackBtn }}\"\n" +
    "                    hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"{{ backSkipStep }}\"\n" +
    "                    nextskipstep=\"flash-from-guides\"></page-navigation>\n" +
    "                <h1 class=\"main-header\">{{ 'GUIDES_SUBTITLE' | translate }}</h1>\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-12\">\n" +
    "                <p ng-show=\"$parent.submenu.selectedItem === 'Guides'\">\n" +
    "                    {{ 'GUIDES_DESCRIPTION' | translate:({ 'PART_NAME': detectedDevice.partName })}}</p>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"$parent.submenu.selectedItem === 'All'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-md-4 border-right \">\n" +
    "                    <div>\n" +
    "                        <i class=\"custom-icon book-icon\"></i>\n" +
    "                    </div>\n" +
    "                    <h4>\n" +
    "                        <span>{{guideCount}}</span>&nbsp;\n" +
    "                        <span>{{ 'GUIDES_WIRING_HEADER_GUIDES_TAB_TITLE' | translate }}</span>\n" +
    "                    </h4>\n" +
    "                    <div class=\"description\">\n" +
    "                        <p>{{ 'GUIDES_ALL_GUIDES_SUBTITLE' | translate }}</p>\n" +
    "                        <div class=\"link-container link-with-icon-box\">\n" +
    "                            <div class=\"link-items\" ng-repeat=\"dg in responseData.deviceGuides\">\n" +
    "                                <a href=\"{{dg.link}}\" target=\"_blank\">\n" +
    "                                    <i class=\"fa fa-file-pdf-o\" aria-hidden=\"true\"></i>\n" +
    "                                    <span class=\"link-name\">{{dg.name}}{{dg.type | translate}}</span>\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                            <div class=\"link-items\">\n" +
    "                                <a ng-if=\"hasRsuGuides.showExtraGuide\" href=\"#\" ng-click=\"clickRsuGuide()\">\n" +
    "                                    <i class=\"fa fa-external-link\" aria-hidden=\"true\"></i>\n" +
    "                                    <span class=\"link-name rsu-guide-button\">{{'RSU_GUIDE_BUTTON' | translate:({BRAND: hasRsuGuides.extraGuideName})}}</span>\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <button type=\"button\" ng-click=\"goToPartial('Guides')\"\n" +
    "                        class=\"small-btn\">{{ 'GUIDES_ALL_GUIDES_SEE_ALL' | translate }}</button>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-4 border-right \">\n" +
    "                    <div>\n" +
    "                        <i class=\"custom-icon wire-icon\"></i>\n" +
    "                    </div>\n" +
    "                    <h4>\n" +
    "                        <i class=\"logo-icon\"></i>\n" +
    "                    </h4>\n" +
    "                    <div class=\"description\">\n" +
    "                        <p>{{ 'GUIDES_ALL_WIRES_SUBTITLE' | translate }}</p>\n" +
    "                        <div class=\"link-container\" ng-hide=\"showWiringErrorMsg\">\n" +
    "                            <div class=\"link-items\">\n" +
    "                                <a ng-click=\"goToPartial('Wiring')\" class=\"link-name\">\n" +
    "                                    <span ng-bind=\"wireCount\"></span>\n" +
    "                                    {{ 'GUIDES_ALL_WIRES_WIRES' | translate }}\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                            <div class=\"link-items\">\n" +
    "                                <a ng-click=\"goToPartial('Wiring')\" class=\"link-name\">\n" +
    "                                    <span ng-bind=\"imageCount\"></span>\n" +
    "                                    {{ 'GUIDES_ALL_WIRES_IMAGES' | translate }}\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <button ng-disabled=\"showWiringErrorMsg\" type=\"button\" ng-click=\"goToPartial('Wiring')\"\n" +
    "                        class=\"small-btn\">{{ 'GUIDES_ALL_WIRES_SEE_ALL' | translate }}</button>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-4\">\n" +
    "                    <div>\n" +
    "                        <i class=\"custom-icon video-icon\"></i>\n" +
    "                    </div>\n" +
    "                    <h4>\n" +
    "                        <span ng-bind=\"videos.length\"></span>&nbsp;\n" +
    "                        <span>{{ 'GUIDES_ALL_VIDEOS_TITLE' | translate}}</span>\n" +
    "                    </h4>\n" +
    "                    <div class=\"description\">\n" +
    "                        <p>{{ 'GUIDES_ALL_VIDEOS_SUBTITLE' | translate }}</p>\n" +
    "                        <div class=\"link-container\" ng-repeat=\"vi in videos\">\n" +
    "                            <div class=\"link-items\">\n" +
    "                                <a ng-click=\"goToPartial('Videos')\" class=\"video-title link-name\">{{vi.title}}</a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <button ng-click=\"goToPartial('Videos')\" type=\"button\" ng-disabled=\"videos.length === 0\"\n" +
    "                        ng-class=\"videos.length === 0 ? 'disabled' : ''\"\n" +
    "                        class=\"small-btn\">{{ 'GUIDES_ALL_VIDEOS_SEE_ALL' | translate }}</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"$parent.submenu.selectedItem === 'Guides'\">\n" +
    "            <div class=\"generic-config-box\">\n" +
    "                <a href=\"{{dg.link}}\" target=\"_blank\" ng-repeat=\"dg in responseData.deviceGuides\"\n" +
    "                    class=\"list-item newer-version uiTest-Guides-new\">\n" +
    "                    <div>\n" +
    "                        <div ng-show=\"dg.isNewVersion === 1\" class=\"new-version-block\">N</div>\n" +
    "                        <div>{{dg.name}}{{dg.type | translate}}</div>\n" +
    "                        <div class=\"list-sub-title\">{{dg.filename}}<br />{{dg.version}}</div>\n" +
    "                    </div>\n" +
    "                </a>\n" +
    "                <button ng-if=\"hasRsuGuides.showExtraGuide\" ng-click=\"clickRsuGuide()\" class=\"list-item newer-version uiTest-Guides-new rsu-guide-button\"><div>{{'RSU_GUIDE_BUTTON' | translate:({BRAND: hasRsuGuides.extraGuideName})}}</div></button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"$parent.submenu.selectedItem === 'Wiring'\" class=\"wires-container\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <h4 class=\"title-regular capitalize\">{{ selectedVehicle.makeName }} {{ selectedVehicle.modelName }}\n" +
    "                        {{ selectedVehicle.yearName }}</h4>\n" +
    "                    <div class=\"sub-title\">{{ selectedVehicle.vehicleName }}</div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row description-box\">\n" +
    "                <div class=\"col-xs-12 col-md-6\">\n" +
    "                    <div ng-show=\"!showWiringErrorMsg\">\n" +
    "                        <div class=\"wire-img-count\">\n" +
    "                            <span ng-bind=\"wireCount\"></span> {{ 'IMAGE_INFO_WIRES' | translate }}\n" +
    "                        </div>\n" +
    "                        <div class=\"wire-img-count\">\n" +
    "                            <span>{{imageCount}}</span> {{ 'GUIDES_WIRING_TOTAL_IMAGES'  | translate }}\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-12 col-md-6\">\n" +
    "                    <div class=\"button-box\">\n" +
    "                        <span class=\"switch-container\">\n" +
    "                            <div>\n" +
    "                                <div class=\"toggle-bg toggle-alternate {{switchAlternate}}\">\n" +
    "                                    <input type=\"radio\" name=\"toggle\" value=\"off\" ng-model=\"switchAlternate\"\n" +
    "                                        ng-click=\"quitGalleryMode()\">\n" +
    "                                    <input type=\"radio\" name=\"toggle\" value=\"on\" ng-model=\"switchAlternate\"\n" +
    "                                        ng-click=\"showAllImages()\">\n" +
    "                                    <span class=\"switch {{switchAlternate}}\"></span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <span class=\"sub-title\">\n" +
    "                                <span ng-show=\"hideWiringTable\">Image only</span>\n" +
    "                                <span ng-show=\"!hideWiringTable\">Wire table</span>\n" +
    "                            </span>\n" +
    "                        </span>\n" +
    "                        <button ng-hide=\"pdfOpen\" class=\"small-btn\"\n" +
    "                            ng-click=\"togglePdfOptions()\">{{ 'GUIDES_WIRING_PRINT_OPTIONS' | translate }}</button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"print-btn-container\" ng-show=\"pdfOpen\">\n" +
    "                <h1 class=\"main-header\">{{ 'GUIDES_WIRING_PRINT_OPTIONS' | translate }}</h1>\n" +
    "                <div class=\"sub-title\">{{ 'GUIDES_WIRING_PRINT_OPTIONS_SUBTITLE' | translate }}</div>\n" +
    "                <form name=\"myForm\" ng-submit=\"printPdfDocument(pdfOptions)\">\n" +
    "                    <input id=\"all\" type=\"radio\" ng-click=\"generatePdf()\" ng-model=\"pdfOptions\" class=\"radio-custom\"\n" +
    "                        value=\"all\">\n" +
    "                    <label for=\"all\"\n" +
    "                        class=\"radio-custom-label\">{{ 'GUIDES_WIRING_PRINT_OPTIONS_ALL' | translate }}</label>\n" +
    "\n" +
    "                    <input id=\"wires\" type=\"radio\" ng-model=\"pdfOptions\" class=\"radio-custom\" value=\"wires\">\n" +
    "                    <label for=\"wires\"\n" +
    "                        class=\"radio-custom-label\">{{ 'GUIDES_WIRING_PRINT_OPTIONS_WIRES' | translate }}</label>\n" +
    "\n" +
    "                    <input id=\"images\" type=\"radio\" ng-model=\"pdfOptions\" class=\"radio-custom\" value=\"images\">\n" +
    "                    <label for=\"images\"\n" +
    "                        class=\"radio-custom-label\">{{ 'GUIDES_WIRING_PRINT_OPTIONS_IMAGES' | translate }}</label>\n" +
    "\n" +
    "                    <div class=\"button-container\">\n" +
    "                        <button type=\"button\"\n" +
    "                            ng-click=\"togglePdfOptions()\">{{ 'GUIDES_WIRING_PRINT_OPTIONS_CANCEL' | translate }}</button>\n" +
    "                        <button type=\"submit\">{{ 'GUIDES_WIRING_PRINT_OPTIONS_SUBMIT' | translate }}</button>\n" +
    "                    </div>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div class=\"table-wires\" ng-show=\"!showWiringErrorMsg && !hideWiringTable\">\n" +
    "                <div class=\"wires-info-container\" ng-repeat=\"wire in wires\">\n" +
    "                    <div class=\"wires-heading\">{{ wire.function}}</div>\n" +
    "                    <table class=\"table\">\n" +
    "                        <tbody>\n" +
    "                            <tr>\n" +
    "                                <td width=\"160px\">\n" +
    "                                    <div>{{ 'GUIDES_WIRING_WIRES_COLOR'  | translate }}</div>\n" +
    "                                    <div class=\"wires-information\">{{ wire.color }}</div>\n" +
    "                                </td>\n" +
    "                                <td>\n" +
    "                                    <div>{{ 'GUIDES_WIRING_POLARITY'   | translate }}</div>\n" +
    "                                    <div class=\"wires-information\">{{ wire.polarity }}</div>\n" +
    "                                </td>\n" +
    "                                <td width=\"80px\" rowspan=\"{{ !wire.note && !wire.component_location ? 2 : 3}}\">\n" +
    "                                    <button class=\"no-button\" ng-click='showWiresImageSection($index, wire)'\n" +
    "                                        ng-disabled=\"{{ wire.image_count==0 }}\">\n" +
    "                                        <span class=\"icon-wires\"></span>\n" +
    "                                        <span class=\"img-count\">{{ wire.image_count }}</span>\n" +
    "                                    </button>\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "                            <tr>\n" +
    "                                <td colspan=\"2\">\n" +
    "                                    <div>{{ 'GUIDES_WIRING_LOCATION'  | translate }}</div>\n" +
    "                                    <div class=\"wires-information\">{{ wire | wirelocation }}</div>\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "                            <tr data-ng-hide=\"{{ !wire.note && !wire.component_location }}\">\n" +
    "                                <td colspan=\"2\">\n" +
    "                                    <div data-ng-hide=\"{{ !wire.component_location }}\">\n" +
    "                                        <div>{{ 'GUIDES_WIRING_COMPONENT_LOCATION'  | translate }}</div>\n" +
    "                                        <div class=\"wires-information\">{{ wire.component_location }}</div>\n" +
    "                                    </div>\n" +
    "                                    <div data-ng-hide=\"{{ !wire.note }}\">\n" +
    "                                        <div>{{ 'GUIDES_WIRING_NOTE'  | translate }}</div>\n" +
    "                                        <div class=\"wires-information\">{{ wire.note }}</div>\n" +
    "                                    </div>\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "\n" +
    "                            <tr class=\"wire-image\" ng-show=\"activePosition == $index\">\n" +
    "                                <td class=\"directives-container\" colspan=\"5\">\n" +
    "                                    <image-thumbnail wire=\"wire\" images=\"wireImages\"></image-thumbnail>\n" +
    "                                    <image-section wire=\"wire\"></image-section>\n" +
    "                                    <image-section-error data-ng-hide=\"data.ErrorMessage\"></image-section-error>\n" +
    "                                    <image-info ng-show=\"data.showContent\" wire=\"wire\"></image-info>\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "                        </tbody>\n" +
    "                    </table>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <images-gallery ng-show=\"!showWiringErrorMsg && hideWiringTable\" images=\"images\"\n" +
    "                class=\"images-only-container\"></images-gallery>\n" +
    "\n" +
    "            <div class=\"text-center col-xs-12\" ng-show=\"showWiringErrorMsg \">\n" +
    "                <h2 class=\"main-header\">{{ 'GUIDES_WIRING_ERROR_NO_WIRES' | translate }}</h2>\n" +
    "            </div>\n" +
    "            <div class=\"text-center col-xs-12\" ng-show=\"showErrorMsg\">\n" +
    "                <h2 class=\"main-header\">{{ 'GUIDES_WIRING_ERROR_NO_IMAGES' | translate }}</j2>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"$parent.submenu.selectedItem === 'Videos'\" class=\"wires-container\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <h4 class=\"title-regular capitalize\">\n" +
    "                        {{ selectedVehicle.makeName }} {{ selectedVehicle.modelName }} {{ selectedVehicle.yearName }}\n" +
    "                    </h4>\n" +
    "                    <div class=\"sub-title\">{{ selectedVehicle.vehicleName }}</div>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-12 description-box\">\n" +
    "                    <div class=\"wire-img-count\">\n" +
    "                        <span ng-bind=\"videos.length\"></span>&nbsp;\n" +
    "                        <span>{{ 'GUIDES_ALL_VIDEOS_TITLE' | translate}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <videos-section data-ng-hide=\"videos.length === 0\" videos=\"videos\" class=\"images-only-container\">\n" +
    "            </videos-section>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"button-container button-section\" ng-hide=\"firmware_skipped || isGuidesMenuUnlocked || skipWiring || skipGuides\">\n" +
    "            <button class=\"large-btn uiTest-GoToFlash-btn\"\n" +
    "                ng-click=\"goToFlash()\">{{ 'GUIDES_BUTTON_GO_TO' | translate }}</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/guides/submenu.client.view.html',
    "<section data-ng-controller=\"PartialsGuidesSubmenuController\">\n" +
    "    <div class =\"submenu-container\">\n" +
    "        <ul class=\"submenu guides\">\n" +
    "            <li class=\"list-item\" ng-repeat=\"item in $parent.submenu.items\"  ng-class=\"{active: $parent.submenu.selectedItem==item.name}\">\n" +
    "                <!-- <a >{{ item | translate }}</a> -->\n" +
    "                <button class=\"submenu-button\" type=\"button\" ng-click=\"loadPartial(item.name)\" ng-disabled=\"item.disabled\">{{ item.name }}</button>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/installOptions/installOptions.client.view.html',
    "<section data-ng-controller=\"PartialsInstallOptionsController\" ng-init=\"initOptions()\">\n" +
    "    <div class=\"main-content-topbar-offset\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"{{ backSkipStep }}\" nextskipstep={{nextSkipStep}}></page-navigation>\n" +
    "            <h1 class=\"main-header\">\n" +
    "                {{ 'INSTALLOPT_TITLE' | translate }}\n" +
    "            </h1>\n" +
    "        </div>\n" +
    "        <div ng-if=\"hasClassicOptions\" class=\"generic-config-box button-container top-margin\">\n" +
    "            <button class=\"list-item large\" ng-click=\"getStdOptions()\" ng-click=\"getStdOptions()\" ng-disabled=\"!disableStdBtn || isGlobalLoading\" ng-class=\"{active:$parent.userSelection.selectedProtocol.isStandalone}\">\n" +
    "                <span>\n" +
    "                    {{ 'INSTALLOPT_CHOICE_STANDALONE' | translate }}\n" +
    "                </span>\n" +
    "                <span class=\"small-text\">\n" +
    "                    {{ 'INSTALLOPT_CHOICE_STANDALONE_DESC' | translate }}\n" +
    "                </span>\n" +
    "            </button>\n" +
    "            <button class=\"list-item large\" ng-click=\"getProtocols()\" ng-class=\"{active:$parent.userSelection.selectedProtocol.usedAsAddOn}\" ng-disabled=\"isGlobalLoading\">\n" +
    "                <span>\n" +
    "                    {{ 'INSTALLOPT_CHOICE_ADDON' | translate }}\n" +
    "                </span>\n" +
    "                <span class=\"small-text\">\n" +
    "                    {{ 'INSTALLOPT_CHOICE_ADDON_DESC' | translate }}\n" +
    "                </span>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    \n" +
    "        <div ng-if=\"$parent.userSelection.selectedProtocol.usedAsAddOn\" class=\"generic-config-box\">       \n" +
    "            <div class=\"list-title\">{{ 'INSTALLOPT_CHOOSE_PROTOCOL' | translate }}</div>\n" +
    "            <div ng-repeat=\"pr in $parent.responseData.classicInstallOptions.addOnProtocols\" class=\"list-container\">\n" +
    "                <button class=\"list-item large\" ng-click=\"setProtocol(pr.id, pr.name)\" ng-class=\"{active:$parent.userSelection.selectedProtocol.id === pr.id}\" ng-disabled=\"isGlobalLoading\">\n" +
    "                    <span>\n" +
    "                        {{ pr.name }}\n" +
    "                    </span>\n" +
    "                    <span class=\"small-text\" ng-hide=\"pr.description === ''\">\n" +
    "                        {{ pr.description }}\n" +
    "                    </span>\n" +
    "                    <span class=\"small-text bold\" ng-show=\"pr.isDefault\"><i class=\"fa fa-check-circle fa-lg\"></i> {{ 'PROTOCOL_SUGGESTED' | translate }}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"$parent.userSelection.selectedProtocol.isStandalone\" class=\"generic-config-box\">\n" +
    "            <div class=\"list-title\">{{ 'INSTALLOPT_CHOOSE_OPTION' | translate }}</div>\n" +
    "            <div ng-repeat=\"rs in $parent.responseData.classicInstallOptions.rsOptions\" class=\"list-container\">\n" +
    "                <button class=\"list-item large\" ng-click=\"setOption(rs.id)\" ng-disabled=\"rs.disabled\" ng-class=\"{active:$parent.userSelection.selectedProtocol.standaloneId === rs.id}\" ng-disabled=\"isGlobalLoading\">\n" +
    "                    <span>\n" +
    "                        {{ rs.name }}\n" +
    "                    </span>\n" +
    "                    <span class=\"small-text\" ng-hide=\"rs.description === ''\">\n" +
    "                        {{ rs.description }}\n" +
    "                    </span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"$parent.userSelection.selectedProtocol.isStandaloneRf\" class=\"generic-config-box\">\n" +
    "            <div class=\"list-title\">{{ 'INSTALLOPT_CHOOSE_PROTOCOL' | translate }}</div>\n" +
    "            <div ng-repeat=\"pr in $parent.responseData.classicInstallOptions.rfProtocols\" class=\"list-container\">\n" +
    "                <button class=\"list-item small\" ng-click=\"setProtocol(pr.id, pr.name)\" ng-class=\"{active:$parent.userSelection.selectedProtocol.id === pr.id}\" ng-disabled=\"isGlobalLoading\">\n" +
    "                    <span>{{ pr.name }}</span>\n" +
    "                    <span class=\"small-text\" ng-show=\"pr.isDefault\"><i class=\"fa fa-check-circle fa-lg\"></i> {{ 'PROTOCOL_SUGGESTED' | translate }}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"$parent.userSelection.selectedProtocol.isStandaloneTelematic\" class=\"generic-config-box\">\n" +
    "            <div class=\"list-title\">{{ 'INSTALLOPT_CHOOSE_PROTOCOL' | translate }}</div>\n" +
    "            <div ng-repeat=\"pr in $parent.responseData.classicInstallOptions.telematicProtocols\" class=\"list-container\">\n" +
    "                <button class=\"list-item small\" ng-click=\"setProtocol(pr.id, pr.name)\" ng-class=\"{active:$parent.userSelection.selectedProtocol.id === pr.id}\" ng-disabled=\"isGlobalLoading\">\n" +
    "                    <span>{{ pr.name }}</span>\n" +
    "                    <span class=\"small-text\" ng-show=\"pr.isDefault\"><i class=\"fa fa-check-circle fa-lg\"></i> {{ 'PROTOCOL_SUGGESTED' | translate }}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"$parent.responseData.vehicleFirmwareFamilies.length > 1\" class=\"generic-config-box\">\n" +
    "            <div class=\"list-title\">{{ 'INSTALLOPT_CHOOSE_FAMILY' | translate }}</div>\n" +
    "            <div ng-repeat=\"pr in $parent.responseData.vehicleFirmwareFamilies\" class=\"list-container\">\n" +
    "                <button class=\"list-item small\" ng-click=\"setFirmwareFamilyUser(pr.id, pr.name)\" ng-class=\"{active:$parent.responseData.vehicleFirmwareFamilies.id === pr.id}\" ng-disabled=\"isGlobalLoading\">\n" +
    "                    <span>{{ pr.name }}</span>\n" +
    "                    <span class=\"small-text\">{{ pr.projectFunction }}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"!hasClassicOptions\" class=\"generic-config-box\">\n" +
    "            <div class=\"list-title\">{{ 'INSTALLOPT_CHOOSE_PROTOCOL' | translate }}</div>\n" +
    "            <div ng-repeat=\"pr in $parent.responseData.protocols\" class=\"list-container\">\n" +
    "                <button class=\"list-item small\" ng-click=\"setProtocol(pr.id, pr.name)\" ng-class=\"{active:$parent.userSelection.selectedProtocol.id === pr.id}\" ng-disabled=\"isGlobalLoading\">\n" +
    "                    <span>{{ pr.name }}</span>\n" +
    "                    <span class=\"small-text\" ng-show=\"pr.isDefault\"><i class=\"fa fa-check-circle fa-lg\"></i> {{ 'PROTOCOL_SUGGESTED' | translate }}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/messageBox/bootloaderUpgradeMessageBox.client.view.html',
    "<div>\n" +
    "    <div class=\"modal-heading\">{{ title | translate}}</div>\n" +
    "    <div class=\"description-box\">\n" +
    "        <div>{{ message | translate}}</div>\n" +
    "        <div>{{ secondMessage | translate}}</div>\n" +
    "    </div>\n" +
    "    <div class=\"button-container\">\n" +
    "        <button type=\"button\" class=\"large-btn\" ng-click=\"event()\">{{ buttonTitle | translate}}\n" +
    "        </button>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('modules/core/views/partials/messageBox/default.messageBox.client.view.html',
    "<div>\n" +
    "    <div ng-show=\"ngDialogData.title\" class=\"modal-heading\">\n" +
    "        <!--<img src=\"img/brand/notice.png\" alt=\"ADS notice\" />-->\n" +
    "        {{ ngDialogData.title | translate }}\n" +
    "    </div>\n" +
    "    <div ng-show=\"ngDialogData.sousTitle\" class=\"modal-sub-heading\">{{ ngDialogData.sousTitle | translate}}</div>\n" +
    "    <!--<p ng-show=\"ngDialogData.title\">Data passed through: <code>{{ngDialogData.title}}</code></p>-->\n" +
    "    <div class=\"button-container\">\n" +
    "        <!--<button type=\"button\" class=\"ngdialog-button ngdialog-button-secondary\" ng-click=closeThisDialog(\"Cancel\")>Cancel</button>-->\n" +
    "        <button type=\"button\" class=\"large-btn\" ng-click=confirm(\"OK\")>OK</button>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/partials/oemRemoteStart/oemRemoteStart.client.view.html',
    "<section id=\"oem-remote-start\" ng-controller=\"PartialsOemRemoteStartClientController\" class=\"controls-partial\">\n" +
    "    <div class=\"row text-center\">\n" +
    "    <div class=\"col-xs-12\">\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"firmware\" nextskipstep=\"oem-start-save-config\"></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'MENU_SIDEBAR_OEM_REMOTE_START' | translate }}</h1>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"oem-remote-section\" ng-repeat=\"feature in responseData.quickConfigs\">\n" +
    "                <div class=\"medium-text capitalize col-xs-8\"   ng-class=\"feature.disabled ? 'disabled' : '' \"> {{feature.name}}</div>\n" +
    "                <div class=\"col-xs-4\">\n" +
    "                    <ui-select ng-model=\"feature.selectedItem\" search-enabled=\"false\" style=\"width: 260px\" theme=\"select2\"  ng-disabled=\"feature.disabled\" ng-change=\"showWarningModal(feature, $index)\">\n" +
    "                        <ui-select-match>{{feature.selectedItem.name}}</ui-select-match>\n" +
    "                        <ui-select-choices repeat=\"op in feature.option\">\n" +
    "                            <div ng-bind-html=\"op.name\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"clearfix\"></div>\n" +
    "    <div class=\"button-container top-margin\">\n" +
    "        <button class=\"large-btn\" ng-disabled=\"isGlobalLoading\" ng-click=\"saveAndGoToSummary()\">{{ 'OEM_REMOTE_START_BUTTON_GO_SUMMARY' | translate }}</button>\n" +
    "        <button class=\"large-btn\" ng-disabled=\"isGlobalLoading\" ng-click=\"saveOemRemoteStartConfig()\">{{ 'CONTROLS_BUTTON_SAVE_GO_TO' | translate }}</button>\n" +
    "    </div>\n" +
    "    <!-- Configuration favorites, not released yet\n" +
    "    <div class=\"button-container\">\n" +
    "        <button class=\"large-btn\" ng-disabled=\"isGlobalLoading\" ng-click=\"flashConfigFromFavorites()\">{{ 'FAVORITE_CONFIG_FLASH' | translate }}</button>\n" +
    "    </div> -->\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/remotes/remotes.client.view.html',
    "<remotes\n" +
    "  request=\"request\"\n" +
    "  supports-internal-remotes=\"supportsInternalRemotes\"\n" +
    "  load-telematics-partial=\"loadTelematicsPartial\"\n" +
    "  load-firmware-partial=\"loadFirmwarePartial\"\n" +
    "  response-data=\"responseData\"\n" +
    "  user-selection=\"userSelection\"\n" +
    ">\n" +
    "</remotes>\n"
  );


  $templateCache.put('modules/core/views/partials/telematics/telematics.client.view.html',
    "<section data-ng-controller=\"PartialsTelematicsTelematicsController\" ng-init=\"initTelematics()\">\n" +
    "    <div class=\"telematics-container\">\n" +
    "        <div class=\"no-temelatics-found\" ng-show=\"hasOnlyOnePort && hasRemoteSelected\">\n" +
    "            {{ 'TELEMATICS_SELECT_DISABLED_GO_TO_REMOTES' | translate }}\n" +
    "        </div>\n" +
    "        <div class=\"nav-ctn\">\n" +
    "            <page-navigation hidebackbtn=\"false\" hidenextbtn=\"hidePageNaviNextBtn\" backskipstep=\"{{ backSkipStep }}\" nextskipstep=\"firmware\"></page-navigation>\n" +
    "            <h1 class=\"main-header\">{{ 'TELEMATICS_TITLE' | translate }}</h1>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row main-content\">\n" +
    "            <div class=\"col-xs-10 col-md-7 col-lg-5\">\n" +
    "                {{ 'TELEMATICS_SELECTION_INSTRUCTIONS' | translate }}\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-12 section\">\n" +
    "                <div class=\"protocol-img-box\" >\n" +
    "                    <img ng-src=\"img/telematics/phone/locked/phone.png\" ng-if=\"!$parent.isTelematicsOn\" err-src=\"img/telematics/phone/generic.png\"/>\n" +
    "                    <img ng-src=\"{{selectedTelematic.imagePhone}}\" ng-hide=\"!$parent.isTelematicsOn\" err-src=\"img/telematics/phone/unlocked/snapshot/generic.png\" />\n" +
    "                    <br/>\n" +
    "                    <div class=\"switch-container\">\n" +
    "                        <div class=\"toggle-bg toggle-alternate {{switchAlternate}}\">\n" +
    "                            <label class=\"{{switchAlternate}}\">{{switchAlternate}}</label>\n" +
    "                            <input type=\"radio\" name=\"toggle\" value=\"off\" ng-model=\"switchAlternate\" ng-click=\"lockTelematicsList()\">\n" +
    "                            <input type=\"radio\" name=\"toggle\" value=\"on\" ng-model=\"switchAlternate\" ng-click=\"unlockTelematicsList()\" ng-disabled=\"hasOnlyOnePort && hasRemoteSelected\">\n" +
    "                            <span class=\"switch {{switchAlternate}}\"></span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"protocols-list\">\n" +
    "                    <div class=\"radio\"  ng-repeat=\"telematic in $parent.responseData.telematics\">\n" +
    "                        <label class=\"{{ $parent.isTelematicsOn ? '' : 'locked'}}\">\n" +
    "\n" +
    "                            <input type=\"radio\" ng-model=\"selectedTelematic.id\" name=\"name\" value=\"{{telematic.id}}\" ng-disabled=\"switchAlternate == 'off'\" required />\n" +
    "                            <span class=\"overlay\"></span><img ng-src=\"{{$parent.isTelematicsOn ? telematic.images.logoUnlocked : telematic.images.logoLocked}}\" err-src=\"img/telematics/logo/generic.png\" alt=\"{{telematic.name}}\" title=\"{{telematic.name}}\" err-name=\"{{telematic.name}}\" /><err-name class=\"err-name\"></err-name>\n" +
    "\n" +
    "                        </label>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"clearfix\"></div>\n" +
    "        <div class=\"button-container telematics-footer\">\n" +
    "            <div><button ng-disabled=\"isGlobalLoading\" class=\"large-btn\" ng-click=\"saveSelectedTelematics()\">{{ 'BUTTON_SAVE_AND_CONTINUE' | translate }}</button></div>\n" +
    "            <div data-ng-show=\"{{ demo_mode && show_wiring }}\"><button class=\"large-btn\" ng-disabled=\"isGlobalLoading\" ng-click=\"skipToGuides()\">{{ 'TELEMATICS_BUTTON_SKIP_TO_GUIDES_WIRING' | translate }}</button></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/views/partials/videoContainer/video.container.client.view.html',
    "<div class=\"vzaar_media_player vzaar-player-container\">\n" +
    "    <iframe width=\"{{ngDialogData.width}}\"\n" +
    "            height=\"{{ngDialogData.height}}\"\n" +
    "            data-ng-src=\"{{ngDialogData.link}}\"\n" +
    "            scrolling=\"no\"\n" +
    "            frameborder=\"0\"\n" +
    "            allowfullscreen=\"true\"\n" +
    "    >\n" +
    "    </iframe>\n" +
    "</div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('modules/core/views/splash/home.client.view.html',
    "<div class=\"splash-view\">\n" +
    "    <div class=\"container\">\n" +
    "        <div class=\"content\">\n" +
    "        <div class=\"text-center logo\">\n" +
    "            <img src=\"img/splash/logo_weblink.png\" />\n" +
    "        </div>\n" +
    "        <div class=\"text-center start\">\n" +
    "            <a href=\"/\">Start</a>\n" +
    "        </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/views/unsupported-browser/home.client.view.html',
    "<section data-ui-view>\n" +
    "    <div class=\"browser-view angular-view text-center\">\n" +
    "        <div class=\"content\">\n" +
    "            <div class=\"msg text-left\">\n" +
    "                <p class=\"top-heading\">{{ 'UNSUPPORTED_BROWSER_BROWSER_VERSION' | translate }} {{unsupportedBrowserCtrl.browserInformation.browserName + ' ' + unsupportedBrowserCtrl.browserInformation.browserVersion}}</p>\n" +
    "                <p class=\"big-heading\">{{ 'UNSUPPORTED_BROWSER_UNSUPPORTED' | translate }}</p>\n" +
    "                <p>{{ 'UNSUPPORTED_BROWSER_BROWSER_TEXT' | translate }}</p>\n" +
    "                <div class=\"btns\">\n" +
    "                    <div class=\"col-sm-6 text-left btn-links\">\n" +
    "                        <a href=\"https://www.weblinkupdater.com/weblinkdesktop/\">{{ 'WEBLINKDESKTOP_DOWNLOAD' | translate }}</a>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-6 text-left btn-links\">\n" +
    "                        <a href=\"http://www.idatalink.com/weblink\">{{ 'UNSUPPORTED_BROWSER_BACK_TO' | translate}}</a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</section>\n"
  );


  $templateCache.put('modules/core/decorators/checkbox-custom.html',
    "<div class=\"checkbox schema-form-checkbox {{form.htmlClass}}\"\n" +
    "     ng-class=\"{'has-error': form.disableErrorState !== true &&  hasError(), 'has-success': form.disableSuccessState !== true &&  hasSuccess()}\">\n" +
    "        <input type=\"checkbox\"\n" +
    "               id={{form.key}}\n" +
    "               sf-changed=\"form\"\n" +
    "               ng-disabled=\"form.readonly\"\n" +
    "               sf-field-model\n" +
    "               schema-validate=\"form\"\n" +
    "               ng-model=\"$$value$$\"\n" +
    "               ng-required=\"form.required\"\n" +
    "               class=\"{{form.fieldHtmlClass}}\"\n" +
    "               name=\"{{form.key.slice(-1)[0]}}\">\n" +
    "    <label for=\"{{form.key}}\" class=\"{{form.labelHtmlClass}}\">{{form.title}}</label>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/decorators/checkboxes-inline.html',
    "<div sf-array=\"form\" ng-model=\"$$value$$\"\n" +
    " class=\"checkbox form-group schema-form-checkboxes {{form.htmlClass}}\"\n" +
    " ng-class=\"{'has-error': hasError(), 'has-success': hasSuccess()}\">\n" +
    "<label class=\"control-label\" ng-show=\"showTitle()\">{{form.title}}</label>\n" +
    "\n" +
    "<div ng-repeat=\"val in titleMapValues track by $index\">\n" +
    "        <input type=\"checkbox\"\n" +
    "               id=`${{$index}}-${{form.key.slice(-1)[0]}}`\n" +
    "               ng-disabled=\"form.readonly\"\n" +
    "               sf-changed=\"form\"\n" +
    "               class=\"{{form.fieldHtmlClass}}\"\n" +
    "               ng-model=\"titleMapValues[$index]\"\n" +
    "               sf-field-model\n" +
    "               schema-validate=\"form\"\n" +
    "               name=\"{{form.key.slice(-1)[0]}}\">\n" +
    "\n" +
    "       <label for=`${{$index}}-${{form.key.slice(-1)[0]}}` class=\"{{form.labelHtmlClass}}\">{{form.titleMap[$index].name}}</label>\n" +
    "\n" +
    "</div>\n" +
    "<div class=\"help-block\" sf-message=\"form.description\"></div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/decorators/custom-radios.html',
    "<div class=\"form-group\" ng-model=\"$$value$$\" sf-changed=\"form\" ng-class=\"{'has-error': hasError(), 'has-success': hasSuccess()}\">\n" +
    "    <label class=\"control-label left required\" model ng-show=\"showTitle()\">{{(form.title | translate) || form.title}}</label>\n" +
    "    <div class=\"custom___radio___right\">\n" +
    "    <div style=\"display: inline-block; padding-right: 10px;\" class=\"radio\" ng-repeat=\"item in form.titleMap track by $index\" >\n" +
    "      <input type=\"radio\"\n" +
    "             id=\"{{$index + item.name}}\"\n" +
    "             sf-changed=\"form\"\n" +
    "             ng-disabled=\"form.readonly\"\n" +
    "             ng-model=\"$$value$$\"\n" +
    "             class=\"{{form.fieldHtmlClass}}\"\n" +
    "             ng-model-options=\"form.ngModelOptions\"\n" +
    "             ng-value=\"item.value\">\n" +
    "      <label for=\"{{$index + item.name}}\" class=\"{{form.labelHtmlClass}}\" ng-bind=\"(item.name | translate) || item.name\"></label>\n" +
    "  </div>\n" +
    "  </div>\n" +
    "  <div class=\"help-block\" ng-show=\"(hasError() && errorMessage(schemaError())) || form.description\" ng-bind-html=\"(hasError() && errorMessage(schemaError())) || form.description\"></div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/decorators/default-custom.html',
    "<div class=\"form-group {{form.htmlClass}}\"\n" +
    "     ng-class=\"{ '{{'schema-form-' + form.type}}': true, 'has-error': form.disableErrorState !== true && hasError(), 'has-success': form.disableSuccessState !== true && hasSuccess(), 'has-feedback': form.feedback !== false }\">\n" +
    "  <label class=\"control-label {{form.labelHtmlClass}}\" ng-class=\"{'sr-only': !showTitle()}\" for=\"{{form.key.slice(-1)[0]}}\">{{(form.title | translate) || form.title}}</label>\n" +
    "\n" +
    "  <input ng-if=\"!form.fieldAddonLeft && !form.fieldAddonRight\"\n" +
    "         ng-show=\"form.key\"\n" +
    "         type=\"{{form.type}}\"\n" +
    "         step=\"any\"\n" +
    "         sf-changed=\"form\"\n" +
    "         placeholder=\"{{(form.placeholder | translate) || form.placeholder}}\"\n" +
    "         class=\"form-control {{form.fieldHtmlClass}}\"\n" +
    "         id=\"{{form.key.slice(-1)[0]}}\"\n" +
    "         sf-field-model\n" +
    "         ng-model-options=\"form.ngModelOptions\"\n" +
    "         ng-model=\"$$value$$\"\n" +
    "         ng-disabled=\"form.readonly\"\n" +
    "         schema-validate=\"form\"\n" +
    "         name=\"{{form.key.slice(-1)[0]}}\"\n" +
    "         aria-describedby=\"{{form.key.slice(-1)[0] + 'Status'}}\">\n" +
    "\n" +
    "  <div ng-if=\"form.fieldAddonLeft || form.fieldAddonRight\"\n" +
    "       ng-class=\"{'input-group': (form.fieldAddonLeft || form.fieldAddonRight)}\">\n" +
    "    <span ng-if=\"form.fieldAddonLeft\"\n" +
    "          class=\"input-group-addon\"\n" +
    "          ng-bind-html=\"form.fieldAddonLeft\"></span>\n" +
    "    <input ng-show=\"form.key\"\n" +
    "           type=\"{{form.type}}\"\n" +
    "           step=\"any\"\n" +
    "           sf-changed=\"form\"\n" +
    "           placeholder=\"{{(form.placeholder | translate) || form.placeholder}}\"\n" +
    "           class=\"form-control {{form.fieldHtmlClass}}\"\n" +
    "           id=\"{{form.key.slice(-1)[0]}}\"\n" +
    "           sf-field-model\n" +
    "           ng-model-options=\"form.ngModelOptions\"\n" +
    "           ng-model=\"$$value$$\"\n" +
    "           ng-disabled=\"form.readonly\"\n" +
    "           schema-validate=\"form\"\n" +
    "           name=\"{{form.key.slice(-1)[0]}}\"\n" +
    "           aria-describedby=\"{{form.key.slice(-1)[0] + 'Status'}}\">\n" +
    "\n" +
    "    <span ng-if=\"form.fieldAddonRight\"\n" +
    "          class=\"input-group-addon\"\n" +
    "          ng-bind-html=\"form.fieldAddonRight\"></span>\n" +
    "  </div>\n" +
    "\n" +
    "  <span ng-if=\"form.feedback !== false\"\n" +
    "        class=\"form-control-feedback\"\n" +
    "        ng-class=\"evalInScope(form.feedback) || {'glyphicon': true, 'glyphicon-ok': form.disableSuccessState !== true && hasSuccess(), 'glyphicon-remove': form.disableErrorState !== true && hasError() }\"\n" +
    "        aria-hidden=\"true\"></span>\n" +
    "\n" +
    "  <span ng-if=\"hasError() || hasSuccess()\"\n" +
    "        id=\"{{form.key.slice(-1)[0] + 'Status'}}\"\n" +
    "        class=\"sr-only\">{{ hasSuccess() ? '(success)' : '(error)' }}</span>\n" +
    "\n" +
    "  <div class=\"help-block\" sf-message=\"form.description\"></div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/decorators/material-input.html',
    "<div class=\"form-group schema-form-{{form.type}} {{form.htmlClass}}\"\n" +
    "      ng-class=\"{'has-error': form.disableErrorState !== true && hasError(), 'has-success': form.disableSuccessState !== true && hasSuccess(), 'has-feedback': form.feedback !== false }\">\n" +
    "      <input\n" +
    "            ng-show=\"form.key\"\n" +
    "            type=\"{{ form.key[0] === 'password' ? 'password' : form.schema.type }}\"\n" +
    "            step=\"any\"\n" +
    "            sf-changed=\"form\"\n" +
    "            placeholder=\"{{(form.title | translate) || form.title}}\"\n" +
    "            id=\"form.key.slice(-1)[0]\"\n" +
    "            ng-model-options=\"form.ngModelOptions\"\n" +
    "            ng-model=\"$$value$$\"\n" +
    "            ng-disabled=\"form.readonly\"\n" +
    "            schema-validate=\"form\"\n" +
    "            name=\"{{form.key.slice(-1)[0]}}\"\n" +
    "            aria-describedby=\"{{form.key.slice(-1)[0] + 'Status'}}\"\n" +
    "      >\n" +
    "\n" +
    "      <span ng-if=\"hasError() || hasSuccess()\"\n" +
    "            id=\"{{form.key.slice(-1)[0] + 'Status'}}\"\n" +
    "            class=\"sr-only\">{{ hasSuccess() ? '(success)' : '(error)' }}\n" +
    "      </span>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/decorators/password-custom.html',
    "<div class=\"form-group {{form.htmlClass}}\"\n" +
    "      ng-class=\"{ '{{'schema-form-' + form.type}}': true, 'has-error': form.disableErrorState !== true && hasError(), 'has-success': form.disableSuccessState !== true && hasSuccess(), 'has-feedback': form.feedback !== false }\">\n" +
    "      <label class=\"control-label {{form.labelHtmlClass}}\" ng-class=\"{'sr-only': !showTitle()}\" for=\"{{form.key.slice(-1)[0]}}\">{{(form.title | translate) || form.title}}</label>\n" +
    "\n" +
    "      <input ng-if=\"!form.fieldAddonLeft && !form.fieldAddonRight\"\n" +
    "            ng-show=\"form.key\"\n" +
    "            type=\"password\"\n" +
    "            step=\"any\"\n" +
    "            sf-changed=\"form\"\n" +
    "            placeholder=\"{{(form.placeholder | translate) || form.placeholder}}\"\n" +
    "            class=\"form-control {{form.fieldHtmlClass}}\"\n" +
    "            id=\"{{form.key.slice(-1)[0]}}\"\n" +
    "            sf-field-model\n" +
    "            ng-model-options=\"form.ngModelOptions\"\n" +
    "            ng-model=\"$$value$$\"\n" +
    "            ng-disabled=\"form.readonly\"\n" +
    "            schema-validate=\"form\"\n" +
    "            name=\"{{form.key.slice(-1)[0]}}\"\n" +
    "            aria-describedby=\"{{form.key.slice(-1)[0] + 'Status'}}\"\n" +
    "      >\n" +
    "\n" +
    "      <div ng-if=\"form.fieldAddonLeft || form.fieldAddonRight\"\n" +
    "            ng-class=\"{'input-group': (form.fieldAddonLeft || form.fieldAddonRight)}\">\n" +
    "            <span ng-if=\"form.fieldAddonLeft\" class=\"input-group-addon\" ng-bind-html=\"form.fieldAddonLeft\"></span>\n" +
    "            <input ng-show=\"form.key\"\n" +
    "                  type=\"password\"\n" +
    "                  step=\"any\"\n" +
    "                  sf-changed=\"form\"\n" +
    "                  placeholder=\"{{(form.placeholder | translate) || form.placeholder}}\"\n" +
    "                  class=\"form-control {{form.fieldHtmlClass}}\"\n" +
    "                  id=\"{{form.key.slice(-1)[0]}}\"\n" +
    "                  sf-field-model\n" +
    "                  ng-model-options=\"form.ngModelOptions\"\n" +
    "                  ng-model=\"$$value$$\"\n" +
    "                  ng-disabled=\"form.readonly\"\n" +
    "                  schema-validate=\"form\"\n" +
    "                  name=\"{{form.key.slice(-1)[0]}}\"\n" +
    "                  aria-describedby=\"{{form.key.slice(-1)[0] + 'Status'}}\"\n" +
    "            >\n" +
    "\n" +
    "            <span ng-if=\"form.fieldAddonRight\" class=\"input-group-addon\" ng-bind-html=\"form.fieldAddonRight\"></span>\n" +
    "      </div>\n" +
    "\n" +
    "      <span ng-if=\"form.feedback !== false\" class=\"form-control-feedback\"\n" +
    "            ng-class=\"evalInScope(form.feedback) || {'glyphicon': true, 'glyphicon-ok': form.disableSuccessState !== true && hasSuccess(), 'glyphicon-remove': form.disableErrorState !== true && hasError() }\"\n" +
    "            aria-hidden=\"true\">\n" +
    "      </span>\n" +
    "\n" +
    "      <span ng-if=\"hasError() || hasSuccess()\" id=\"{{form.key.slice(-1)[0] + 'Status'}}\" class=\"sr-only\">{{ hasSuccess() ? '(success)' : '(error)' }}</span>\n" +
    "\n" +
    "      <div class=\"help-block\" sf-message=\"form.description\"></div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/decorators/select-custom.html',
    "<div class=\"form-group {{form.htmlClass}} schema-form-select\"\n" +
    "     ng-class=\"{'has-error': form.disableErrorState !== true && hasError(), 'has-success': form.disableSuccessState !== true && hasSuccess(), 'has-feedback': form.feedback !== false}\">\n" +
    "  <label class=\"control-label {{form.labelHtmlClass}}\" ng-show=\"showTitle()\">\n" +
    "    {{(form.title | translate) || form.title}}\n" +
    "  </label>\n" +
    "  <select sf-field-model\n" +
    "          ng-disabled=\"form.readonly\"\n" +
    "          sf-changed=\"form\"\n" +
    "          ng-model=\"$$value$$\"\n" +
    "          class=\"form-control {{form.fieldHtmlClass}}\"\n" +
    "          schema-validate=\"form\"\n" +
    "          ng-options=\"item.value as item.name group by item.group for item in form.titleMap\"\n" +
    "          name=\"{{form.key.slice(-1)[0]}}\">\n" +
    "  </select>\n" +
    "  <div class=\"help-block\" sf-message=\"form.description\"></div>\n" +
    "</div>\n"
  );


  $templateCache.put('modules/core/decorators/submit-custom.html',
    "<div class=\"form-group schema-form-submit button-container top-margin flex-btn-box {{form.htmlClass}}\">\n" +
    "  <input type=\"submit\"\n" +
    "    class=\"{{ form.style }} {{form.fieldHtmlClass}}\"\n" +
    "    value=\"{{(form.title | translate) || form.title }}\"\n" +
    "    ng-disabled=\"form.readonly\"\n" +
    "    ng-if=\"form.type === 'submit'\">\n" +
    "  <button class=\"btn {{ form.style || 'btn-default' }}\"\n" +
    "    type=\"button\"\n" +
    "    ng-click=\"buttonClick($event,form)\"\n" +
    "    ng-disabled=\"form.readonly\"\n" +
    "    ng-if=\"form.type !== 'submit'\"\n" +
    "  >\n" +
    "    <span ng-if=\"form.icon\" class=\"{{form.icon}}\"></span>\n" +
    "    {{(form.title | translate) || form.title}}\n" +
    "  </button>\n" +
    "</div>\n"
  );

}]);
