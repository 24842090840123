'use strict';

/**
 * Created by jeromesoulas on 15-07-06.
 */

angular.module('core').directive('featureRow', ['$rootScope', '_', 'ngDialog',
    function($rootScope, _, ngDialog){

    function link($scope, $elem, attrs) {
        $scope.isDefault = false;
        $scope.showPopup = true;
        $scope.isDefaultFeature = false;

        var options = $scope.feature.option;
        $scope.selectedOption = {};
        $scope.selectedOption.selectedValue = _.find(options, 'isSelected');

        for (var i = 0; i < options.length; i++){
            if( options[i].isDefault === true && options[i].isSelected === 1 ){
                $scope.isDefault = true;
            }
            if( options[i].isDefaultFeature === true && options[i].isSelected === 1 ){
                $scope.isDefaultFeature = true;
            }
        }

        if( $scope.feature.isLocked === 1 ){
            $scope.rowStatus = 'row-locked';
        }else if( $scope.isDefault === false ){
            $scope.rowStatus = 'row-modified';
        }else{
            $scope.rowStatus = 'row-standard';
        }

        $scope.resetfeature = function() {

            var engineeringDefaultOption = _.find(options, ['isDefaultFeature', true]);
            var defaultOption = _.find(options, ['isDefault', true]);

            if (!engineeringDefaultOption){
                $scope.selectedOption.selectedValue = defaultOption;
            } else {
                $scope.selectedOption.selectedValue = engineeringDefaultOption;
            }

            for (var i = 0; i < options.length; i++){
                var option = options[i];

                if (option.isDefaultFeature || (option.isDefault && !engineeringDefaultOption)) {
                    option.isSelected = 1;
                } else {
                    option.isSelected = 0;
                }

                //Only update once, with selected option.
                if (option.isSelected === 1) {
                    $scope.isDefault = option.isDefault;
                    $scope.isDefaultFeature = option.isDefaultFeature;
                    updateChange($rootScope.globalResponseData.deviceFeatures, option.featureConfigurationId, option, $scope.showPopup);
                    $scope.showPopup = false;
                }

            }


            // Only reset appearance if it's the global default
            if($scope.isDefault){
                $scope.rowStatus = 'row-standard';
            } else {
                $scope.rowStatus = 'row-modified';
            }

        };

        $scope.editfeature = function( id ) {
           var optionValue;
            if( $scope.feature.selectionType === 'radio' ){
                var radioOn = angular.element( document.querySelector( '#_radio'+id+'On' ));
                var radioOnValue = radioOn.prop('checked');

                if( radioOnValue === true ){
                    optionValue = options[0].name;
                }else{
                    optionValue = options[1].name;
                }
            }else if( $scope.feature.selectionType === 'select' ){
                optionValue = $scope.selectedOption.selectedValue.name;
            }

            for (var i = 0; i < options.length; i++){
                if( options[i].name === optionValue ){
                    options[i].isSelected = 1;
                    $scope.selectedOption.selectedValue.id = options[i].id;
                    if( options[i].isDefault === true){
                        $scope.rowStatus = 'row-standard';
                        $scope.isDefault = true;
                    }else{

                        $scope.rowStatus = 'row-modified';
                        $scope.isDefault = false;
                    }

                    $scope.isDefaultFeature = options[i].isDefaultFeature;

                }else{
                    options[i].isSelected = 0;
                }
            }

            updateChange($rootScope.globalResponseData.deviceFeatures, id, $scope.selectedOption.selectedValue, $scope.showPopup);
            $scope.showPopup = false;
        };

    }

    function updateChange(deviceFeatures, selectedFeatureId, selectedOption, showPopupIfHasInformation){
        var featureConfigurations;
        var fOptions;
        var featureId;
        var originalOptionId;
        angular.forEach(deviceFeatures, function(featuresGroup){

            featureConfigurations = featuresGroup.featureConfigurations;

            angular.forEach(featureConfigurations, function(feature){

                if(feature.id === selectedFeatureId) {
                    if (showPopupIfHasInformation &&
                        feature &&
                        feature.information &&
                        feature.information.length > 0
                    ) {
                        ngDialog.openConfirm({
                            template: 'modules/core/views/modals/featureConfigWarning.modal.client.view.html',
                            className: 'ngdialog-theme-plain',
                            controller: ['$scope', function ($scope) {
                                $scope.posts = feature.information;
                            }]
                        });
                    }
                    fOptions = feature.option;
                    featureId = feature.id;
                    originalOptionId = feature.clientSide_originalSelectedOptionId;

                    angular.forEach(fOptions, function (option) {

                        //Option should only be marked as changed if it does not match the original selected option
                        if (option.id === selectedOption.id && selectedOption.id !== originalOptionId.toString()) {
                            feature.clientSide_isChanged = true;
                        }
                    });
                }
            });
        });
    }

    return {
        link: link,
        scope: {
            feature: '=featureRow'
        },
        templateUrl: 'modules/core/views/directives-partial/feature.row.html'
    };

}
]);
